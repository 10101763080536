/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../../_melbarr/assets/ts/components'
import { KTIcon, QUERIES, MODALTYPE } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteCampaign, startCampaign, moveToQACampaign, moveToUploadCampaign, completeUploadCampaign, completeCampaign, downloadCampaignOutput } from '../../core/_requests'
import { Campaign } from '../../core/_models'
import { useAuth } from '../../../../../../auth'

const FileDownload = require('js-file-download');

type Props = {
  campaign: Campaign
}

const ActionsCell: FC<Props> = ({ campaign }) => {
  const { setItemIdForUpdate, setUpdateModalType } = useListView()
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const editAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:edit") > -1)) ? true : false;
  const deleteAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:delete") > -1)) ? true : false;
  const moveUploadAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:move:upload") > -1)) ? true : false;
  const moveQCAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:move:qa") > -1)) ? true : false;
  const startCampaignAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:move:operation") > -1)) ? true : false;
  const assignOperationAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:assign:operation") > -1)) ? true : false;
  const assignQCAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:assign:qa") > -1)) ? true : false;
  const uploadAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:uploader") > -1)) ? true : false;
  const downloadAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:download") > -1)) ? true : false;

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const openModal = (modalType) => {
    setUpdateModalType(modalType || MODALTYPE.EDIT);
    setItemIdForUpdate(campaign.id)
  }

  const deleteItem = useMutation(() => deleteCampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      refetch();
    },
  })

  const startItem = useMutation(() => startCampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      refetch();
    },
  })

  const moveToQAItem = useMutation(() => moveToQACampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      refetch();
    },
  })

  const moveToUploadItem = useMutation(() => moveToUploadCampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      refetch();
    },
  })

  const uploadCompleteItem = useMutation(() => completeUploadCampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      refetch();
    },
  })

  const sendtoCustomerItem = useMutation(() => completeCampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      refetch();
    },
  })
  const downloadOutputFileItem = async () => {
    if (campaign && campaign.id && campaign.output_file_path) {
      const fileName = JSON.parse(campaign.output_file_path)["originalname"]
      const response = await downloadCampaignOutput(campaign.id)
      FileDownload(response, fileName);
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-auto py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {editAccess && !campaign.is_campaign_started &&
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => openModal(MODALTYPE.EDIT)}>
              Edit
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {startCampaignAccess && !campaign.is_campaign_started &&
          <div className='menu-item px-3'>
            <a className='menu-link px-3'
              onClick={async () => await startItem.mutateAsync()}>
              Start Campaign
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {assignOperationAccess && campaign.is_campaign_started && !campaign?.operations_assigned_by &&
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => openModal(MODALTYPE.ASSIGNOPERATION)}>
              Assign to Operations
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {moveQCAccess && campaign.is_campaign_started && campaign?.operations_assigned_by && !campaign.is_operation_completed &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-campaigns-table-filter='deactivate_row'
              onClick={async () => await moveToQAItem.mutateAsync()}
            >
              Move Campaign for QA
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {assignQCAccess && campaign.is_operation_completed && !campaign?.qc_assigned_by &&
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => openModal(MODALTYPE.ASSIGNQA)}>
              Assign to QA
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {moveUploadAccess && campaign.is_operation_completed && campaign?.qc_assigned_by && !campaign.is_qc_completed &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-campaigns-table-filter='deactivate_row'
              onClick={async () => await moveToUploadItem.mutateAsync()}
            >
              Move Campaign for Upload
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {uploadAccess && campaign.is_qc_completed && !campaign.is_upload_completed &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-campaigns-table-filter='deactivate_row'
              onClick={async () => await uploadCompleteItem.mutateAsync()}
            >
              Mark Campaign as Upload complete
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {uploadAccess && campaign.is_upload_completed && !campaign.is_ready_for_customer &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-campaigns-table-filter='deactivate_row'
              onClick={async () => await sendtoCustomerItem.mutateAsync()}
            >
              Send Campaign to Customer
            </a>
          </div>
        }
        {downloadAccess && campaign.is_upload_completed && campaign.output_file_path && campaign.output_file_path.includes("filename") &&
              
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-campaigns-table-filter='deactivate_row'
              onClick={downloadOutputFileItem}
            >
              Download Campaign output
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {deleteAccess &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-campaigns-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </a>
          </div>
        }
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { ActionsCell }
