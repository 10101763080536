/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Campaign } from '../../core/_models'

type Props = {
  campaign: Campaign
}

const InfoCell: FC<Props> = ({ campaign }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <span className='text-gray-800 text-hover-primary mb-1'>
        Allocation: {campaign.allocation}
      </span>
      <span>Band Name: {campaign.band_name}</span>
      <span></span>
    </div>
  </div>
)

export { InfoCell }
