/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getOngoingCampaignList } from './core/_requests'
import { Campaign } from '../campaign-management/list/core/_models'

type Props = {
  className: string
}

const OnGoingCampaignTable: React.FC<Props> = ({ className }) => {
  const [ongoingCampaignList, setOngoingCampaignList] = useState<Campaign[]>()

  useEffect(() => {
    getOngoingCampaignList().then((response) => {
      setOngoingCampaignList(response)
    })
  }, [])
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5' style={{minHeight: "10px"}}>
        <h3 className='card-title align-items-start flex-column background-color-rh top-title'>
          <span className='card-label fw-bold fs-3 mb-1 w-100 text-white'>Ongoing Campaigns</span>
        </h3>
      </div>
      <div className='card-body py-3 scroll-y h-400px'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-white background-color-rh'>
                <th className='ps-4 w-400px'>Customer Name</th>
                <th className='min-w-225px'>Campaign Details</th>
                <th className='w-450px'>Campaign Status</th>
                <th className='w-150px'>Status</th>
              </tr>
            </thead>
            {ongoingCampaignList && ongoingCampaignList.length &&
              <tbody>
                {
                  ongoingCampaignList.map(function (i) {
                    const badgeColor = ((i?.admin_status?.includes("Pending") || i?.admin_status?.includes("waiting")) ? "danger" :
                      ((i?.admin_status?.includes("Ongoing") || i?.admin_status?.includes("progress")) ? "success" :
                        ((i?.admin_status?.includes("Expired")) ? "light" :
                          ((i?.admin_status?.includes("Send to customer")) ? "primary" : 'warning'
                          ))))
                    return (
                      <tr key={i.id}>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {i.customer?.customer_name}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>{i.customer?.contact_first_name} {i.customer?.contact_last_name}</span>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                {i.campaign_id}
                              </a>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {i.band_name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <span>Total Leads: {i.total_lead_filtered}</span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <span>Emails validated: {i.leads_email_verified}</span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <span>Phone validated: {i.leads_phone_verified}</span>
                          </div>
                        </td>
                        <td>
                          <span className={`badge badge-light-${badgeColor} fs-7 fw-semibold`}>{i.admin_status}</span>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            }
          </table>
        </div>
      </div>
    </div>
  )
}

export { OnGoingCampaignTable }
