import { useListView } from '../../core/ListViewProvider'
import { Campaign } from '../../../../campaign-management/list/core/_models'
import { FC, useState } from 'react'
import { KTIcon, MODALTYPE, QUERIES } from '../../../../../../../../_melbarr/helpers'
import { useQueryResponse } from '../../../../campaign-management/list/core/QueryResponseProvider'
import { useMutation, useQueryClient } from 'react-query'
import {
  completeCampaign,
  completeUploadCampaign,
  moveToQACampaign,
  moveToUploadCampaign,
  startCampaign,
  downloadCampaignProjectDoc,
  uploadCampaignOutput,
  deleteCampaign,
  skipCampaignDataValidation,
  reValidateCampaignData,
  downloadCampaignOutput,
} from '../../../../campaign-management/list/core/_requests'
import { useAuth } from '../../../../../../auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FileDownload = require('js-file-download');

type Props = {
  campaign: Campaign
}

const CampaignInfo: FC<Props> = ({ campaign }) => {
  const { setItemIdForUpdate, setUpdateModalType } = useListView()
  const { query } = useQueryResponse()
  const [outputFile, setOutputFile] = useState<File | undefined>()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const deleteAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:delete") > -1)) ? true : false;
  const moveUploadAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:move:upload") > -1)) ? true : false;
  const moveQCAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:move:qa") > -1)) ? true : false;
  const startCampaignAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:move:operation") > -1)) ? true : false;
  const assignOperationAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:assign:operation") > -1)) ? true : false;
  const assignQCAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:assign:qa") > -1)) ? true : false;
  const uploadAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:uploader") > -1)) ? true : false;


  const openModal = (modalType) => {
    setUpdateModalType(modalType || MODALTYPE.EDIT);
    setItemIdForUpdate(campaign.id)
  }

  const handleOutputFileClick = () => {
    setOutputFile(undefined);
  }
  const handleOutputFileChange = (event) => {
    const file = event.target.files[0];
    setOutputFile(file);
  }

  const startItem = useMutation(() => startCampaign(campaign.id || ""), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGNLEAD_LIST}-${query}`])
      window.location.reload();
    },
  })

  const skipValidation = useMutation(() => skipCampaignDataValidation(campaign.id || ""), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGNLEAD_LIST}-${query}`])
      window.location.reload();
    },
  })

  const reValidateData = useMutation(() => reValidateCampaignData(campaign.id || ""), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGNLEAD_LIST}-${query}`])
      toast("Revalidation of lead are started and will be completed in sometime...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    },
  })

  const moveToQAItem = useMutation(() => moveToQACampaign(campaign.id || ""), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGNLEAD_LIST}-${query}`])
      window.location.reload();
    },
  })

  const moveToUploadItem = useMutation(() => moveToUploadCampaign(campaign.id || ""), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGNLEAD_LIST}-${query}`])
      window.location.reload();
    },
  });

  const sendtoCustomerItem = useMutation(() => completeCampaign(campaign.id || ""), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGNLEAD_LIST}-${query}`])
      window.location.reload();
    },
  })

  const uploadOutputFileItem = async () => {
    if (campaign && campaign.id && outputFile && outputFile.size) {
      try {
        await uploadCampaignOutput(campaign.id, outputFile);
        await completeUploadCampaign(campaign.id)
        queryClient.invalidateQueries([`${QUERIES.CAMPAIGNLEAD_LIST}-${query}`])
        window.location.reload();
      }
      catch (ex) {
        toast("Upload file failed. Please try again after some time or contact admin.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "error",
        });
      }
    }
  }

  const deleteItem = useMutation(() => deleteCampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      window.location.reload();
    },
  })

  const downloadProjectDocFileItem = async () => {
    if (campaign && campaign.id && campaign.project_doc_file_path) {
      const fileName = JSON.parse(campaign.project_doc_file_path)["originalname"]
      const response = await downloadCampaignProjectDoc(campaign.id)
      FileDownload(response, fileName);
    }
  }

  const downloadOutputFileItem = async () => {
    if (campaign && campaign.id && campaign.output_file_path) {
      const fileName = JSON.parse(campaign.output_file_path)["originalname"]
      const response = await downloadCampaignOutput(campaign.id)
      FileDownload(response, fileName);
    }
  }

  const completionPercent = campaign?.admin_progress_percent;
  const campaignStatus = campaign?.admin_status;
  return (
    <div className='card-body pt-9 pb-0'>
      <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
        <div className='flex-grow-1'>
          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center mb-2'>
                <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                  {campaign.customer?.customer_name}
                </div>
              </div>

              <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2' >
                  Campaign Id: {campaign.campaign_id}
                </div>
                <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'  >
                  Allocation: {campaign.allocation}
                </div>
                <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'  >
                  Band name: {campaign.band_name}
                </div>
              </div>
              <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                  <i className="far fa-envelope text-danger fs-3"></i>&nbsp;&nbsp;
                  Verify Email: {campaign.email_verify ? <KTIcon iconName='check  text-success' className='fs-1' /> : <KTIcon iconName='cross text-danger' className='fs-1' />}
                </div>
                <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                  <i className="fas fa-phone text-success fs-3"></i>&nbsp;&nbsp;
                  Verify Phone: {campaign.phone_verify ? <KTIcon iconName='check  text-success' className='fs-1' /> : <KTIcon iconName='cross text-danger' className='fs-1' />}
                </div>
                <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                  <i className="fas fa-mail-bulk text-primary fs-3"></i>&nbsp;&nbsp;
                  Schedule Batch: {campaign.schedule_batch ? <KTIcon iconName='check  text-success' className='fs-1' /> : <KTIcon iconName='cross text-danger' className='fs-1' />}
                </div>
              </div>
            </div>
            <div className='d-flex my-4'>
              {deleteAccess && campaignStatus === "New Campaign" &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={async () => await deleteItem.mutateAsync()}>
                  <span className='indicator-label'>Delete</span>
                </button>
              }
              {startCampaignAccess && campaignStatus === "New Campaign" &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={async () => await startItem.mutateAsync()}>
                  <span className='indicator-label'>Start</span>
                </button>
              }
              {startCampaignAccess && !campaign.is_data_extracted && campaign.is_campaign_started &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={async () => await skipValidation.mutateAsync()}>
                  <span className='indicator-label'>Skip data validation</span>
                </button>
              }
              {startCampaignAccess && campaign.is_data_extracted && campaign.is_campaign_started && !campaign.is_upload_completed &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={async () => await reValidateData.mutateAsync()}>
                  <span className='indicator-label'>Re-validate data</span>
                </button>
              }
              {startCampaignAccess && campaignStatus !== "New Campaign" && campaign.schedule_batch && campaign.is_data_extracted && !campaign.is_campaign_scheduled &&

                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={() => openModal(MODALTYPE.SCHEDULECAMPAIGN)}>
                  <span className='indicator-label'>Schedule Campaign</span>
                </button>
              }
              {moveQCAccess && campaignStatus === "Ongoing Operations" &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={async () => await moveToQAItem.mutateAsync()}>
                  <span className='indicator-label'>Move to QC</span>
                </button>
              }
              {assignOperationAccess && campaignStatus === "Pending to Assign to Operations" &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={() => openModal(MODALTYPE.ASSIGNOPERATION)}>
                  <span className='indicator-label'>Assign to Operations</span>
                </button>
              }
              {moveUploadAccess && campaignStatus === "Ongoing QC" &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={async () => await moveToUploadItem.mutateAsync()}>
                  <span className='indicator-label'>Move to Upload</span>
                </button>
              }
              {assignQCAccess && campaignStatus === "Operations completed waiting for QC" &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={() => openModal(MODALTYPE.ASSIGNQA)}>
                  <span className='indicator-label'>Assign to QC</span>
                </button>
              }
              {campaign.is_data_extracted && campaign.is_campaign_started && campaign.project_doc_file_path && campaign.project_doc_file_path.includes("filename") &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={downloadProjectDocFileItem}>
                  <span className='indicator-label'>Download Project Doc</span>
                </button>
              }
              {uploadAccess && campaignStatus === "Ready for customer" &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={async () => await sendtoCustomerItem.mutateAsync()}>
                  <span className='indicator-label'>Send Campaign output to customer</span>
                </button>
              }
              {campaign.is_upload_completed && campaign.output_file_path && campaign.output_file_path.includes("filename") &&
                <button className='btn btn-sm btn-primary me-2' id='kt_campaign_button'
                  onClick={downloadOutputFileItem}>
                  <span className='indicator-label'>Download Output</span>
                </button>
              }
              {uploadAccess && campaignStatus === "Ongoing Upload" &&
                <div className='me-0'>
                  <button
                    className='btn btn-sm btn-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    Upload Campaign Output
                  </button>
                  <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Upload customer output</div>
                    </div>

                    <div className='separator border-gray-200'></div>

                    <div className='px-7 py-5'>
                      <div className='mb-10'>
                        <div>
                          <input
                            placeholder='Campaign output file path'
                            type='file'
                            name='output_file_path'
                            autoComplete='off'
                            onChange={handleOutputFileChange}
                            onClick={handleOutputFileClick}
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                          onClick={uploadOutputFileItem}>
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='d-flex flex-wrap flex-stack'>
            <div className='d-flex flex-column flex-grow-1 pe-8'>
              <div className='d-flex flex-wrap'>
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    {campaign.is_campaign_started && !campaign.is_data_extracted &&
                      <i className="fa fa-circle-o-notch fa-spin"></i>
                    }
                    {campaign.is_campaign_started && campaign.is_data_extracted &&
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                    }
                    <div className='fs-2 fw-bolder'>{campaign.total_lead_filtered}</div>
                  </div>

                  <div className='fw-bold fs-6 text-gray-400'>Total Leads Filtered</div>
                </div>
                {campaign.email_verify &&
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {campaign.is_campaign_started && !campaign.is_data_extracted &&
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                      }
                      {campaign.is_campaign_started && campaign.is_data_extracted &&
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      }
                      <div className='fs-2 fw-bolder'>{campaign.leads_email_verified}/{campaign.leads_email_recipients}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Emails Verified</div>
                  </div>
                }
                {campaign.phone_verify &&
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {campaign.is_campaign_started && !campaign.is_data_extracted &&
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                      }
                      {campaign.is_campaign_started && campaign.is_data_extracted &&
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      }
                      <div className='fs-2 fw-bolder'>{campaign.leads_phone_verified}/{campaign.leads_phone_recipients}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Phone Verified</div>
                  </div>
                }
                {campaign.schedule_batch && campaign.is_campaign_scheduled &&
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{campaign.promotional_email_open_count}/{campaign.promotional_email_recipients}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Total Open</div>
                  </div>
                }
                {campaign.schedule_batch && campaign.is_campaign_scheduled &&
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{campaign.promotional_email_click_count}/{campaign.promotional_email_recipients}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Total Click</div>
                  </div>
                }
                {campaign.schedule_batch && campaign.is_campaign_scheduled &&
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{campaign.promotional_email_unsubscribe_count}/{campaign.promotional_email_recipients}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Total Unsubscribe</div>
                  </div>
                }
                {campaign.schedule_batch && campaign.is_campaign_scheduled &&
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{campaign.promotional_email_bounce_count}/{campaign.promotional_email_recipients}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Total Bounce</div>
                  </div>
                }
                {campaign.schedule_batch && campaign.is_campaign_scheduled &&
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{campaign.promotional_email_complaint_count}/{campaign.promotional_email_recipients}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Total Spam</div>
                  </div>
                }
              </div>
            </div>

            <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
              <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                <span className='fw-bold fs-6 text-gray-400'> {campaignStatus}
                </span>
                <span className='fw-bolder fs-6'>
                  {completionPercent + "%"}
                </span>
              </div>
              <div className='h-5px mx-3 w-100 bg-light mb-3'>
                <div
                  className={`${(campaignStatus === "New Campaign" || campaignStatus === "Ready for customer" ? "bg-warning" :
                    (campaignStatus === "Pending to Assign to Operations" || campaignStatus === "Operations completed waiting for QC" || campaignStatus === "Expired" ? "bg-danger" :
                      (campaignStatus === "Ongoing Operations" || campaignStatus === "Ongoing QC" || campaignStatus === "Ongoing Upload" ? "bg-success" : "bg-primary"))
                  )} rounded h-5px`}
                  role='progressbar'
                  style={{ width: `${completionPercent}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export { CampaignInfo }
