import { Response } from '../../../../../../../_melbarr/helpers'
import { Campaign, CampaignQuery, CampaignsQueryResponse, SelectList } from './_models'
import { ApiClient } from '../../../../../../../_melbarr/helpers/APIHelpers'

const CAMPAIGN_URL = `/campaign`
const MASTER_URL = `/master`
const CUSTOMER_URL = `/customer/list`
const GET_CAMPAIGNS_URL = `/campaigns/query`

const getCampaigns = (query?: CampaignQuery): Promise<CampaignsQueryResponse> => {
  return ApiClient()._post(`${GET_CAMPAIGNS_URL}`, query)
    .then((d) => d)
}

const getCampaignById = (id: string): Promise<Campaign | undefined> => {
  return ApiClient()._get(`${CAMPAIGN_URL}/${id}`)
    .then((response) => response)
    .then((response: Response<Campaign>) => response.data)
}

const getCPLList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/cpl`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getDepartmentList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/department`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getJobLevelList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/job_level`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getEmployeeRangeList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/employeerange`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getRevenueList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/revenue`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getIndustryList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/industry`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getCountryList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/country`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getCustomerList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${CUSTOMER_URL}`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const createCampaign = (campaign: Campaign): Promise<Campaign | undefined> => {
  return ApiClient()._multipartput(CAMPAIGN_URL, campaign)
    .then((response) => response)
    .then((response: Response<Campaign>) => response.data)
}

const updateCampaign = (campaign: Campaign): Promise<Campaign | undefined> => {
  return ApiClient()._multipartpatch(`${CAMPAIGN_URL}/${campaign.id}`, campaign)
    .then((response) => response.data)
    .then((response: Response<Campaign>) => response.data)
}

const updateCampaignAssignToOperations = (campaignId: string, assigned_to: number[]): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/operation/assign`, { assigned_to: assigned_to })
    .then(() => { })
}

const updateCampaignAssignToQC = (campaignId: string, assigned_to: number[]): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/qa/assign`, { assigned_to: assigned_to })
    .then(() => { })
}

const deleteCampaign = (campaignId: string): Promise<void> => {
  return ApiClient()._delete(`${CAMPAIGN_URL}/${campaignId}`).then(() => { })
}

const startCampaign = (campaignId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/start`, {}).then(() => { })
}

const skipCampaignDataValidation = (campaignId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/skipdatavalidation`, {}).then(() => { })
}

const reValidateCampaignData = (campaignId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/restart`, {}).then(() => { })
}

const moveToQACampaign = (campaignId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/move/qa`, {}).then(() => { })
}

const moveToUploadCampaign = (campaignId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/move/upload`, {}).then(() => { })
}

const uploadCampaignOutput = (campaignId: string, outputFile: File): Promise<Campaign | undefined> => {
  return ApiClient()._multipartpatch(`${CAMPAIGN_URL}/${campaignId}/upload/output`, { "output_file": outputFile })
    .then((response) => response)
    .then((response: Response<Campaign>) => response.data)
}

const uploadCampaignPromotionalEmail = (obj: any): Promise<Campaign | undefined> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${obj.campaign_id}/schedule/batch`, obj)
    .then((response) => response)
    .then((response: Response<Campaign>) => response.data)
}

const completeUploadCampaign = (campaignId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/upload/complete`, {}).then(() => { })
}

const completeCampaign = (campaignId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/${campaignId}/complete`, {}).then(() => { })
}

const deleteSelectedCampaigns = (campaignIds: Array<string>): Promise<void> => {
  const requests = campaignIds.map((id) => ApiClient()._delete(`${CAMPAIGN_URL}/${id}`))
  return ApiClient()._all(requests).then(() => { })
}

const exportCampaigns = (): Promise<any> => {
  return ApiClient()._download(`${CAMPAIGN_URL}/export`)
    .then((response) => response)
}

const downloadCampaignProjectDoc = (campaignId: string): Promise<any> => {
  return ApiClient()._download(`${CAMPAIGN_URL}/${campaignId}/project_doc`)
    .then((response) => response)
}

const downloadCampaignOutput = (campaignId: string): Promise<any> => {
  return ApiClient()._download(`${CAMPAIGN_URL}/${campaignId}/download_output`)
    .then((response) => response)
}



export {
  getCampaigns,
  deleteCampaign,
  deleteSelectedCampaigns,
  getCampaignById,
  createCampaign,
  updateCampaign,
  moveToQACampaign,
  moveToUploadCampaign,
  exportCampaigns,
  startCampaign,
  completeUploadCampaign,
  completeCampaign,
  getCountryList,
  getCPLList,
  getDepartmentList,
  getEmployeeRangeList,
  getIndustryList,
  getJobLevelList,
  getRevenueList,
  getCustomerList,
  updateCampaignAssignToOperations,
  updateCampaignAssignToQC,
  downloadCampaignProjectDoc,
  downloadCampaignOutput,
  uploadCampaignOutput,
  uploadCampaignPromotionalEmail,
  reValidateCampaignData,
  skipCampaignDataValidation,
}
