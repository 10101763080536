import { Response } from '../../../../../../../_melbarr/helpers'
import { LeadsQuery, CampaignLeadsQueryResponse, CampaignLeads } from './_models'
import { ApiClient } from '../../../../../../../_melbarr/helpers/APIHelpers'

const CAMPAIGN_URL = `/campaign`

const getCampaignLeads = (campaignId: string, query?: LeadsQuery): Promise<CampaignLeadsQueryResponse> => {
  return ApiClient()._post(`${CAMPAIGN_URL}/${campaignId}/leads`, query)
    .then((d) => d)
}

const getCampaignLeadById = (id: string): Promise<CampaignLeads | undefined> => {
  return ApiClient()._get(`${CAMPAIGN_URL}/lead/${id}`)
    .then((response) => response)
    .then((response: Response<CampaignLeads>) => response.data)
}

const exportCampaignLeads = (campaignId: string): Promise<any> => {
  return ApiClient()._download(`${CAMPAIGN_URL}/${campaignId}/leads/export`)
    .then((response) => response)
}

const validateSelectedCampaigns = (leadIds: Array<string>): Promise<void> => {
  const requests = leadIds.map((id) => ApiClient()._patch(`${CAMPAIGN_URL}/lead/${id}/validate`, {}).then(() => { }))
  return ApiClient()._all(requests).then(() => { })
}


const verifySelectedCampaigns = (leadIds: Array<string>): Promise<void> => {
  const requests = leadIds.map((id) => ApiClient()._patch(`${CAMPAIGN_URL}/lead/${id}/verify`, {}).then(() => { }))
  return ApiClient()._all(requests).then(() => { })
}


const validateLead = (leadId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/lead/${leadId}/validate`, {}).then(() => { })
}


const verifyLead = (leadId: string): Promise<void> => {
  return ApiClient()._patch(`${CAMPAIGN_URL}/lead/${leadId}/verify`, {}).then(() => { })
}

export {
  getCampaignLeads,
  exportCampaignLeads,
  getCampaignLeadById,
  validateSelectedCampaigns,
  verifySelectedCampaigns,
  validateLead,
  verifyLead,
}
