/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Campaign } from '../../core/_models'

type Props = {
  campaign: Campaign
}

const InfoCell: FC<Props> = ({ campaign }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <span className='text-muted fw-semibold text-muted d-block fs-7'>
        Allocation: {campaign.allocation}
      </span>
      <span className='text-muted fw-semibold text-muted d-block fs-7'>Band Name: {campaign.band_name}</span>
      <span className='text-muted fw-semibold text-muted d-block fs-7'>CPC: {campaign.cpc}</span>
    </div>
  </div>
)

export { InfoCell }
