import { UserModel } from './_models'
import { ApiClient } from '../../../../_melbarr/helpers/APIHelpers'

export const REGISTER_URL = `/auth/register`
export const REQUEST_PASSWORD_URL = `/auth/forgot_password`
export const ACTIVATE_ACCOUNT_URL = `/auth/activate_account`
export const GET_USER_BY_ACCESSTOKEN_URL = `/users/me`
export const GET_USER_LIST_URL = `/users/list`
export function loginUser(email: string, password: string) {
  return ApiClient()._login(email, password);
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return ApiClient()._post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function getUserList() {
  return ApiClient()._get(GET_USER_LIST_URL);
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return ApiClient()._post(REQUEST_PASSWORD_URL, {
    email,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function activateAccount(email: string, verification_code: string, password: string, confirm_password: string ) {
  return ApiClient()._post(ACTIVATE_ACCOUNT_URL, {
    email,
    verification_code,
    password,
    confirm_password,
  })
}

export async function getUserByToken(token: string) {
  const data = await ApiClient()._get(GET_USER_BY_ACCESSTOKEN_URL)
  const user: UserModel = data.data;
  return user;
}
