import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty } from '../../../../../../../_melbarr/helpers'
import { initialCustomer, Customer } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ListLoading } from '../components/loading/ListLoading'
import { createCustomer, updateCustomer } from '../core/_requests'
import { checkEmailUsernameUnique } from '../../../user-management/list/core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { EditMessage } from './EditMessage'
import _ from 'lodash'
import { useAuth } from '../../../../../auth'

type Props = {
  isCustomerLoading: boolean
  customer: Customer
}

let currentEditCustomer: Customer;
let currentCustomerEmail: string;
let currentCustomerUsername: string;
let previousEmailError: boolean;
let previousUsernameError: boolean;

const editCustomerSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .test('Unique Email', 'Email already in use',
      function (value, context) {
        if (currentCustomerEmail !== value) {
          return new Promise((resolve, reject) => {
            currentCustomerEmail = value;
            checkEmailUsernameUnique(`${value}`, "", (_.first(currentEditCustomer?.user)?.id || 0)).then((res) => {
              previousEmailError = !res;
              resolve(!res)
            }).catch((error) => {
              previousEmailError = false;
              resolve(false);
            })
          })
        } else {
          return previousEmailError;
        }
      }
    ),
  customer_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  contact_first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  contact_last_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  contact_username: Yup.string()
    .min(1, 'Please select user role')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required')
    .matches(
      /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
      "Invalid username"
    )
    .test('Unique Username', 'Username already in use',
      function (value, context) {
        if (currentCustomerUsername !== value) {
          return new Promise((resolve, reject) => {
            currentCustomerUsername = value;
            checkEmailUsernameUnique("", `${value}`, (_.first(currentEditCustomer?.user)?.id || 0)).then((res) => {
              previousUsernameError = !res;
              resolve(!res)
            }).catch((error) => {
              previousUsernameError = false;
              resolve(false);
            })
          })
        } else {
          return previousUsernameError;
        }
      }
    ),
})

const EditModalForm: FC<Props> = ({ customer, isCustomerLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [editCustomer, setEditCustomer] = useState<Customer>(customer)
  const [viewVerificationCodeModal, setViewVerificationCodeModal] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const { currentUser } = useAuth()
  const createAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "customer:*" || str.indexOf("customer:new") > -1)) ? true : false;
  const editAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "customer:*" || str.indexOf("customer:edit") > -1)) ? true : false;

  const [customerForEdit] = useState<Customer>({
    ...customer,
    customer_name: customer.customer_name || initialCustomer.customer_name,
    email: customer.email || initialCustomer.email,
    contact_first_name: _.first(customer.user)?.first_name || initialCustomer.contact_first_name,
    contact_last_name: _.first(customer.user)?.last_name || initialCustomer.contact_last_name,
    contact_username: _.first(customer.user)?.username || initialCustomer.contact_username,
  })

  currentEditCustomer = customerForEdit;
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    setViewVerificationCodeModal(false);

    if ((!createAccess && !customer.id) || (!editAccess && customer.id)) {
      cancel(true)
    }
  }, [])

  const formik = useFormik({
    initialValues: customerForEdit,
    validationSchema: editCustomerSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (isNotEmpty(values.id)) {
          await updateCustomer(values)
          cancel(true)
        } else {
          setEditCustomer(await createCustomer(values) || {})
          setViewVerificationCodeModal(true);
        }

        setHasErrors(false);
      } catch (ex) {
        setHasErrors(true);
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_customer_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_customer_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_customer_header'
          data-kt-scroll-wrappers='#kt_modal_add_customer_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please close this form and try again.
              </div>
            </div>
          )}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Customer Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Customer name'
              {...formik.getFieldProps('customer_name')}
              type='text'
              name='customer_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.customer_name && formik.errors.customer_name },
                {
                  'is-valid': formik.touched.customer_name && !formik.errors.customer_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCustomerLoading}
            />
            {formik.touched.customer_name && formik.errors.customer_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customer_name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Contact First Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='First name'
                  {...formik.getFieldProps('contact_first_name')}
                  type='text'
                  name='contact_first_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.contact_first_name && formik.errors.contact_first_name },
                    {
                      'is-valid': formik.touched.contact_first_name && !formik.errors.contact_first_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCustomerLoading}
                />
                {formik.touched.contact_first_name && formik.errors.contact_first_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.contact_first_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-6 mb-2'>Contact Last Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Last name'
                  {...formik.getFieldProps('contact_last_name')}
                  type='text'
                  name='contact_last_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.contact_last_name && formik.errors.contact_last_name },
                    {
                      'is-valid': formik.touched.contact_last_name && !formik.errors.contact_last_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCustomerLoading}
                />
                {formik.touched.contact_last_name && formik.errors.contact_last_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.contact_last_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Email</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCustomerLoading}
                />
                {/* end::Input */}
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Username</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Username'
                  {...formik.getFieldProps('contact_username')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.contact_username && formik.errors.contact_username },
                    {
                      'is-valid': formik.touched.contact_username && !formik.errors.contact_username,
                    }
                  )}
                  type='text'
                  name='contact_username'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCustomerLoading}
                />
                {/* end::Input */}
                {formik.touched.contact_username && formik.errors.contact_username && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.contact_username}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-customers-modal-action='cancel'
            disabled={formik.isSubmitting || isCustomerLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-customers-modal-action='submit'
            disabled={isCustomerLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCustomerLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCustomerLoading) && <ListLoading />}
      {(viewVerificationCodeModal && editCustomer) && <EditMessage customer={editCustomer} />}
    </>
  )
}

export { EditModalForm }
