import { FC } from 'react';
import { KTIcon } from '../../../../../../../../_melbarr/helpers'
import { useAuth } from '../../../../../../auth';
import { Campaign } from '../../../../campaign-management/list/core/_models';
import { exportCampaignLeads } from '../../core/_requests'
import { ListFilter } from './ListFilter';
const FileDownload = require('js-file-download');
type Props = {
  campaign: Campaign,
}

const ListToolbar: FC<Props> = ({ campaign }) => {
  const { currentUser } = useAuth()
  const downloadAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:download") > -1)) ? true : false;

  const exportItem = async () => {
    const response = await exportCampaignLeads(campaign.id || "")
    FileDownload(response, 'CampaignLeads.csv');
  }

  return (
    <div className='d-flex justify-content-end' data-kt-campaign-table-toolbar='base'>
      <ListFilter />
      
      {downloadAccess &&
        <button type='button' className='btn btn-light-primary me-3' onClick={exportItem}>
          <KTIcon iconName='exit-up' className='fs-2' />
          Export
        </button>
      }
    </div>
  )
}

export { ListToolbar }
