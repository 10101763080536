import { ID, Response } from '../../../../../../../_melbarr/helpers'
import { Customer, CustomerQuery, CustomersQueryResponse } from './_models'
import { ApiClient } from '../../../../../../../_melbarr/helpers/APIHelpers'

const CUSTOMER_URL = `/customer`
const GET_CUSTOMERS_URL = `/customers/query`

const getCustomers = (query?: CustomerQuery): Promise<CustomersQueryResponse> => {
  return ApiClient()._post(`${GET_CUSTOMERS_URL}`, query)
    .then((d) => d)
}

const getCustomerById = (id: ID): Promise<Customer | undefined> => {
  return ApiClient()._get(`${CUSTOMER_URL}/${id}`)
    .then((response) => response)
    .then((response: Response<Customer>) => response.data)
}

const createCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return ApiClient()._put(CUSTOMER_URL, customer)
    .then((response) => response)
    .then((response: Response<Customer>) => response.data)
}

const updateCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return ApiClient()._patch(`${CUSTOMER_URL}/${customer.id}`, customer)
    .then((response) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const deleteCustomer = (customerId: ID): Promise<void> => {
  return ApiClient()._delete(`${CUSTOMER_URL}/${customerId}`).then(() => { })
}


const activateCustomer = (customerId: ID): Promise<void> => {
  return ApiClient()._patch(`${CUSTOMER_URL}/activate/${customerId}`, {}).then(() => { })
}


const deactivateCustomer = (customerId: ID): Promise<void> => {
  return ApiClient()._patch(`${CUSTOMER_URL}/deactivate/${customerId}`, {}).then(() => { })
}

const deleteSelectedCustomers = (customerIds: Array<ID>): Promise<void> => {
  const requests = customerIds.map((id) => ApiClient()._delete(`${CUSTOMER_URL}/${id}`))
  return ApiClient()._all(requests).then(() => { })
}

const activateSelectedCustomers = (customerIds: Array<ID>): Promise<void> => {
  const requests = customerIds.map((id) =>  ApiClient()._patch(`${CUSTOMER_URL}/activate/${id}`, {}).then(() => { }))
  return ApiClient()._all(requests).then(() => { })
}

const deactivateSelectedCustomers = (customerIds: Array<ID>): Promise<void> => {
  const requests = customerIds.map((id) => ApiClient()._patch(`${CUSTOMER_URL}/deactivate/${id}`, {}).then(() => { }))
  return ApiClient()._all(requests).then(() => { })
}

const exportCustomers = (): Promise<any> => {
  return ApiClient()._download(`${CUSTOMER_URL}/export`)
  .then((response) => response)
}

export {
  getCustomers,
  deleteCustomer,
  deleteSelectedCustomers,
  getCustomerById,
  createCustomer,
  updateCustomer,
  activateCustomer,
  deactivateCustomer,
  exportCustomers,
  activateSelectedCustomers,
  deactivateSelectedCustomers,
}
