import { KTIcon } from '../../../../../../../../_melbarr/helpers'
import 'react-toastify/dist/ReactToastify.css';

const ListToolbar = () => {
   return (
    <div className='d-flex justify-content-end' data-kt-campaign-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={event =>  window.location.href='/campaign/new'}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Campaign
      </button>
    </div>
  )
}

export { ListToolbar }
