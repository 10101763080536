/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { CampaignLeads } from '../../core/_models'
import moment from 'moment'
import { KTIcon } from '../../../../../../../../_melbarr/helpers'

type Props = {
  campaignleads: CampaignLeads
}

const ContactJobInfoCell: FC<Props> = ({ campaignleads }) => {

  const maxDate = moment().subtract(60, 'days').startOf('day').toDate();
  const validDate = moment().subtract(5, 'days').startOf('day').toDate();
  return (
    <>
      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
        {campaignleads.contact?.job_title}
        <KTIcon iconName='verify' className={`fs-5 m-0 text-${campaignleads.contact?.job_details_status === 1 && campaignleads.contact.job_details_validate_date && new Date(campaignleads.contact.job_details_validate_date) > maxDate ? (campaignleads.contact.job_details_validate_date && new Date(campaignleads.contact.job_details_validate_date) > validDate ? 'success' : 'warning') : 'muted'}`} />
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads.contact?.job_level}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads.contact?.job_area}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads.contact?.job_function}
      </span>
    </>
  )
}

export { ContactJobInfoCell }
