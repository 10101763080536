import { FC, useState } from 'react'
import { User } from '../../core/_models'
import Moment from 'moment';
import { KTIcon } from '../../../../../../../../_melbarr/helpers';

type Props = {
  user?: User
}

const StatusCell: FC<Props> = ({ user }) => {
  const [showVerificationCode, setShowVerificationCode] = useState<boolean>(false)
  const [lockIcon, setLockIcon] = useState<string>("text-primary")
  const toggleVerificationCode = () => {
    setLockIcon(showVerificationCode ? 'text-primary' : 'text-default');
    setShowVerificationCode(!showVerificationCode);
  }

  return <>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex' }}>
        {!user?.verified && <div className="badge" style={{ cursor: 'pointer' }} onClick={() => toggleVerificationCode()}><KTIcon iconName='lock-3' className={'fs-2 ' + lockIcon + ' ms-n1'} /></div>}
        <div className={"badge " + (user?.verified && user?.is_active ? "badge-success" : "badge-danger") + " fw-bolder"}>{(!user?.verified ? "Verification Pending" : (!user?.is_active ? "Deactivated" : "Active"))}</div>
      </div>
      <div>
        {!user?.verified && showVerificationCode && <div className='badge badge-primary fw-bolder'>{user?.verification_code}</div>}
        {user?.verified && user?.is_active && <div className='fw-bolder'>{(user?.last_login) ? Moment(user?.last_login).format('YYYY-MM-DD HH:MM:SS') : ""}</div>}
      </div>
    </div>
  </>
}

export { StatusCell }
