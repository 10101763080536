import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_melbarr/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { UsersWrapper } from '../pages/user/UsersWrapper'
import { CustomersWrapper } from '../pages/customer/CustomersWrapper'
import { CampaignsWrapper } from '../pages/campaign/CampaignsWrapper'
import { CampaignLeadsWrapper } from '../pages/campaign/CampaignLeadsWrapper'
import { getCSSVariableValue } from '../../_melbarr/assets/ts/_utils'
import { WithChildren } from '../../_melbarr/helpers'
import { useAuth } from '../modules/auth'
import { useLayout } from '../../_melbarr/layout/core'
import { ContactsWrapper } from '../pages/contact/ContactsWrapper'
import { CampaignCreateEditWrapper } from '../pages/campaign/CampaignCreateEditWrapper'

const PrivateRoutes = () => {
  const { currentUser } = useAuth()
  const { setLayout, config } = useLayout()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  if (currentUser && currentUser.customer?.customer_type === "crm") {
    if (config.header.width !== "fluid") {
      let newConfig = config;
      newConfig.header.width = "fluid";
      setLayout(newConfig);
    }
  } else {
    if (config.header.width !== "fixed") {
      let newConfig = config;
      newConfig.header.width = "fixed";
      setLayout(newConfig);
    }
  }


  const campaignMenuAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:view") > -1)) ? true : false;
  const customerMenuAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "customer:*" || str.indexOf("customer:view") > -1)) ? true : false;
  const userMenuAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:view") > -1)) ? true : false;
  const contactMenuAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:view") > -1)) ? true : false;

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {userMenuAccess &&
          <Route path='user' element={<UsersWrapper />} />
        }
        {customerMenuAccess &&
          <Route path='customer' element={<CustomersWrapper />} />
        }
        {campaignMenuAccess &&
          <Route path='campaign' element={<CampaignsWrapper />} />
        }
        {campaignMenuAccess &&
          <Route path='campaign/:campaignId' element={<CampaignLeadsWrapper />} />
        }
        {contactMenuAccess &&
          <Route path='contact' element={<ContactsWrapper />} />
        }
        {currentUser && currentUser.customer?.customer_type === "customer" &&
          <>
            <Route path='campaign/:campaignId' element={<CampaignLeadsWrapper />} />
            <Route path='campaign/new' element={<CampaignCreateEditWrapper />} />
            <Route path='campaign/edit/:campaignId' element={<CampaignCreateEditWrapper />} />
          </>
        }
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
