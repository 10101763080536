import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { CampaignLead } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ListLoading } from '../components/loading/ListLoading'
import { getCompanies, updateContact } from '../../../contact-management/list/core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useAuth } from '../../../../../auth'
import { SelectList, SelectOption, Contact, Company } from '../../../contact-management/list/core/_models'
import { validateLead, verifyLead } from '../core/_requests'

type Props = {
  isContactLoading: boolean
  campaignLead: CampaignLead
  companies: Company[] | undefined
  country: SelectList[] | undefined
  employee_range: SelectList[] | undefined
  revenue: SelectList[] | undefined
  subindustry: SelectList[] | undefined
}

const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: '#f9f9f9',
    borderColor: '#f9f9f9',
    borderRadius: '0.475rem',
    padding: "0.2rem 0 0.2rem 1rem ",
    fontSize: "1.1rem",
    fontWeight: "500",
    lineHeight: "1.5",
    color: "#4b5675",
    border: '0 !important',
    boxShadow: '0 !important',
    "&:hover": {
      backgroundColor: '#f1f1f2',
      borderColor: '#f1f1f2',
      border: '0 !important',
      boxShadow: '0 !important',
    }
  }),
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const ipAddressRegExp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
const editContactSchema = Yup.object().shape({
  email_id: Yup.string().nullable()
    .email('Wrong email format')
    .max(200, 'Maximum 50 symbols'),
  company_email: Yup.string().nullable()
    .email('Wrong email format')
    .max(200, 'Maximum 50 symbols'),
  first_name: Yup.string()
    .min(1, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  last_name: Yup.string()
    .min(1, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  contact_no: Yup.string().nullable()
    .max(15, 'Maximum 15 symbols')
    .matches(phoneRegExp, 'Contact no. is not valid'),
  company_contact_no: Yup.string().nullable()
    .max(15, 'Maximum 15 symbols')
    .matches(phoneRegExp, 'Contact no. is not valid'),
  ip_address: Yup.string().nullable()
    .max(20, 'Maximum 20 symbols')
    .matches(ipAddressRegExp, 'Ip address is not valid')
})

const EditModalForm: FC<Props> = ({ campaignLead, isContactLoading, companies, country, employee_range, revenue, subindustry }) => {
  const contact = campaignLead.contact;

  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const { currentUser } = useAuth()
  const editAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:edit") > -1)) ? true : false;
  const verifyAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:validate") > -1)) ? true : false;
  const validateAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:verify") > -1)) ? true : false;

  const company_list = companies?.map(c => {
    const val: SelectList = {
      label: c.company_name || "",
      name: c.company_name || "",
      value: c.id,
      __isNew__: false,
      data: c,
    }
    return val
  })

  let contact_company: SelectOption | undefined = contact?.company ? {
    label: contact?.company?.company_name || "",
    value: contact?.company?.id,
    __isNew__: false,
    id: parseInt(contact?.company?.id || "0"),
    data: contact?.company,
  } : undefined;

  if (contact?.company && (!companies || !companies.length || !companies.find(i => i.id === contact?.company?.id))) {
    if (contact?.company) {
      companies?.push(contact?.company);
    }
  }

  let contact_employee_range: SelectOption | undefined = employee_range?.filter(i => i.label === contact?.company?.company_employee_range).map(c => {
    const val: SelectOption = {
      label: c.label,
      value: c.value,
      __isNew__: c.__isNew__,
      id: parseInt(c.value),
    }
    return val
  })[0];

  let company_revenue_range_list: SelectOption | undefined = revenue?.filter(i => i.label === contact?.company?.company_revenue_range).map(c => {
    const val: SelectOption = {
      label: c.label,
      value: c.value,
      __isNew__: c.__isNew__,
      id: parseInt(c.value),
    }
    return val
  })[0] || undefined;

  let contact_country: SelectOption | undefined = country?.filter(i => i.value === contact?.country?.id).map(c => {
    const val: SelectOption = {
      label: c.label,
      value: c.value,
      __isNew__: c.__isNew__,
      id: parseInt(c.value),
    }
    return val
  })[0] || undefined;
  if(!contact_country) {
   contact_country = country?.find(i => i.label === contact?.country?.region)?.options?.filter(i => i.value === contact?.country?.id).map(c => {
      const val: SelectOption = {
        label: c.label,
        value: c.value,
        __isNew__: c.__isNew__,
        id: parseInt(c.value),
      }
      return val
    })[0] || undefined;
  }
  let company_country: SelectOption | undefined = country?.filter(i => i.value === contact?.company?.country?.id).map(c => {
    const val: SelectOption = {
      label: c.label,
      value: c.value,
      __isNew__: c.__isNew__,
      id: parseInt(c.value),
    }
    return val
  })[0] || undefined;

  
  if(!company_country) {
    company_country = country?.find(i => i.label === contact?.company?.country?.region)?.options?.filter(i => i.value === contact?.company?.country?.id).map(c => {
       const val: SelectOption = {
         label: c.label,
         value: c.value,
         __isNew__: c.__isNew__,
         id: parseInt(c.value),
       }
       return val
     })[0] || undefined;
   }
  let company_sub_industry_list: SelectOption | undefined = subindustry?.filter(i => i.value === contact?.company?.sub_industry_id).map(c => {
    const val: SelectOption = {
      label: c.label,
      value: c.value,
      __isNew__: c.__isNew__,
      id: parseInt(c.value),
    }
    return val
  })[0] || undefined;
  const [editContact, setEditContact] = useState<Contact>({
    ...contact,
    contact_no: contact?.contact_no || undefined,
    contact_company: contact_company || undefined,
    company_address: contact?.company?.address,
    company_street: contact?.company?.street,
    company_city: contact?.company?.city,
    company_state: contact?.company?.state,
    company_zip_code: contact?.company?.zip_code,
    company_contact_no: contact?.company?.contact_no,
    company_email: contact?.company?.email || "",
    company_country_id: contact?.company?.country?.id,
    company_country_name: contact?.company?.country?.country_name,
    company_domain: contact?.company?.company_domain,
    company_link: contact?.company?.company_link,
    company_name: contact?.company?.company_name,
    company_website: contact?.company?.company_website,
    company_priority: contact?.company?.priority,
    company_employee_range_list: contact_employee_range,
    company_revenue_range_list: company_revenue_range_list,
    contact_country: contact_country,
    company_country: company_country,
    company_sub_industry_list: company_sub_industry_list,
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  if (!contact?.id || (!editAccess && contact?.id)) {
    cancel(true)
  }
  const formik = useFormik({
    initialValues: editContact,
    validationSchema: editContactSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        values.company_id = values.contact_company?.value;
        await updateContact(values)
        if (verifyAccess) {
          await verifyLead(campaignLead.id);
        }
        if (validateAccess) {
          await validateLead(campaignLead.id);
        }
        cancel(true)
        setHasErrors(false);
      } catch (ex) {
        setHasErrors(true);
      } finally {
        setSubmitting(true)
      }
    },
  })

  const filterCompanyList = (inputValue: string) =>
    new Promise<SelectOption[]>(async (resolve) => {
      const companyList = await getCompanies(inputValue)
      let companies: SelectOption[] = [];
      if (companyList && companyList.data) {
        let newCompanies = companyList.data.map(c => {
          const val: SelectOption = {
            label: c.company_name,
            value: c.id,
            __isNew__: false,
            id: parseInt(c.id),
            data: c,
          }

          return val;
        });

        if (newCompanies && newCompanies.length) {
          newCompanies.forEach(ele => {
            companies.push(ele)
          })
        }
      }

      resolve(companies);
    });

  function updatedCompanyFields(company: SelectOption) {
    if (company && !company.__isNew__) {
      const selectedCompany: Company = company.data;
      contact_company = {
        label: selectedCompany?.company_name || "",
        value: selectedCompany?.id,
        __isNew__: false,
        id: parseInt(selectedCompany?.id || "0"),
        data: selectedCompany,
      }

      contact_employee_range = employee_range?.filter(i => i.label === selectedCompany?.company_employee_range).map(c => {
        const val: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: c.__isNew__,
          id: parseInt(c.value),
        }
        return val
      })[0];

      company_revenue_range_list = revenue?.filter(i => i.label === selectedCompany?.company_revenue_range).map(c => {
        const val: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: c.__isNew__,
          id: parseInt(c.value),
        }
        return val
      })[0] || undefined;

      company_country = country?.filter(i => i.value === selectedCompany?.country?.id).map(c => {
        const val: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: c.__isNew__,
          id: parseInt(c.value),
        }
        return val
      })[0] || undefined;
      company_sub_industry_list = subindustry?.filter(i => i.value === selectedCompany?.sub_industry_id).map(c => {
        const val: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: c.__isNew__,
          id: parseInt(c.value),
        }
        return val
      })[0] || undefined;

      const editFields = editContact;
      editFields.company = selectedCompany;
      editFields.sub_industry = selectedCompany.sub_industry;
      editFields.sub_industry_id = selectedCompany.sub_industry_id;
      editFields.company_address = selectedCompany.address;
      editFields.company_street = selectedCompany.street;
      editFields.company_city = selectedCompany.city;
      editFields.company_contact_no = selectedCompany.contact_no;
      editFields.contact_company = contact_company;
      editFields.company_country = company_country;
      editFields.company_country_id = selectedCompany.country_id;
      editFields.company_country_name = selectedCompany.country?.country_name;
      editFields.company_domain = selectedCompany.company_domain;
      editFields.company_email = selectedCompany.email;
      editFields.company_employee_range_list = contact_employee_range;
      editFields.company_id = selectedCompany.id;
      editFields.company_link = selectedCompany.company_link;
      editFields.company_revenue_range_list = company_revenue_range_list;
      editFields.company_state = selectedCompany.state;
      editFields.company_sub_industry_list = company_sub_industry_list;
      editFields.company_website = selectedCompany.company_website;
      editFields.company_zip_code = selectedCompany.zip_code;
      setEditContact(editFields);
      formik.setValues(editFields);

    }
  }

  return (
    <>
      <form id='kt_modal_add_contact_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_contact_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_contact_header'
          data-kt-scroll-wrappers='#kt_modal_add_contact_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please close this form and try again.
              </div>
            </div>
          )}

          {/* end::Title */}
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>First Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='First name'
                  {...formik.getFieldProps('first_name')}
                  type='text'
                  name='first_name'
                  maxLength={50}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                    {
                      'is-valid': formik.touched.first_name && !formik.errors.first_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.first_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Last Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Last name'
                  {...formik.getFieldProps('last_name')}
                  type='text'
                  name='last_name'
                  maxLength={50}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                    {
                      'is-valid': formik.touched.last_name && !formik.errors.last_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.last_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Email</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Email'
                  maxLength={200}
                  {...formik.getFieldProps('email_id')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.email_id && formik.errors.email_id },
                    {
                      'is-valid': formik.touched.email_id && !formik.errors.email_id,
                    }
                  )}
                  type='email'
                  name='email_id'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {/* end::Input */}
                {formik.touched.email_id && formik.errors.email_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email_id}</span>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Contact no</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  maxLength={15}
                  placeholder='Contact no.'
                  {...formik.getFieldProps('contact_no')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.contact_no && formik.errors.contact_no },
                    {
                      'is-valid': formik.touched.contact_no && !formik.errors.contact_no,
                    }
                  )}
                  type='text'
                  name='contact_no'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {/* end::Input */}
                {formik.touched.contact_no && formik.errors.contact_no && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.contact_no}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Input group */}
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-5'>Country</label>
                {/* end::Label */}
                {/* begin::Roles */}
                {/* begin::Input row */}
                <Select
                  {...formik.getFieldProps('contact_country')}
                  className={clsx(
                    'basic-multi-select  mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.contact_country && formik.errors.contact_country },
                    {
                      'is-valid': formik.touched.contact_country && !formik.errors.contact_country,
                    }
                  )}
                  name="contact_country"
                  onChange={selectedOption => {
                    let event = { target: { name: 'contact_country', value: selectedOption } }
                    formik.handleChange(event)
                  }}
                  options={country}
                  styles={colourStyles}
                  classNamePrefix="select"
                  isDisabled={formik.isSubmitting || isContactLoading}
                />
                {formik.touched.contact_country && formik.errors.contact_country && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.contact_country}</span>
                  </div>
                )}
                {/* end::Roles */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-5'>Profile Link</label>
                {/* end::Label */}
                {/* begin::Roles */}
                {/* begin::Input row */}
                <input
                  placeholder='Profile Link'
                  maxLength={255}
                  {...formik.getFieldProps('profile_link')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.profile_link && formik.errors.profile_link },
                    {
                      'is-valid': formik.touched.profile_link && !formik.errors.profile_link,
                    }
                  )}
                  type='text'
                  name='profile_link'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {formik.touched.profile_link && formik.errors.profile_link && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.profile_link}</span>
                  </div>
                )}
                {/* end::Roles */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
        </div>
        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Company Info:</h3>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Company</label>
              <AsyncCreatableSelect
                {...formik.getFieldProps('contact_company')}
                className={clsx(
                  'basic-multi-select  mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.contact_company && formik.errors.contact_company },
                  {
                    'is-valid': formik.touched.contact_company && !formik.errors.contact_company,
                  }
                )}
                name="contact_company"
                onChange={selectedOption => {
                  updatedCompanyFields(selectedOption);
                  let event = { target: { name: 'contact_company', value: selectedOption } }
                  formik.handleChange(event)
                }}
                defaultOptions={company_list}
                cacheOptions
                allowCreateWhileLoading={true}
                styles={colourStyles}
                classNamePrefix="select"
                loadOptions={filterCompanyList}
                isDisabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.contact_company && formik.errors.contact_company && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.contact_company}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Company Domain</label>
              <input
                maxLength={255}
                placeholder='Company Domain'
                {...formik.getFieldProps('company_domain')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_domain && formik.errors.company_domain },
                  {
                    'is-valid': formik.touched.company_domain && !formik.errors.company_domain,
                  }
                )}
                type='text'
                name='company_domain'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_domain && formik.errors.company_domain && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_domain}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Company Website</label>
              <input
                maxLength={255}
                placeholder='Company Website'
                {...formik.getFieldProps('company_website')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_website && formik.errors.company_website },
                  {
                    'is-valid': formik.touched.company_website && !formik.errors.company_website,
                  }
                )}
                type='text'
                name='company_website'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_website && formik.errors.company_website && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_website}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Company Link</label>
              {/* end::Label */}
              {/* begin::Roles */}
              {/* begin::Input row */}
              <input
                maxLength={255}
                placeholder='Company Link'
                {...formik.getFieldProps('company_link')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_link && formik.errors.company_link },
                  {
                    'is-valid': formik.touched.company_link && !formik.errors.company_link,
                  }
                )}
                type='text'
                name='company_link'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_link && formik.errors.company_link && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_link}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Company Email</label>
              <input
                maxLength={200}
                placeholder='Company Email'
                {...formik.getFieldProps('company_email')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_email && formik.errors.company_email },
                  {
                    'is-valid': formik.touched.company_email && !formik.errors.company_email,
                  }
                )}
                type='text'
                name='company_email'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_email && formik.errors.company_email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_email}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Company Contact No.</label>
              {/* end::Label */}
              {/* begin::Roles */}
              {/* begin::Input row */}
              <input
                maxLength={15}
                placeholder='Company Contact No.'
                {...formik.getFieldProps('company_contact_no')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_contact_no && formik.errors.company_contact_no },
                  {
                    'is-valid': formik.touched.company_contact_no && !formik.errors.company_contact_no,
                  }
                )}
                type='text'
                name='company_contact_no'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_contact_no && formik.errors.company_contact_no && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_contact_no}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Company Employee Range</label>
              <Select
                {...formik.getFieldProps('company_employee_range_list')}
                className={clsx(
                  'basic-multi-select  mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_employee_range_list && formik.errors.company_employee_range_list },
                  {
                    'is-valid': formik.touched.company_employee_range_list && !formik.errors.company_employee_range_list,
                  }
                )}
                name="company_employee_range_list"
                onChange={selectedOption => {
                  let event = { target: { name: 'company_employee_range_list', value: selectedOption } }
                  formik.handleChange(event)
                }}
                options={employee_range}
                styles={colourStyles}
                classNamePrefix="select"
                isDisabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_employee_range_list && formik.errors.company_employee_range_list && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_employee_range_list}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Company Revenue</label>
              {/* end::Label */}
              {/* begin::Roles */}
              {/* begin::Input row */}
              <Select
                {...formik.getFieldProps('company_revenue_range_list')}
                className={clsx(
                  'basic-multi-select  mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_revenue_range_list && formik.errors.company_revenue_range_list },
                  {
                    'is-valid': formik.touched.company_revenue_range_list && !formik.errors.company_revenue_range_list,
                  }
                )}
                name="company_revenue_range_list"
                onChange={selectedOption => {
                  let event = { target: { name: 'company_revenue_range_list', value: selectedOption } }
                  formik.handleChange(event)
                }}
                options={revenue}
                styles={colourStyles}
                classNamePrefix="select"
                isDisabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_revenue_range_list && formik.errors.company_revenue_range_list && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_revenue_range_list}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Company Sub industry</label>
              <Select
                {...formik.getFieldProps('company_sub_industry_list')}
                className={clsx(
                  'basic-multi-select  mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_sub_industry_list && formik.errors.company_sub_industry_list },
                  {
                    'is-valid': formik.touched.company_sub_industry_list && !formik.errors.company_sub_industry_list,
                  }
                )}
                name="company_sub_industry_list"
                onChange={selectedOption => {
                  let event = { target: { name: 'company_sub_industry_list', value: selectedOption } }
                  formik.handleChange(event)
                }}
                options={subindustry}
                styles={colourStyles}
                classNamePrefix="select"
                isDisabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_sub_industry_list && formik.errors.company_sub_industry_list && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_sub_industry_list}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Ip Address</label>
              <input
                maxLength={20}
                placeholder='Company Ip address'
                {...formik.getFieldProps('ip_address')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.ip_address && formik.errors.ip_address },
                  {
                    'is-valid': formik.touched.ip_address && !formik.errors.ip_address,
                  }
                )}
                type='text'
                name='ip_address'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.ip_address && formik.errors.ip_address && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.ip_address}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Company Address:</h3>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Address Line</label>
              <input
                maxLength={200}
                placeholder='Address Line 1'
                {...formik.getFieldProps('company_address')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_address && formik.errors.company_address },
                  {
                    'is-valid': formik.touched.company_address && !formik.errors.company_address,
                  }
                )}
                type='text'
                name='company_address'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_address && formik.errors.company_address && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_address}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Address Line 2</label>
              <input
                placeholder='Address Line 2'
                maxLength={200}
                {...formik.getFieldProps('company_street')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_street && formik.errors.company_street },
                  {
                    'is-valid': formik.touched.company_street && !formik.errors.company_street,
                  }
                )}
                type='text'
                name='company_street'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_street && formik.errors.company_street && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_street}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>City</label>
              <input
                maxLength={50}
                placeholder='City'
                {...formik.getFieldProps('company_city')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_city && formik.errors.company_city },
                  {
                    'is-valid': formik.touched.company_city && !formik.errors.company_city,
                  }
                )}
                type='text'
                name='company_city'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_city && formik.errors.company_city && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_city}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>State</label>
              <input
                placeholder='State'
                maxLength={50}
                {...formik.getFieldProps('company_state')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_state && formik.errors.company_state },
                  {
                    'is-valid': formik.touched.company_state && !formik.errors.company_state,
                  }
                )}
                type='text'
                name='company_state'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_state && formik.errors.company_state && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_state}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Country</label>
              <Select
                {...formik.getFieldProps('company_country')}
                className={clsx(
                  'basic-multi-select  mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_country && formik.errors.company_country },
                  {
                    'is-valid': formik.touched.company_country && !formik.errors.company_country,
                  }
                )}
                name="company_country"
                onChange={selectedOption => {
                  let event = { target: { name: 'company_country', value: selectedOption } }
                  formik.handleChange(event)
                }}
                options={country}
                styles={colourStyles}
                classNamePrefix="select"
                isDisabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_country && formik.errors.company_country && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_country}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Zipcode</label>
              <input
                maxLength={15}
                placeholder='Zipcode'
                {...formik.getFieldProps('company_zip_code')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.company_zip_code && formik.errors.company_zip_code },
                  {
                    'is-valid': formik.touched.company_zip_code && !formik.errors.company_zip_code,
                  }
                )}
                type='text'
                name='company_zip_code'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.company_zip_code && formik.errors.company_zip_code && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.company_zip_code}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Contact Job Details:</h3>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Job Title</label>
              <input
                maxLength={200}
                placeholder='Job Title'
                {...formik.getFieldProps('job_title')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.job_title && formik.errors.job_title },
                  {
                    'is-valid': formik.touched.job_title && !formik.errors.job_title,
                  }
                )}
                type='text'
                name='job_title'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.job_title && formik.errors.job_title && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.job_title}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Job Area</label>
              <input
                maxLength={200}
                placeholder='Job Area'
                {...formik.getFieldProps('job_area')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.job_area && formik.errors.job_area },
                  {
                    'is-valid': formik.touched.job_area && !formik.errors.job_area,
                  }
                )}
                type='text'
                name='job_area'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.job_area && formik.errors.job_area && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.job_area}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Job Function</label>
              <input
                maxLength={200}
                placeholder='Job Function'
                {...formik.getFieldProps('job_function')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.job_function && formik.errors.job_function },
                  {
                    'is-valid': formik.touched.job_function && !formik.errors.job_function,
                  }
                )}
                type='text'
                name='job_function'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.job_function && formik.errors.job_function && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.job_function}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>Job Level</label>
              <input
                maxLength={200}
                placeholder='Job Level'
                {...formik.getFieldProps('job_level')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.job_level && formik.errors.job_level },
                  {
                    'is-valid': formik.touched.job_level && !formik.errors.job_level,
                  }
                )}
                type='text'
                name='job_level'
                autoComplete='off'
                disabled={formik.isSubmitting || isContactLoading}
              />
              {formik.touched.job_level && formik.errors.job_level && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.job_level}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-contacts-modal-action='cancel'
            disabled={formik.isSubmitting || isContactLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-contacts-modal-action='submit'
            disabled={isContactLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isContactLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isContactLoading) && <ListLoading />}
    </>
  )
}

export { EditModalForm }
