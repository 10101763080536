import { useQueryClient, useMutation } from 'react-query'
import { QUERIES } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { activateSelectedUsers, deactivateSelectedUsers, deleteSelectedUsers } from '../../core/_requests'
import { useAuth } from '../../../../../../auth'

const ListGrouping = () => {
  const { selected, clearSelected } = useListView()
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  const userDeleteAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:delete") > -1)) ? true : false;

  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })
  const activateSelectedItems = useMutation(() => activateSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })
  const deactivateSelectedItems = useMutation(() => deactivateSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>
      {userDeleteAccess &&
        <button
          type='button'
          className='btn btn-success me-1'
          onClick={async () => await activateSelectedItems.mutateAsync()}
        >
          Activate Selected
        </button>
      }
      {userDeleteAccess &&
        <button
          type='button'
          className='btn btn-danger me-1'
          onClick={async () => await deactivateSelectedItems.mutateAsync()}
        >
          Deactivate Selected
        </button>
      }
      {userDeleteAccess &&
        <button
          type='button'
          className='btn btn-danger'
          onClick={async () => await deleteSelectedItems.mutateAsync()}
        >
          Delete Selected
        </button>
      }
    </div>
  )
}

export { ListGrouping }
