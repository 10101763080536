// @ts-nocheck
import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { CustomCell } from './CustomCell'
import { StatusCell } from './StatusCell'
import { ActionsCell } from './ActionsCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { Customer } from '../../core/_models'
import _ from 'lodash'

const tableColumns: ReadonlyArray<Column<Customer>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='ps-2 min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <InfoCell customer={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Contact' className='min-w-125px' />,
    id: 'contact',
    Cell: ({ ...props }) => <CustomCell value={(_.first(props.data[props.row.index].user).first_name ? _.first(props.data[props.row.index].user).first_name + " " : '') + (_.first(props.data[props.row.index].user).last_name ? _.first(props.data[props.row.index].user).last_name : '')} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='text-center min-w-125px' />
    ),
    id: 'status',
    Cell: ({ ...props }) => <StatusCell customer={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ActionsCell customer={props.data[props.row.index]} />,
  },
]

export { tableColumns }
