import { useQuery } from 'react-query'
import { EditModalForm } from './EditModalForm'
import { AssignModalForm } from './AssignModalForm'
import { isNotEmpty, MODALTYPE, QUERIES } from '../../../../../../../_melbarr/helpers'
import { useListView } from '../core/ListViewProvider'
import { getCampaignById } from '../core/_requests'

const EditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate, updateModalType } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: campaign,
    error,
  } = useQuery(
    `${QUERIES.CAMPAIGN_LIST}-campaign-${itemIdForUpdate}`,
    () => {
      return getCampaignById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <EditModalForm isCampaignLoading={isLoading} campaign={{ id: undefined, campaign_id: '', band_name: '' }} />
  }

  if (!isLoading && !error && campaign && updateModalType === MODALTYPE.EDIT) {
    return <EditModalForm isCampaignLoading={isLoading} campaign={campaign} />
  }

  if (!isLoading && !error && campaign && (updateModalType === MODALTYPE.ASSIGNOPERATION || updateModalType === MODALTYPE.ASSIGNQA)) {
    return <AssignModalForm isCampaignLoading={isLoading} campaign={campaign} modalType={updateModalType} />
  }
  return null
}

export { EditModalFormWrapper }
