/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, loginUser } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { KTIcon } from '../../../../_melbarr/helpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}
export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const auth = await loginUser(values.email, values.password)
        saveAuth(auth)
        const user = await getUserByToken(auth.access_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-light mb-3 text-white' style={{ fontSize: "3rem" }}>Sign In</h1>
      </div>
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : ""}
      <div className='fv-row mb-8'>
        <div className='input-group mb-5 border-bottom border-color-rh'>
          <span className="input-group-text bg-transparent border-transparent"> <KTIcon iconName='user' className='fs-1 text-white' /></span>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent form-control-transparent fs-4 text-white',
              {
                'is-invalid': formik.touched.email && formik.errors.email,
              },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
        </div>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-3'>
        <div className='input-group mb-5 border-bottom border-color-rh'>
          <span className="input-group-text bg-transparent border-transparent"> <KTIcon iconName='lock' className='fs-1 text-white' /></span>
          <input
            type='password'
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent form-control-transparent fs-4 text-white',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container  text-danger'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-grid mb-10 mt-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn text-white b-white btn-shadow btn-bordered fs-4 border-color-rh'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='d-flex flex-stack fs-base fw-semibold mb-8'>
        <div className='w-100'>
          <Link to='/auth/forgot-password' className='link-muted fs-4'>
            Forgot Password ?
          </Link>
        </div>
        <div className='w-100' style={{ textAlign: 'right' }}>
          <Link to='/auth/activate-account' className='link-muted fs-4 px-5'>
            Activate Account
          </Link>
        </div>
      </div>
    </form>
  )
}
