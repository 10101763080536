import { ID, Response } from '../../../../../../../_melbarr/helpers'
import { User, UserQuery, UsersQueryResponse, RolesQueryResponse } from './_models'
import { ApiClient } from '../../../../../../../_melbarr/helpers/APIHelpers'

const USER_URL = `/user`
const GET_USERS_URL = `/users/query`
const GET_ROLES_URL = `/roles/list`

const getUsers = (query?: UserQuery): Promise<UsersQueryResponse> => {
  return ApiClient()._post(`${GET_USERS_URL}`, query)
    .then((d) => d)
}

const getRoles = (): Promise<RolesQueryResponse> => {
  return ApiClient()._get(`${GET_ROLES_URL}`)
    .then((d) => d)
}

const checkEmailUsernameUnique = (email: string, username: string, user_id: ID): Promise<boolean> => {
  return ApiClient()._get(`${USER_URL}/${user_id}/validate?email=${email}&username=${username}&user_id=${user_id}`)
    .then((d) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return ApiClient()._get(`${USER_URL}/${id}`)
    .then((response) => response)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return ApiClient()._put(USER_URL, user)
    .then((response) => response)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return ApiClient()._patch(`${USER_URL}/${user.id}`, user)
    .then((response) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return ApiClient()._delete(`${USER_URL}/${userId}`).then(() => { })
}


const activateUser = (userId: ID): Promise<void> => {
  return ApiClient()._patch(`${USER_URL}/activate/${userId}`, {}).then(() => { })
}


const deactivateUser = (userId: ID): Promise<void> => {
  return ApiClient()._patch(`${USER_URL}/deactivate/${userId}`, {}).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => ApiClient()._delete(`${USER_URL}/${id}`))
  return ApiClient()._all(requests).then(() => { })
}

const deactivateSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => ApiClient()._patch(`${USER_URL}/deactivate/${id}`, {}).then(() => { }))
  return ApiClient()._all(requests).then(() => { })
}

const activateSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => ApiClient()._patch(`${USER_URL}/activate/${id}`, {}).then(() => { }))
  return ApiClient()._all(requests).then(() => { })
}

const exportUsers = (): Promise<any> => {
  return ApiClient()._download(`${USER_URL}/export`)
  .then((response) => response)
}



export {
  getUsers,
  getRoles,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  checkEmailUsernameUnique,
  activateUser,
  deactivateUser,
  exportUsers,
  deactivateSelectedUsers,
  activateSelectedUsers
}
