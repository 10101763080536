import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { activateAccount } from '../core/_requests'
import { KTIcon } from '../../../../_melbarr/helpers'

const initialValues = {
  email: '',
  verification_code: '',
  password: '',
  confirm_password: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  verification_code: Yup.string()
    .required('Please enter your verification code')
    .matches(/^[0-9]+$/, "Invalid verification code")
    .min(6, 'Your password is too short')
    .max(6, 'Your password is too long'),
  password: Yup.string()
    .required('Please enter your password')
    .min(8, 'Your password is too short')
    .max(32, 'Your password is too long'),
  confirm_password: Yup.string()
    .required('Please enter confirm password')
    .oneOf([Yup.ref('password')], 'Password and confirm password do not match')
})

export function ActivateAccount() {
  const [loading, setLoading] = useState(false)
  const [activateStatus, setActivateStatus] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        activateAccount(values.email, values.verification_code, values.password, values.confirm_password)
          .then(() => {
            setActivateStatus(true)
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      {activateStatus &&
        <div className='mb-lg-15 alert alert-success'>
          <div className='font-weight-bold h6 pb-10'>
            Account is activated now. Please login to view your account.
          </div>
          <Link to='/auth/login'>
            <button
              type='button'
              className='btn btn-primary'
            >
              Go to Login
            </button>
          </Link>
        </div>
      }
      {!activateStatus &&
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-light mb-3 text-white' style={{ fontSize: "3rem" }}>Activate Account</h1>
          </div>
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}
          <div className='fv-row mb-8'>
            <div className='input-group mb-5 border-bottom border-color-rh'>
              <span className="input-group-text bg-transparent border-transparent"> <KTIcon iconName='user' className='fs-1 text-white' /></span>
              <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent form-control-transparent fs-4 text-white',
                  {
                    'is-invalid': formik.touched.email && formik.errors.email,
                  },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-3'>
            <div className='input-group mb-5 border-bottom border-color-rh'>
              <span className="input-group-text bg-transparent border-transparent"> <KTIcon iconName='lock-2' className='fs-1 text-white' /></span>
              <input
                type='text'
                placeholder='Verification Code'
                autoComplete='off'
                {...formik.getFieldProps('verification_code')}
                className={clsx(
                  'form-control bg-transparent form-control-transparent fs-4 text-white',
                  {
                    'is-invalid': formik.touched.verification_code && formik.errors.verification_code,
                  },
                  {
                    'is-valid': formik.touched.verification_code && !formik.errors.verification_code,
                  }
                )}
              />
            </div>
            {formik.touched.verification_code && formik.errors.verification_code && (
              <div className='fv-plugins-message-container  text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.verification_code}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-3'>
            <div className='input-group mb-5 border-bottom border-color-rh'>
              <span className="input-group-text bg-transparent border-transparent"> <KTIcon iconName='lock' className='fs-1 text-white' /></span>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent form-control-transparent fs-4 text-white',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container  text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-3'>
            <div className='input-group mb-5 border-bottom border-color-rh'>
              <span className="input-group-text bg-transparent border-transparent"> <KTIcon iconName='lock' className='fs-1 text-white' /></span>
              <input
                type='password'
                placeholder='Confirm Password'
                autoComplete='off'
                {...formik.getFieldProps('confirm_password')}
                className={clsx(
                  'form-control bg-transparent form-control-transparent fs-4 text-white',
                  {
                    'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
                  },
                  {
                    'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                  }
                )}
              />
            </div>
            {formik.touched.confirm_password && formik.errors.confirm_password && (
              <div className='fv-plugins-message-container  text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirm_password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit'
              className='btn text-white b-white btn-shadow btn-bordered fs-4 border-color-rh me-5'
              disabled={formik.isSubmitting || !formik.isValid}>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light text-dark'
                disabled={formik.isSubmitting}
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      }
    </>
  )
}
