import axios from "axios";

import * as authHelper from '../../app/modules/auth/core/AuthHelpers'
const API_URL =  window["globalConfig"]["apiURL"] || process.env.REACT_APP_API_URL

export const REFRESHTOKEN_URL = `/auth/refresh_token`
export const LOGIN_URL = `/auth/login`

export const ApiClient = () => {
    // Create a new axios instance
    const api = axios.create({
        baseURL: API_URL,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
            Accept: "application/json",
        },
        timeout: 3600000
    });

    let multipartconfig = {
        headers: {
            "Content-Type": 'multipart/form-data',
            Accept: "application/json",
        }
      }
    let auth = authHelper.getAuth();

    // Add a request interceptor to add the JWT token to the authorization header
    api.interceptors.request.use(
        (config) => {
            if (auth?.access_token) {
                config.headers.Authorization = `Bearer ${auth?.access_token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Add a response interceptor to refresh the JWT token if it's expired
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            const originalRequest = error.config;

            // If the error is a 401 and we have a refresh token, refresh the JWT token
            if (
                error.response.status === 401 &&
                auth?.refresh_token
            ) {
                let data = JSON.stringify({
                    refresh_token: auth?.refresh_token,
                });

                _post(REFRESHTOKEN_URL, data)
                    .then((response) => {
                        authHelper.setAuth(response)
                        auth = response;
                        // Re-run the original request that was intercepted
                        originalRequest.headers.Authorization = `Bearer ${auth?.access_token}`;
                        api(originalRequest)
                            .then((response) => {
                                return response.data;
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        // return api(originalRequest)
                    })
                    .catch((err) => {
                        // If there is an error refreshing the token, log out the user
                        console.log(err);
                    });
            }

            // Return the original error if we can't handle it
            return Promise.reject(error);
        }
    );

    const _login = (email, password) => {
        return api
            .post(LOGIN_URL, { username: email, password })
            .then(({ data }) => {
                authHelper.setAuth(data)
                return data;
            })
            .catch((err) => {
                // Return the error if the request fails
                return err;
            });
    };

    const _download = (path) => {
        return api.get( path,{ responseType: 'blob'}).then((response) => response.data);
    };

    const _get = (path) => {
        return api.get(path).then((response) => response.data);
    };

    const _post = (path, data) => {
        return api.post(path, data).then((response) => response.data);
    };

    const _put = (path, data) => {
        return api.put(path, data).then((response) => response.data);
    };


    const _patch = (path, data) => {
        return api.patch(path, data).then((response) => response.data);
    };

    const _multipartpost = (path, data) => {
        return api.post(path, data, multipartconfig).then((response) => response.data);
    };

    const _multipartput = (path, data) => {
        return api.put(path, data, multipartconfig).then((response) => response.data);
    };
    
    const _multipartpatch = (path, data) => {
        return api.patch(path, data, multipartconfig).then((response) => response.data);
    };

    const _delete = (path) => {
        return api.delete(path).then((response) => response);
    };

    const _all = (request) => {
        return axios.all(request).then((response) => response);
    };


    return {
        _login,
        _get,
        _post,
        _put,
        _patch,
        _multipartpost,
        _multipartput,
        _multipartpatch,
        _delete,
        _all,
        _download,
    };
};