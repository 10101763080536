// @ts-nocheck
import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { JobInfoCell } from './JobInfoCell'
import { CompanyInfoCell } from './CompanyInfoCell'
import { ActionsCell } from './ActionsCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { User } from '../../core/_models'

const tableColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='ps-2 min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <InfoCell contact={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Job Info' className='min-w-125px' />,
    id: 'job_info',
    Cell: ({ ...props }) => <JobInfoCell contact={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Company Info' className='min-w-125px' />,
    id: 'company_info',
    Cell: ({ ...props }) => <CompanyInfoCell contact={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-center w-125px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ActionsCell contact={props.data[props.row.index]} />,
  },
]

export { tableColumns }
