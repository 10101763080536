import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty } from '../../../../../../../_melbarr/helpers'
import { Campaign, SelectList, SelectOption } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ListLoading } from '../components/loading/ListLoading'
import {
  createCampaign,
  getCPLList,
  getDepartmentList,
  getEmployeeRangeList,
  getIndustryList,
  getJobLevelList,
  getRevenueList,
  updateCampaign,
  getCountryList,
} from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { EditMessage } from './EditMessage'
import Select from 'react-select';
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';
import { useAuth } from '../../../../../auth'
import { useNavigate } from 'react-router-dom'

const csvCompanyData = [
  ["Company Name", "Company Domain"]
];

const csvJobTitleData = [
  ["Title"]
];

type Props = {
  isCampaignLoading: boolean
  redirectType: string
  campaign: Campaign
}

const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: '#f9f9f9',
    borderColor: '#f9f9f9',
    borderRadius: '0.475rem',
    padding: "0.2rem 0 0.2rem 1rem ",
    fontSize: "1.1rem",
    fontWeight: "500",
    lineHeight: "1.5",
    color: "#4b5675",
    border: '0 !important',
    boxShadow: '0 !important',
    "&:hover": {
      backgroundColor: '#f1f1f2',
      borderColor: '#f1f1f2',
      border: '0 !important',
      boxShadow: '0 !important',
    }
  }),
};
const EditModalForm: FC<Props> = ({ campaign, redirectType, isCampaignLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [editCampaign, setEditCampaign] = useState<Campaign>(campaign)
  const [viewVerificationCodeModal, setViewVerificationCodeModal] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [cplList, setCplList] = useState<SelectList[]>([])
  const [departmentList, setDepartmentlList] = useState<SelectList[]>([])
  const [industryList, setIndustryList] = useState<SelectList[]>([])
  const [jobLevelList, setJobLevelList] = useState<SelectList[]>([])
  const [empRangeList, setEmpRangeList] = useState<SelectList[]>([])
  const [revenueList, setRevenueList] = useState<SelectList[]>([])
  const [countryList, setCountryList] = useState<SelectList[]>([])
  const [abmList, setAbmList] = useState(campaign.abm_list?.map(i => JSON.parse(i)))
  const [supressionList, setSupressionList] = useState(campaign.suppression_list?.map(i => JSON.parse(i)))
  const [jobTitleList, setJobTitleList] = useState(campaign.job_title_list?.map(i => JSON.parse(i)))
  const [projectDocFile, setProjectDocFile] = useState<File | undefined>()
  const { currentUser } = useAuth()
  const navigate = useNavigate();

  let abmFile: File | null = null;
  let supressionFile: File | null = null;
  let jobTitleFile: File | null = null;

  const editCampaignSchema = Yup.object().shape({
    campaign_id: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Campaign Id is required'),
    band_name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Band name is required'),
    allocation: Yup.number()
      .required('Allocation is required'),
    cpc: Yup.number(),
    cpl_list: Yup.array()
      .min(1, 'CPL is required')
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    abm_file: Yup.mixed()
      .test('fileFormat', 'Unsupported file type. Only CSV file allowed',
        function (file, context) {
          if (abmFile && abmFile?.type !== "text/csv") {
            return false;
          }

          return true;
        }
      )
      .test('fileValidate', 'Invalid File.',
        function (file, context) {
          if (abmFile) {
            return new Promise((resolve, reject) => {
              extractFileData(abmFile, csvCompanyData[0]).then((res) => {
                if (res && res.length) {
                  setAbmList(res)
                  resolve(true);
                } else {
                  setAbmList([])
                  resolve(false);
                }
              }).catch((error) => {
                setAbmList([])
                resolve(false);
              });
            })
          } else {
            return true;
          }
        }
      ),
    suppression_file: Yup.mixed()
      .test('fileFormat', 'Unsupported file type. Only CSV file allowed',
        function (file, context) {
          if (supressionFile && supressionFile?.type !== "text/csv") {
            return false;
          }

          return true;
        }
      )
      .test('fileValidate', 'Invalid File.',
        function (file, context) {
          if (supressionFile) {
            return new Promise((resolve, reject) => {
              extractFileData(supressionFile, csvCompanyData[0]).then((res) => {
                if (res && res.length) {
                  setSupressionList(res)
                  resolve(true);
                } else {
                  setSupressionList([])
                  resolve(false);
                }
              }).catch((error) => {
                setSupressionList([])
                resolve(false);
              });
            })
          } else {
            return true;
          }
        }
      ),
    job_title_file: Yup.mixed()
      .test('fileFormat', 'Unsupported file type. Only CSV file allowed',
        function (file, context) {
          if (jobTitleFile && jobTitleFile?.type !== "text/csv") {
            return false;
          }

          return true;
        }
      )
      .test('fileValidate', 'Invalid File.',
        function (file, context) {
          if (jobTitleFile) {
            return new Promise((resolve, reject) => {
              extractFileData(jobTitleFile, csvJobTitleData[0]).then((res) => {
                if (res && res.length) {
                  setJobTitleList(res)
                  resolve(true);
                } else {
                  setJobTitleList([])
                  resolve(false);
                }
              }).catch((error) => {
                setJobTitleList([])
                resolve(false);
              });
            })
          } else {
            return true;
          }
        }
      ),
  });

  function extractFileData(file, csvData) {
    return new Promise<[]>((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: "utf-8",
        complete: function (results) {
          const headers = results.meta.fields;
          if (results.data && results.data.length && _.isEqual(headers, csvData)) {
            resolve(results.data)
          } else {
            resolve([]);
          }
        },
        error: function (err, file, inputElem, reason) {
          console.log(err);
          console.log(reason);
          reject(err)
        },
      });
    })

  }

  const [campaignForEdit, setCampaignForEdit] = useState<Campaign>({
    ...campaign
  })
  const cancel = (withRefresh?: boolean) => {
    if (redirectType === "navigate") {
      navigate("/dashboard");
    } else {
      if (withRefresh) {
        refetch()
      }
      setItemIdForUpdate(undefined)
    }
  }

  const handleABMListClick = () => {
    abmFile = null;
  }
  const handleABMListChange = (event) => {
    const file = event.target.files[0];
    abmFile = file;
  };

  const handleSuppressionListClick = () => {
    supressionFile = null;
  }

  const handleSuppressionListChange = (event) => {
    const file = event.target.files[0];
    supressionFile = file;
  };

  const handleJobTitleListClick = () => {
    jobTitleFile = null;
  }

  const handleJobTitleListChange = (event) => {
    const file = event.target.files[0];
    jobTitleFile = file;
  };

  const handleProjectDocFileClick = () => {
    setProjectDocFile(undefined);
  }
  const handleProjectDocFileChange = (event) => {
    const file = event.target.files[0];
    setProjectDocFile(file);
  }

  useEffect(() => {
    setViewVerificationCodeModal(false);
    const fetchCPLList = getCPLList()
    const fetchCountryList = getCountryList()
    const fetchDepartmentList = getDepartmentList()
    const fetchEmpRangeList = getEmployeeRangeList()
    const fetchIndustryList = getIndustryList()
    const fetchJobLevelList = getJobLevelList()
    const fetchRevenueList = getRevenueList()

    Promise.all([
      fetchCPLList,
      fetchCountryList,
      fetchDepartmentList,
      fetchEmpRangeList,
      fetchIndustryList,
      fetchJobLevelList,
      fetchRevenueList,
    ]).then((responses) => {
      setCplList(responses[0] || []);
      campaignForEdit.cpl_list = [];
      responses[0]?.filter((c) => campaign.cpl?.includes(c.value)).forEach((c) => {
        const cp: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: false,
          id: parseInt(c.value),
        }
        if (campaignForEdit.cpl_list && !campaignForEdit.cpl_list.filter(i => i.value === c.value).length) {
          campaignForEdit.cpl_list.push(cp)
        }
      });
      setCountryList(responses[1] || []);
      campaignForEdit.country_list = [];
      responses[1]?.forEach((c) => {
        if (c.options && c.options.length) {
          c.options.filter((o) => campaign.country_id?.includes(o.value)).forEach((item) => {
            const cp: SelectOption = {
              label: item.label,
              value: item.value,
              __isNew__: false,
              id: parseInt(item.value),
              parent: c.parent,
              type: c.type,
            }
            if (campaignForEdit.country_list && !campaignForEdit.country_list.filter(i => i.value === item.value).length) {
              campaignForEdit.country_list.push(cp)
            }
          })
        } else if (campaign.country_id?.includes(c.value)) {
          const cp: SelectOption = {
            label: c.label,
            value: c.value,
            __isNew__: false,
            id: parseInt(c.value),
            parent: c.parent,
            type: c.type,
          }
          if (campaignForEdit.country_list && !campaignForEdit.country_list.filter(i => i.value === c.value).length) {
            campaignForEdit.country_list.push(cp)
          }
        }
      });
      setDepartmentlList(responses[2] || []);
      campaign.department?.filter(c => !responses[2]?.map(i => i.name).includes(c)).forEach((c) => {
        const cp: SelectList = {
          label: c,
          value: c,
          name: c,
          __isNew__: true,
        }
        responses[2]?.push(cp)
      });
      setDepartmentlList(responses[2] || []);
      campaignForEdit.department_list = [];
      responses[2]?.filter((c) => campaign.department?.includes(c.label) || campaign.department_id?.includes(c.value)).forEach((c) => {
        const cp: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: c.__isNew__,
          id: parseInt(c.value),
        }
        if (campaignForEdit.department_list && !campaignForEdit.department_list.filter(i => i.value === c.value).length) {
          campaignForEdit.department_list.push(cp)
        }
      });

      if (!campaignForEdit.department_list || !campaignForEdit.department_list.length) {
        const obj = responses[2]?.find((c) => !c.value);
        if (obj) {
          campaignForEdit.department_list.push({
            label: obj.label,
            value: obj.value,
            __isNew__: obj.__isNew__,
            id: parseInt(obj.value),
          })
        }
      }
      setEmpRangeList(responses[3] || []);
      campaignForEdit.employee_range_list = [];
      responses[3]?.filter((c) => campaign.employee_range_id?.includes(c.value)).forEach((c) => {
        const cp: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: false,
          id: parseInt(c.value),
        }
        if (campaignForEdit.employee_range_list && !campaignForEdit.employee_range_list.filter(i => i.value === c.value).length) {
          campaignForEdit.employee_range_list.push(cp)
        }
      });

      if (!campaignForEdit.employee_range_list || !campaignForEdit.employee_range_list.length) {
        const obj = responses[3]?.find((c) => !c.value);
        if (obj) {
          campaignForEdit.employee_range_list.push({
            label: obj.label,
            value: obj.value,
            __isNew__: obj.__isNew__,
            id: parseInt(obj.value),
          })
        }
      }
      campaign.industry?.filter(c => !responses[4]?.map(i => i.name).includes(c)).forEach((c) => {
        const cp: SelectList = {
          label: c,
          value: c,
          name: c,
          __isNew__: true,
        }
        responses[4]?.push(cp)
      });

      setIndustryList(responses[4] || []);
      campaignForEdit.industry_list = [];
      responses[4]?.filter((c) => campaign.industry?.includes(c.label) || campaign.industry_id?.includes(c.value)).forEach((c) => {
        const cp: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: c.__isNew__,
          id: parseInt(c.value),
        }
        if (campaignForEdit.industry_list && !campaignForEdit.industry_list.filter(i => i.value === c.value).length) {
          campaignForEdit.industry_list.push(cp)
        }
      });

      if (!campaignForEdit.industry_list || !campaignForEdit.industry_list.length) {
        const obj = responses[4]?.find((c) => !c.value);
        if (obj) {
          campaignForEdit.industry_list.push({
            label: obj.label,
            value: obj.value,
            __isNew__: obj.__isNew__,
            id: parseInt(obj.value),
          })
        }
      }
      campaign.job_level?.filter(c => !responses[5]?.map(i => i.name).includes(c)).forEach((c) => {
        const cp: SelectList = {
          label: c,
          value: c,
          name: c,
          __isNew__: true,
        }
        responses[5]?.push(cp)
      });
      setJobLevelList(responses[5] || []);
      campaignForEdit.job_level_list = [];
      responses[5]?.filter((c) => campaign.job_level?.includes(c.label) || campaign.job_level_id?.includes(c.value)).forEach((c) => {
        const cp: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: c.__isNew__,
          id: parseInt(c.value),
        }
        if (campaignForEdit.job_level_list && !campaignForEdit.job_level_list.filter(i => i.value === c.value).length) {
          campaignForEdit.job_level_list.push(cp)
        }
      });

      if (!campaignForEdit.job_level_list || !campaignForEdit.job_level_list.length) {
        const obj = responses[5]?.find((c) => !c.value);
        if (obj) {
          campaignForEdit.job_level_list.push({
            label: obj.label,
            value: obj.value,
            __isNew__: obj.__isNew__,
            id: parseInt(obj.value),
          })
        }
      }
      setRevenueList(responses[6] || []);
      campaignForEdit.revenue_list = [];
      responses[6]?.filter((c) => campaign.revenue_id?.includes(c.value)).forEach((c) => {
        const cp: SelectOption = {
          label: c.label,
          value: c.value,
          __isNew__: false,
          id: parseInt(c.value),
        }
        if (campaignForEdit.revenue_list && !campaignForEdit.revenue_list.filter(i => i.value === c.value).length) {
          campaignForEdit.revenue_list.push(cp)
        }
      });

      if (!campaignForEdit.revenue_list || !campaignForEdit.revenue_list.length) {
        const obj = responses[6]?.find((c) => !c.value);
        if (obj) {
          campaignForEdit.revenue_list.push({
            label: obj.label,
            value: obj.value,
            __isNew__: obj.__isNew__,
            id: parseInt(obj.value),
          })
        }
      }
      setCampaignForEdit(campaignForEdit);
      setIsLoading(false)
    });
  }, [])

  const formik = useFormik({
    initialValues: campaignForEdit,
    validationSchema: editCampaignSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const saveCampaign: Campaign = Object.assign({}, values)

      saveCampaign.customer_id = currentUser?.customer?.id;
      if (values.cpl_list && values.cpl_list.length) {
        saveCampaign.cpl = Object.assign([], values.cpl_list.map(a => a.value.toString()));
      }
      if (values.department_list && values.department_list.length) {
        saveCampaign.department_id = Object.assign([], values.department_list.filter(function (option) {
          return !option.__isNew__ && option.value && option.value !== "";
        }).map(a => a.value) || undefined);
        saveCampaign.department = Object.assign([], values.department_list.filter(function (option) {
          return option.__isNew__ === false;
        }).map(a => a.value) || undefined);
      }

      if (values.job_level_list && values.job_level_list.length) {
        saveCampaign.job_level_id = Object.assign([], values.job_level_list.filter(function (option) {
          return !option.__isNew__ && option.value && option.value !== "";
        }).map(a => a.value) || undefined);


        saveCampaign.job_level = Object.assign([], values.job_level_list.filter(function (option) {
          return option.__isNew__ === false;
        }).map(a => a.value) || undefined);
      }

      if (values.employee_range_list && values.employee_range_list.length) {
        saveCampaign.employee_range_id = Object.assign([], values.employee_range_list.filter(function (option) {
          return option.value && option.value !== "";
        }).map(a => a.value) || undefined);
        saveCampaign.employee_range = Object.assign([], values.employee_range_list.filter(function (option) {
          return option.value && option.value !== "";
        }).map(a => a.label) || undefined);
      }

      if (values.revenue_list && values.revenue_list.length) {
        saveCampaign.revenue_id = Object.assign([], values.revenue_list.filter(function (option) {
          return option.value && option.value !== "";
        }).map(a => a.value) || undefined);
        saveCampaign.revenue = Object.assign([], values.revenue_list.filter(function (option) {
          return option.value && option.value !== "";
        }).map(a => a.label) || undefined);
      }

      if (values.industry_list && values.industry_list.length) {
        saveCampaign.industry_id = Object.assign([], values.industry_list.filter(function (option) {
          return !option.__isNew__ && option.value && option.value !== "";
        }).map(a => a.value) || undefined);
        saveCampaign.industry = Object.assign([], values.industry_list.filter(function (option) {
          return option.__isNew__ === false;
        }).map(a => a.value) || undefined);
      }

      if (values.country_list && values.country_list.length) {
        const country_list = Object.assign([], values.country_list.filter(function (option) {
          return !option.__isNew__ && option.value && option.value !== "" && option.type === "child";
        }).map(a => a.value) || undefined);
        const parents = Object.assign([], values.country_list.filter(function (option) {
          return option.type === "parent";
        }).map(a => a.value) || undefined);

        if (parents && parents.length) {
          parents.forEach((c) => {
            const cp = Object.assign([], countryList.find((o) => o.label === c)?.options?.filter(i => i.value && i.value !== "" && i.value !== c).map(a => a.value) || undefined);
            cp.forEach(element => {
              country_list.push(element);
            });
          });
        }
        saveCampaign.country_id = country_list;
        saveCampaign.country = Object.assign([], values.country_list.filter(function (option) {
          return option.__isNew__ === false;
        }).map(a => a.value) || undefined);
      }


      saveCampaign.abm_list = abmList;
      saveCampaign.suppression_list = supressionList;
      saveCampaign.job_title_list = jobTitleList;
      saveCampaign.project_doc_file = projectDocFile;

      saveCampaign.cpl_list = undefined;
      saveCampaign.customer_option = undefined;
      saveCampaign.department_list = undefined;
      saveCampaign.job_level_list = undefined;
      saveCampaign.employee_range_list = undefined;
      saveCampaign.revenue_list = undefined;
      saveCampaign.industry_list = undefined;
      saveCampaign.country_list = undefined;
      try {
        if (isNotEmpty(saveCampaign.id)) {
          await updateCampaign(saveCampaign)
          cancel(true)
        } else {
          await createCampaign(saveCampaign)
          setEditCampaign(values)
          setViewVerificationCodeModal(true);
        }

        setHasErrors(false);
      } catch (ex) {
        setHasErrors(true);
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_campaign_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_campaign_scroll'
          data-kt-scroll='false'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='100%'
          data-kt-scroll-dependencies='#kt_modal_add_campaign_header'
          data-kt-scroll-wrappers='#kt_modal_add_campaign_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please close this form and try again.
              </div>
            </div>
          )}
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Campaign Id</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Campaign Id'
                  {...formik.getFieldProps('campaign_id')}
                  type='text'
                  name='campaign_id'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.campaign_id && formik.errors.campaign_id },
                    {
                      'is-valid': formik.touched.campaign_id && !formik.errors.campaign_id,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                {formik.touched.campaign_id && formik.errors.campaign_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.campaign_id}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Band Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Band Name'
                  {...formik.getFieldProps('band_name')}
                  type='text'
                  name='band_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.band_name && formik.errors.band_name },
                    {
                      'is-valid': formik.touched.band_name && !formik.errors.band_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                {formik.touched.band_name && formik.errors.band_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.band_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Allocation</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Allocation'
                  {...formik.getFieldProps('allocation')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.allocation && formik.errors.allocation },
                    {
                      'is-valid': formik.touched.allocation && !formik.errors.allocation,
                    }
                  )}
                  type='text'
                  name='allocation'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                {/* end::Input */}
                {formik.touched.allocation && formik.errors.allocation && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.allocation}</span>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>CPC</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='CPC'
                  {...formik.getFieldProps('cpc')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.cpc && formik.errors.cpc },
                    {
                      'is-valid': formik.touched.cpc && !formik.errors.cpc,
                    }
                  )}
                  type='text'
                  name='cpc'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                {/* end::Input */}
                {formik.touched.cpc && formik.errors.cpc && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.cpc}</span>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>

            <div className='col-6'>
              {/* end::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>CPL</label>
                {/* end::Label */}

                {/* begin::Input */}
                <Select
                  isMulti
                  {...formik.getFieldProps('cpl_list')}
                  className={clsx(
                    'basic-multi-select  mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.cpl_list && formik.errors.cpl_list },
                    {
                      'is-valid': formik.touched.cpl_list && !formik.errors.cpl_list,
                    }
                  )}
                  name="cpl_list"
                  options={cplList}
                  styles={colourStyles}
                  onChange={selectedOption => {
                    let event = { target: { name: 'cpl_list', value: selectedOption } }
                    formik.handleChange(event)
                  }}
                  classNamePrefix="select"
                  isDisabled={formik.isSubmitting || isCampaignLoading}
                />
                {/* end::Input */}
                {formik.touched.cpl_list && formik.errors.cpl_list && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.cpl_list}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Job Function</label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreatableSelect
              isMulti
              {...formik.getFieldProps('department_list')}
              className={clsx(
                'basic-multi-select  mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.department_list && formik.errors.department_list },
                {
                  'is-valid': formik.touched.department_list && !formik.errors.department_list,
                }
              )}
              name="department_list"
              classNamePrefix="select"
              onChange={selectedOption => {
                let event = { target: { name: 'department_list', value: selectedOption } }
                formik.handleChange(event)
              }}
              options={departmentList}
              styles={colourStyles}
              isDisabled={formik.isSubmitting || isCampaignLoading}
            />
            {/* end::Input */}
            {formik.touched.department_list && formik.errors.department_list && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.department_list}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Job Level</label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreatableSelect
              isMulti
              {...formik.getFieldProps('job_level_list')}
              className={clsx(
                'basic-multi-select  mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.job_level_list && formik.errors.job_level_list },
                {
                  'is-valid': formik.touched.job_level_list && !formik.errors.job_level_list,
                }
              )}
              name="job_level_list"
              onChange={selectedOption => {
                let event = { target: { name: 'job_level_list', value: selectedOption } }
                formik.handleChange(event)
              }}
              styles={colourStyles}
              options={jobLevelList}
              classNamePrefix="select"
              isDisabled={formik.isSubmitting || isCampaignLoading}
            />
            {/* end::Input */}
            {formik.touched.job_level_list && formik.errors.job_level_list && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.job_level_list}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Employee</label>
                {/* end::Label */}

                {/* begin::Input */}
                <Select
                  isMulti
                  {...formik.getFieldProps('employee_range_list')}
                  className={clsx(
                    'basic-multi-select  mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.employee_range_list && formik.errors.employee_range_list },
                    {
                      'is-valid': formik.touched.employee_range_list && !formik.errors.employee_range_list,
                    }
                  )}
                  name="employee_range_list"
                  onChange={selectedOption => {
                    let event = { target: { name: 'employee_range_list', value: selectedOption } }
                    formik.handleChange(event)
                  }}
                  options={empRangeList}
                  styles={colourStyles}
                  classNamePrefix="select"
                  isDisabled={formik.isSubmitting || isCampaignLoading}
                />
                {formik.touched.employee_range_list && formik.errors.employee_range_list && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.employee_range_list}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Revenue</label>
                {/* end::Label */}

                {/* begin::Input */}
                <Select
                  isMulti
                  {...formik.getFieldProps('revenue_list')}
                  className={clsx(
                    'basic-multi-select  mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.revenue_list && formik.errors.revenue_list },
                    {
                      'is-valid': formik.touched.revenue_list && !formik.errors.revenue_list,
                    }
                  )}
                  onChange={selectedOption => {
                    let event = { target: { name: 'revenue_list', value: selectedOption } }
                    formik.handleChange(event)
                  }}
                  name="revenue_list"
                  options={revenueList}
                  styles={colourStyles}
                  classNamePrefix="select"
                  isDisabled={formik.isSubmitting || isCampaignLoading}
                />
                {formik.touched.revenue_list && formik.errors.revenue_list && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.revenue_list}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Industry</label>
            {/* end::Label */}

            {/* begin::Input */}
            <CreatableSelect
              isMulti
              {...formik.getFieldProps('industry_list')}
              className={clsx(
                'basic-multi-select  mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.industry_list && formik.errors.industry_list },
                {
                  'is-valid': formik.touched.industry_list && !formik.errors.industry_list,
                }
              )}
              name="industry_list"
              onChange={selectedOption => {
                let event = { target: { name: 'industry_list', value: selectedOption } }
                formik.handleChange(event)
              }}
              options={industryList}
              styles={colourStyles}
              classNamePrefix="select"
              isDisabled={formik.isSubmitting || isCampaignLoading}
            />
            {/* end::Input */}
            {formik.touched.industry_list && formik.errors.industry_list && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.industry_list}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Country</label>
            {/* end::Label */}

            {/* begin::Input */}
            <Select
              isMulti
              {...formik.getFieldProps('country_list')}
              className={clsx(
                'basic-multi-select  mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.country_list && formik.errors.country_list },
                {
                  'is-valid': formik.touched.country_list && !formik.errors.country_list,
                }
              )}
              name="country_list"
              onChange={selectedOption => {
                let event = { target: { name: 'country_list', value: selectedOption } }
                formik.handleChange(event)
              }}
              options={countryList}
              styles={colourStyles}
              classNamePrefix="select"
              isDisabled={formik.isSubmitting || isCampaignLoading}
            />
            {/* end::Input */}
            {formik.touched.country_list && formik.errors.country_list && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.country_list}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>ABM</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='ABM File Path'
                  {...formik.getFieldProps('abm_file')}
                  type='file'
                  accept=".csv"
                  name='abm_file'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.abm_file && formik.errors.abm_file },
                    {
                      'is-valid': formik.touched.abm_file && !formik.errors.abm_file,
                    }
                  )}
                  autoComplete='off'
                  onChange={handleABMListChange}
                  onClick={handleABMListClick}
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                <CSVLink data={csvCompanyData} className='me-5' filename={"ABMTemplate.csv"} >Download Template</CSVLink>
                {formik.touched.abm_file && formik.errors.abm_file && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.abm_file}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Suppression</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Suppression File Path'
                  {...formik.getFieldProps('suppression_file')}
                  type='file'
                  accept=".csv"
                  name='suppression_file'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.suppression_file && formik.errors.suppression_file },
                    {
                      'is-valid': formik.touched.suppression_file && !formik.errors.suppression_file,
                    }
                  )}
                  autoComplete='off'
                  onChange={handleSuppressionListChange}
                  onClick={handleSuppressionListClick}
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                <CSVLink data={csvCompanyData} className='me-5' filename={"SuppressionTemplate.csv"} >Download Template</CSVLink>
                {formik.touched.suppression_file && formik.errors.suppression_file && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.suppression_file}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>

          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Job Title List</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Job Title List'
                  {...formik.getFieldProps('job_title_file')}
                  type='file'
                  accept=".csv"
                  name='job_title_file'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.job_title_file && formik.errors.job_title_file },
                    {
                      'is-valid': formik.touched.job_title_file && !formik.errors.job_title_file,
                    }
                  )}
                  autoComplete='off'
                  onChange={handleJobTitleListChange}
                  onClick={handleJobTitleListClick}
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                <CSVLink data={csvJobTitleData} className='me-5' filename={"JobTitleTemplate.csv"} >Download Template</CSVLink>
                {formik.touched.job_title_file && formik.errors.job_title_file && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.job_title_file}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Project Doc/Asset</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Project Doc/Asset'
                  {...formik.getFieldProps('project_doc_file_name')}
                  type='file'
                  accept=".csv, .pdf"
                  name='project_doc_file_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.project_doc_file_name && formik.errors.project_doc_file_name },
                    {
                      'is-valid': formik.touched.project_doc_file_name && !formik.errors.project_doc_file_name,
                    }
                  )}
                  autoComplete='off'
                  onChange={handleProjectDocFileChange}
                  onClick={handleProjectDocFileClick}
                  disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                />
                {formik.touched.project_doc_file_name && formik.errors.project_doc_file_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.project_doc_file_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          <div className='row fv-row'>
            <div className='col-4'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input
                    {...formik.getFieldProps('email_verify')}
                    type='checkbox'
                    name='email_verify'
                    defaultChecked={campaign.email_verify}
                    className={clsx(
                      'form-check-input h-20px w-30px',
                      { 'is-invalid': formik.touched.email_verify && formik.errors.email_verify },
                      {
                        'is-valid': formik.touched.email_verify && !formik.errors.email_verify,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                  />
                  <label className="form-check-label">
                    Verify Email
                  </label>
                </div>

                {formik.touched.email_verify && formik.errors.email_verify && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email_verify}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-4'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input
                    {...formik.getFieldProps('phone_verify')}
                    type='checkbox'
                    name='phone_verify'
                    defaultChecked={campaign.phone_verify}
                    className={clsx(
                      'form-check-input h-20px w-30px',
                      { 'is-invalid': formik.touched.phone_verify && formik.errors.phone_verify },
                      {
                        'is-valid': formik.touched.phone_verify && !formik.errors.phone_verify,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                  />
                  <label className="form-check-label">
                    Verify Phone
                  </label>
                </div>

                {formik.touched.phone_verify && formik.errors.phone_verify && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phone_verify}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-4'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <div className="form-check form-switch form-check-custom form-check-solid me-10">
                  <input
                    {...formik.getFieldProps('schedule_batch')}
                    type='checkbox'
                    name='schedule_batch'
                    defaultChecked={campaign.schedule_batch}
                    className={clsx(
                      'form-check-input h-20px w-30px',
                      { 'is-invalid': formik.touched.schedule_batch && formik.errors.schedule_batch },
                      {
                        'is-valid': formik.touched.schedule_batch && !formik.errors.schedule_batch,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isCampaignLoading || isLoading}
                  />
                  <label className="form-check-label">
                    Schedule Batch
                  </label>
                </div>

                {formik.touched.schedule_batch && formik.errors.schedule_batch && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.schedule_batch}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-campaigns-modal-action='cancel'
            disabled={formik.isSubmitting || isCampaignLoading || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-campaigns-modal-action='submit'
            disabled={isCampaignLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCampaignLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCampaignLoading || isLoading) && <ListLoading />}
      {(viewVerificationCodeModal && editCampaign) && <EditMessage campaign={editCampaign} />}
    </>
  )
}

export { EditModalForm }
