/* eslint-disable jsx-a11y/anchor-is-valid */


import { FC } from 'react'
import { Campaign } from './list/core/_models'
import { KTIcon } from "../../../../../_melbarr/helpers";

type Props = {
  campaign: Campaign
}
const steps = [
  {
    label: 'Campaign Submitted',
    icon: 'bookmark-2',
    step: 1,
  },
  {
    label: 'Campaign Accepted',
    icon: 'award',
    step: 2,
  },
  {
    label: 'Extracting Leads</br>and<br>Suppression Check',
    icon: 'update-file',
    step: 3,
  },
  {
    label: 'Validating Email<br>and<br>Email Marketing',
    icon: 'notepad-edit',
    step: 4,
  },
  {
    label: 'QC Check',
    icon: 'questionnaire-tablet',
    step: 5,
  },
  {
    label: 'Lead file prep<br>and<br>final check',
    icon: 'tablet-down',
    step: 6,
  },
  {
    label: 'Download file',
    icon: 'cloud-download',
    step: 7,
  },
]


const CampaignActivity: FC<Props> = ({ campaign }) => {
  const activeStep = campaign.is_ready_for_customer ? 7 : (campaign.is_upload_completed ? 6 : (campaign.qc_completed_date ? 5 : (campaign.is_operation_completed ? 4 : (campaign.is_data_extracted ? 3 : (campaign.is_campaign_started ? 2 : 1)))));
  return (
    <>
      <div
        id='kt_activities'
        className='bg-body'
        data-kt-drawer='false'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_activities_toggle'
        data-kt-drawer-close='#kt_activities_close'
      >
        <div className='card shadow-none rounded-0'>
          <div className='card-header' id='kt_activities_header'>
            <h3 className='card-title fw-bolder text-dark'>Activity Logs</h3>

          </div>
          <div className='card-body position-relative' id='kt_activities_body'>
            <div
              id='kt_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_activities_body'
              data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='stepper-container' style={{
                display: 'flex', justifyContent: 'center', alignItems: 'start',
              }}>
                {steps.map(({ label, icon, step }) => (
                  <div className='steps' key={step} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    position: 'relative',
                  }}>
                    <div className='steps-icon symbol symbol-circle symbol-40px'>
                      <div className={`symbol-label bg-${step <= activeStep ? 'success' : 'warning'}`}>
                        <KTIcon iconName={icon} className='fs-xl-1 text-light' />
                      </div>
                    </div>
                    <div className='steps-content mb-10 mt-n1 text-center'>
                      <div className='mt-4'>
                        <div className='fs-5 fw-bold mb-2 text-center' dangerouslySetInnerHTML={{ __html: label }}>
                        </div>
                      </div>
                    </div>
                    {step < steps.length &&
                      <div  className={`stepper-arrow bg-${step < activeStep ? 'success' : ''}`} style={{
                        position: 'absolute',
                        width: '80px',
                        height: '2px',
                        right: '-20%',
                        top: '25px',
                        background: '#ddd',
                      }}></div>
                    }
                  </div>
                ))}
              </div>
              {/* <div className='timeline'>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-success`}>
                      <KTIcon iconName='bookmark-2' className='fs-xl-1 text-light' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-n1'>
                    <div className='pe-3 mt-4'>
                      <div className='fs-5 fw-bold mb-2'>
                        Campaign Submitted
                      </div>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'></div>
                      </div>
                      <div className="d-flex align-items-end mt-1 fs-6 w-100">
                        <div className="text-muted me-2 fs-7 text-end w-100">{campaign.created_at ? "Added at " + moment(campaign.created_at).format("yyyy-MMM-DD hh:mm A") : ""}</div>
                        <KTIcon iconName='verify' className='fs-2 text-500 text-success' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>
                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-${campaign.is_campaign_started && campaign.operations_assigned_date ? 'success' : 'warning'}`}>
                      <KTIcon iconName='award' className='fs-xl-1 text-light' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-n1'>
                    <div className='pe-3 mt-4'>
                      <div className='fs-5 fw-bold mb-2'>
                        {campaign.is_campaign_started ?
                          "Campaign Accepted and ready to start work on." : "Campaign waiting to be accepted."
                        }
                      </div>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'></div>
                      </div>
                      <div className="d-flex align-items-end mt-1 fs-6 w-100">
                        <div className="text-muted me-2 fs-7 text-end w-100">{campaign.operations_assigned_date ? "Added at " + moment(campaign.operations_assigned_date).format("yyyy-MMM-DD hh:mm A") : (campaign.is_campaign_started ? 'Added at ' + moment(campaign.updated_at).format("yyyy-MMM-DD hh:mm A") : '')}</div>
                        {
                          campaign.is_campaign_started &&
                          <KTIcon iconName='verify' className='fs-2 text-500 text-success' />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-${campaign.is_campaign_started ? 'success' : 'warning'}`}>
                      <KTIcon iconName='search-list' className='fs-xl-1 text-light' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-n1'>
                    <div className='pe-3 mt-4'>
                      <div className='fs-5 fw-bold mb-2'>
                        {campaign.is_campaign_started && campaign.operations_assigned_date ?
                          "Lead are extracted as per campaign requirements and on-going validation." : "Campaign leads will be extracted as per requirement and submitted for validation."
                        }
                      </div>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'></div>
                      </div>
                      <div className="d-flex align-items-end mt-1 fs-6 w-100">
                        <div className="text-muted me-2 fs-7 text-end w-100">{campaign.operations_assigned_date ? "Added at " + moment(campaign.operations_assigned_date).format("yyyy-MMM-DD hh:mm A") : ""}</div>
                        {
                          campaign.is_campaign_started && campaign.operations_assigned_date &&
                          <KTIcon iconName='verify' className='fs-2 text-500 text-success' />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-${campaign.qc_assigned_date ? 'success' : 'warning'}`}>
                      <KTIcon iconName='update-file' className='fs-xl-1 text-light' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-n1'>
                    <div className='pe-3 mt-4'>
                      <div className='fs-5 fw-bold mb-2'>
                        {campaign.is_operation_completed ?
                          `Validation completed of lead of the campaign. ${campaign.qc_assigned_date ? 'Leads submitted for verification.' : 'Leads are waiting for verification'}` : "Campaign leads after validation will be submitted for verification."
                        }
                      </div>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'></div>
                      </div>
                      <div className="d-flex align-items-end mt-1 fs-6 w-100">
                        <div className="text-muted me-2 fs-7 text-end w-100">{campaign.qc_assigned_date ? "Added at " + moment(campaign.qc_assigned_date).format("yyyy-MMM-DD hh:mm A") : (campaign.operation_completed_date ? 'Added at ' + moment(campaign.operation_completed_date).format("yyyy-MMM-DD hh:mm A") : '')}</div>
                        {
                          campaign.is_operation_completed &&
                          <KTIcon iconName='verify' className='fs-2 text-500 text-success' />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-${campaign.qc_completed_date ? 'success' : 'warning'}`}>
                      <KTIcon iconName='notepad-edit' className='fs-xl-1 text-light' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-n1'>
                    <div className='pe-3 mt-4'>
                      <div className='fs-5 fw-bold mb-2'>
                        {campaign.qc_completed_date ?
                          `Verification completed of lead of the campaign. Leads submitted to generate the campaign leads output file.` : "Campaign leads after verification will be submitted for preparing campaign leads output file."
                        }
                      </div>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'></div>
                      </div>
                      <div className="d-flex align-items-end mt-1 fs-6 w-100">
                        <div className="text-muted me-2 fs-7 text-end w-100">{campaign.qc_completed_date ? "Added at " + moment(campaign.qc_completed_date).format("yyyy-MMM-DD hh:mm A") : ''}</div>
                        {
                          campaign.qc_completed_date &&
                          <KTIcon iconName='verify' className='fs-2 text-500 text-success' />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-${campaign.is_upload_completed ? 'success' : 'warning'}`}>
                      <KTIcon iconName='questionnaire-tablet' className='fs-xl-1 text-light' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-n1'>
                    <div className='pe-3 mt-4'>
                      <div className='fs-5 fw-bold mb-2'>
                        {campaign.is_upload_completed ?
                          `Campaign Leads output file generated as per the requirement and submitted for final checks` : "Campaign leads after generation will be submitted for final checks."
                        }
                      </div>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'></div>
                      </div>
                      <div className="d-flex align-items-end mt-1 fs-6 w-100">
                        <div className="text-muted me-2 fs-7 text-end w-100">{campaign.upload_completed_date ? "Added at " + moment(campaign.upload_completed_date).format("yyyy-MMM-DD hh:mm A") : ''}</div>
                        {
                          campaign.is_upload_completed &&
                          <KTIcon iconName='verify' className='fs-2 text-500 text-success' />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-${campaign.is_ready_for_customer ? 'success' : 'warning'}`}>
                      <KTIcon iconName='tablet-down' className='fs-xl-1 text-light' />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-n1'>
                    <div className='pe-3 mt-4'>
                      <div className='fs-5 fw-bold mb-2'>
                        {campaign.is_ready_for_customer ?
                          `Campaign Leads output file is ready for download. ${campaign.campaign_expiry_date && campaign.campaign_expiry_date < new Date() ? "Click on the download button above to download the output. The output file is only valid till " + moment(campaign.campaign_expiry_date).format("yyyy-MMM-DD") : ""}` : "Campaign leads can be downloaded after all the final checks are completed."
                        }
                      </div>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'></div>
                      </div>
                      <div className="d-flex align-items-end mt-1 fs-6 w-100">
                        <div className="text-muted me-2 fs-7 text-end w-100">{campaign.upload_completed_date ? "Added at " + moment(campaign.upload_completed_date).format("yyyy-MMM-DD hh:mm A") : ''}</div>
                        {
                          campaign.is_ready_for_customer &&
                          <KTIcon iconName='verify' className='fs-2 text-500 text-success' />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {campaign.campaign_expiry_date && campaign.campaign_expiry_date > new Date() &&
                  <div className='timeline-item'>
                    <div className='timeline-line w-40px'></div>

                    <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                      <div className={`symbol-label bg-danger`}>
                        <KTIcon iconName='delete-folder' className='fs-xl-1 text-light' />
                      </div>
                    </div>

                    <div className='timeline-content mb-10 mt-n1'>
                      <div className='pe-3 mt-4'>
                        <div className='fs-5 fw-bold mb-2'>
                          Campaign Leads output file is expired and cannot be downloaded now.
                        </div>
                        <div className='d-flex align-items-center mt-1 fs-6'>
                          <div className='text-muted me-2 fs-7'></div>
                        </div>
                        <div className="d-flex align-items-end mt-1 fs-6 w-100">
                          <div className="text-muted me-2 fs-7 text-end w-100">{campaign.campaign_expiry_date ? "Added at " + moment(campaign.campaign_expiry_date).format("yyyy-MMM-DD hh:mm A") : ''}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export { CampaignActivity }
