import {ListToolbar} from './ListToolbar'
import {ListSearchComponent} from './ListSearchComponent'

const ListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'><ListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ListHeader}
