/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../../_melbarr/assets/ts/components'
import { KTIcon, QUERIES } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteContact } from '../../core/_requests'
import { Contact } from '../../core/_models'
import { useAuth } from '../../../../../../auth'

type Props = {
  contact: Contact
}

const ActionsCell: FC<Props> = ({ contact }) => {
  const { setItemIdForUpdate } = useListView()
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const deleteAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:delete") > -1)) ? true : false;
  const editAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:edit") > -1)) ? true : false;

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(contact.id)
  }

  const deleteItem = useMutation(() => deleteContact(contact.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CONTACTS_LIST}-${query}`])
      refetch();
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {editAccess &&
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Edit
            </a>
          </div>}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {deleteAccess &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </a>
          </div>
        }
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { ActionsCell }
