import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../../../_melbarr/helpers'
import {useListView} from '../core/ListViewProvider'
import { getCampaignById } from '../../../campaign-management/list/core/_requests'
import { AssignModalForm } from './AssignModalForm'

const AssignCampaignModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate, updateModalType} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: campaign,
    error,
  } = useQuery(
    `${QUERIES.CONTACTS_LIST}-contact-${itemIdForUpdate}`,
    () => {
      return getCampaignById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!isLoading && !error && campaign) {
    return <AssignModalForm isCampaignLoading={isLoading} campaign={campaign} modalType={updateModalType} />
  }
  return null
}

export {AssignCampaignModalFormWrapper}
