// @ts-nocheck
import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { CustomCell } from './CustomCell'
import { StatusCell } from './StatusCell'
import { ActionsCell } from './ActionsCell'
import { CustomHeader } from './CustomHeader'
import { Campaign } from '../../core/_models'

const tableColumns: ReadonlyArray<Column<Campaign>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Campaign Id' className='ps-2 w-125px' />,
    accessor: 'campaign_id',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Campaign Details' className='min-w-125px' />,
    id: 'campaign_details',
    Cell: ({ ...props }) => <InfoCell campaign={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Campaign Status' className='w-150px text-center ' />,
    id: 'stats',
    Cell: ({ ...props }) => <CustomCell campaign={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='text-center w-125px' />
    ),
    id: 'status',
    Cell: ({ ...props }) => <StatusCell campaign={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-center w-125px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ActionsCell campaign={props.data[props.row.index]} />,
  },
]

export { tableColumns }
