import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { DisableSidebar, PageTitle } from '../../../_melbarr/layout/core'
import {
  NewCampaignTable,
  OnGoingCampaignTable,
  DashboardStats,
  LeadStats,
  CustomerList
} from '../../modules/apps/admin/dashboard'
import { useAuth } from '../../modules/auth'
import { CampaignStats } from '../../modules/apps/customer/campaigns/CampaignStats'
import { CampaignList } from '../../modules/apps/customer/campaigns/CampaignList'


const AdminDashboardPage = () => {
  const { currentUser } = useAuth()
  const access = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str === "campaign:*") ? true : false;

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <div className='container-xxl' style={{
      paddingTop: '90px'
    }}>
      <PageTitle breadcrumbs={[]}>
        Dashboard
      </PageTitle>
      {/* begin::Row */}
      {access &&
        <>
          <DashboardStats
            className='card-xl-stretch mb-xl-8'
          />
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-6  pt-10'>
              <CustomerList className='card-xxl-stretch' />
            </div>
            <div className='col-xl-6 pt-10'>
              <LeadStats
                className='card-xl-stretch mb-xl-8'
                chartColor='primary'
                chartHeight='150px'
              />
            </div>
          </div>
        </>
      }
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-8 pt-10'>
          <OnGoingCampaignTable className='card-xl-stretch' />
        </div>
        <div className='col-xl-4 pt-10'>
          <NewCampaignTable className='card-xl-stretch' />
        </div>
      </div>
    </div>
  )
}


const CustomerDashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <div className='container-xxl' style={{
      paddingTop: '90px'
    }}>
      <CampaignStats className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      <CampaignList className='mb-5 mb-xl-10' />
    </div>
  )
}

const DashboardWrapper = () => {
  const { currentUser } = useAuth()
  const intl = useIntl()
  return (
    <>
      {currentUser && currentUser.customer?.customer_type === "crm" &&
        <>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
          <AdminDashboardPage />
        </>
      }
      {currentUser && currentUser.customer?.customer_type === "customer" &&
        <DisableSidebar>
          <PageTitle breadcrumbs={[]}>
            {`Hello ${currentUser.first_name},`}
          </PageTitle>
          <CustomerDashboardPage />
        </DisableSidebar>}
    </>
  )
}

export { DashboardWrapper }
