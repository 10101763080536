// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { KTIcon } from '../../../../../_melbarr/helpers'
import { CampaignStatDetails } from './list/core/_models'
import { getCustomerCampaignStats } from './list/core/_requests'

type Props = {
  className: string
  backGroundColor: string
}

const CampaignStats: FC<Props> = ({ className, backGroundColor }) => {
  const [dashboardStats, setDashboardStats] = useState<CampaignStatDetails>()

  const abbreviate_number = function (num, fixed) {
    if (!num) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
  }

  useEffect(() => {
    getCustomerCampaignStats().then((response) => {
      setDashboardStats(response)
    })
  }, [])
  return (
    <div className='row g-0 pb-6'>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(45deg, rgb(10 119 143) 0%, rgb(0 116 190) 60%, rgb(0 152 248) 100%)',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='abstract-42' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>Total CID</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.total, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(68deg,#21504e,#278685)',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='abstract-45' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>Ongoing CID</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.ongoing, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(68deg, rgb(48 179 3), rgb(61 134 39))',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='double-check' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>Delivered CID</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.completed, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(68deg, rgb(163 7 7), rgb(255 0 0))',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='abstract-21' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>Pending CID</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.new, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export { CampaignStats }
