/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Campaign } from '../../core/_models'

type Props = {
  campaign: Campaign
}

const CustomCell: FC<Props> = ({ campaign }) => (
  <>
    <div className='d-flex align-items-center'>
      <span>Total Leads: {campaign.total_lead_filtered}</span>
    </div>
    <div className='d-flex align-items-center'>
      <span>Emails validated: {campaign.leads_email_verified}</span>
    </div>
    <div className='d-flex align-items-center'>
      <span>Phone validated: {campaign.leads_phone_verified}</span>
    </div>
  </>
)

export { CustomCell }
