import { ID, Response } from '../../../../../../../_melbarr/helpers'
import { Contact, ContactQuery, ContactsQueryResponse, CompanyQueryResponse, SelectList, ContactRaw } from './_models'
import { ApiClient } from '../../../../../../../_melbarr/helpers/APIHelpers'

const CONTACT_URL = `/contact`
const COMPANY_URL = `/contact/company/list`
const GET_CONTACT_URL = `/contacts/query`
const MASTER_URL = `/master`

const getSubIndustryList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/subindustry`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getCountryList = (): Promise<SelectList[] | undefined> => {
  return ApiClient()._get(`${MASTER_URL}/country`)
    .then((response) => response)
    .then((response: Response<SelectList[]>) => response.data)
}

const getContacts = (query?: ContactQuery): Promise<ContactsQueryResponse> => {
  return ApiClient()._post(`${GET_CONTACT_URL}`, query)
    .then((d) => d)
}
const getCompanies = (inputVal: string): Promise<CompanyQueryResponse> => {
  return ApiClient()._get(`${COMPANY_URL}?query=${inputVal}`)
    .then((d) => d)
}

const checkEmailUnique = (email: string,  contact_id: string): Promise<boolean> => {
  return ApiClient()._get(`${CONTACT_URL}/${contact_id}/validate?email=${email}&contact_id=${contact_id}`)
    .then((d) => d.data)
}

const getContactById = (id: string): Promise<Contact | undefined> => {
  return ApiClient()._get(`${CONTACT_URL}/${id}`)
    .then((response) => response)
    .then((response: Response<Contact>) => response.data)
}

const createContact = (contact: Contact): Promise<Contact | undefined> => {
  return ApiClient()._put(CONTACT_URL, contact)
    .then((response) => response)
    .then((response: Response<Contact>) => response.data)
}

const createContactRaw = (contact: ContactRaw): Promise<Contact | undefined> => {
  return ApiClient()._post(`${CONTACT_URL}/raw`, contact)
    .then((response) => response)
    .then((response: Response<Contact>) => response.data)
}


const uploadContacts = (contactFile: File): Promise<boolean> => {
  return ApiClient()._multipartpost(`${CONTACT_URL}/upload`, {"contact_file": contactFile})
    .then((response) => response)
}

const updateContact = (contact: Contact): Promise<Contact | undefined> => {
  return ApiClient()._patch(`${CONTACT_URL}/${contact.id}`, contact)
    .then((response) => response.data)
    .then((response: Response<Contact>) => response.data)
}

const deleteContact = (contactId: string): Promise<void> => {
  return ApiClient()._delete(`${CONTACT_URL}/${contactId}`).then(() => { })
}

const deleteSelectedContacts = (contactIds: Array<ID>): Promise<void> => {
  const requests = contactIds.map((id) => ApiClient()._delete(`${CONTACT_URL}/${id}`))
  return ApiClient()._all(requests).then(() => { })
}

const exportContacts = (): Promise<any> => {
  return ApiClient()._download(`${CONTACT_URL}/export`)
  .then((response) => response)
}

export {
  getContacts,
  getCompanies,
  deleteContact,
  deleteSelectedContacts,
  getContactById,
  createContact,
  updateContact,
  checkEmailUnique,
  exportContacts,
  getCountryList,
  getSubIndustryList,
  createContactRaw,
  uploadContacts
}
