/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { CampaignLeads } from '../../core/_models'
import moment from 'moment'
import { KTIcon } from '../../../../../../../../_melbarr/helpers'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type Props = {
  campaignleads: CampaignLeads
}

const ContactInfoCell: FC<Props> = ({ campaignleads }) => {
  const maxDate = moment().subtract(60, 'days').startOf('day').toDate();
  const validDate = moment().subtract(5, 'days').startOf('day').toDate();
  return (<>
    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
      {campaignleads.contact?.first_name} {campaignleads.contact?.last_name}
    </a>
    {campaignleads.contact?.email_id &&
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id='tooltip-user-name'>
          {campaignleads.contact.email_status === 1 ? ` Email validated on ${moment(campaignleads.contact.email_validate_date).format("yyyy-MMM-DD")}` : 
          (campaignleads.is_email_processed ? "Email is invalid/undeliverable." : "Email not validate yet.") }
          {campaignleads.campaign?.is_campaign_scheduled ? 
            (campaignleads.contact.email_promotion_status === "not_opened" ? `Promotional Email shared but not yet open` : 
              (campaignleads.contact.email_promotion_status === "opened" ? "Promotional Email shared and opened by user" : 
              (campaignleads.contact.email_promotion_status === "unsubscribed" ? "Promotional Email shared and user unsubscribed" : 
              (campaignleads.contact.email_promotion_status === "bounced" || campaignleads.contact.email_promotion_status === "soft_bounced" ? "Promotional Email could not be shared as the email bounced" : 
              (campaignleads.contact.email_promotion_status === "complained" ? "Promotional Email shared but moved to spam" : "Promotional Email status unknowm")))) ) : ""}
        </Tooltip>}
      >

        <span className='text-muted fw-semibold d-block fs-7'> {campaignleads.contact?.email_id}
          <KTIcon iconName='verify' className={`fs-5 m-0 text-${campaignleads.contact?.email_status === 1 && campaignleads.contact.email_validate_date && new Date(campaignleads.contact.email_validate_date) > maxDate ? (campaignleads.contact.email_validate_date && new Date(campaignleads.contact.email_validate_date) > validDate ? 'success' : (campaignleads.contact?.email_status !== 1 && campaignleads.is_email_processed ? 'danger' : 'warning')) : 'muted'}`} />
        </span>
      </OverlayTrigger>
    }
    {campaignleads.contact?.contact_no &&
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads.contact?.contact_no}
        <KTIcon iconName='verify' className={`fs-5 m-0 text-${campaignleads.contact?.phone_status === 1 && campaignleads.contact.phone_validate_date && new Date(campaignleads.contact.phone_validate_date) > maxDate ? (campaignleads.contact.phone_validate_date && new Date(campaignleads.contact.phone_validate_date) > validDate ? 'success' : (!campaignleads.contact?.phone_status && campaignleads.is_phone_processed ? 'danger' : 'warning')) : 'muted'}`} />
      </span>
    }

    {campaignleads.contact?.profile_link &&
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads.contact?.profile_link}
        <KTIcon iconName='verify' className={`fs-5 m-0 text-${campaignleads.contact?.profile_link_status === 1 && campaignleads.contact.profile_link_validate_date && new Date(campaignleads.contact.profile_link_validate_date) > maxDate ? (campaignleads.contact.profile_link_validate_date && new Date(campaignleads.contact.profile_link_validate_date) > validDate ? 'success' : 'warning') : 'muted'}`} />
      </span>
    }

  </>)
}

export { ContactInfoCell }
