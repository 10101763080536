const QUERIES = {
  USERS_LIST: 'user_list',
  CONTACTS_LIST: 'contact_list',
  CAMPAIGN_LIST: 'campaign_list',
  CAMPAIGN: 'campaign',
  CUSTOMER_LIST: 'customer_list',
  CAMPAIGNLEAD_LIST: 'lead_list',
}

const MODALTYPE = {
  EDIT: 'edit',
  ASSIGNOPERATION: 'assign_ops',
  ASSIGNQA: 'assign_qa',
  EDITLEAD: 'edit_lead',
  SCHEDULECAMPAIGN: 'schedule_campaign',
}

const PAGINATION_ITEM_PER_PAGE =10;

export { QUERIES, MODALTYPE, PAGINATION_ITEM_PER_PAGE };
