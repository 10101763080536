import { FC } from 'react'
import { Campaign } from '../../core/_models'
import Moment from 'moment';

type Props = {
  campaign?: Campaign
}

const StatusCell: FC<Props> = ({ campaign }) => {
  const completionPercent = campaign?.customer_progress_percent || 0;
  const campaignStatus = campaign?.customer_status_code;

  return <>
    <div className='d-flex flex-column w-100 me-2'>
      <div className='d-flex flex-stack mb-2'>
        <div className={`badge badge-${campaignStatus === 'new' ? 'warning' : (campaignStatus === 'expired' ? 'danger' : (completionPercent > 50 ? 'success' : 'warning'))}`} >
          {campaign?.customer_status}
        </div>
      </div>
      {campaignStatus === 'download' &&
        <div className='d-flex flex-stack mb-2'>
          <span className='text-muted me-2 fs-7 fw-semibold'>Expiry: {Moment(campaign?.campaign_expiry_date).format('yyyy-MMM-DD')}</span>
        </div>
      }
      {campaign?.is_campaign_started && (!campaign.campaign_expiry_date || campaign.campaign_expiry_date < new Date()) &&
        <div className='progress h-6px w-100'>
          <div
            className={`progress-bar bg-${completionPercent > 50 ? 'success' : 'warning'} `}
            role='progressbar'
            style={{ width: `${completionPercent}%` }}
          ></div>
        </div>
      }
    </div>
  </>
}

export { StatusCell }
