/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'

const AsideUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()

  return (
    <>
      <div className='d-flex flex-stack w-100'>
        <div className='d-flex align-items-center w-100'>
          <div className='symbol symbol-circle symbol-40px'>
            <img src={toAbsoluteUrl('/media/avatars/' + (currentUser?.photo || "default.png"))} alt='avatar' />
          </div>
          <div className='ms-2'>
            <a href='#' className='text-white fs-6 fw-bolder lh-1'>
              {currentUser?.first_name} {currentUser?.last_name}
            </a>
          </div>
        </div>
        <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
          >
            <a onClick={logout} className='menu-link px-5'>
              <KTIcon iconName='exit-right' className='fs-1 text-white' />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export { AsideUserMenu }
