import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_melbarr/layout/core'
import { ListWrapper } from '../../modules/apps/admin/lead-management/list/List'
import { CampaignLeads } from '../../modules/apps/customer/campaigns/CampaignLeads'
import { useAuth } from '../../modules/auth'
import { KTCard } from '../../../_melbarr/helpers'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Campaign, initialCampaign } from '../../modules/apps/customer/campaigns/list/core/_models'
import { getCampaignById } from '../../modules/apps/customer/campaigns/list/core/_requests'
import { CampaignActivity } from '../../modules/apps/customer/campaigns/CampaignActivity'


const AdminCampaignLeadsPage = () => {

  return (
      <div className='container-xxl' style={{
      paddingTop: '90px'
    }}>
      <ListWrapper />
    </div>
  )
}


const CustomerCampaignLeadsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [campaign, setCampaign] = useState<Campaign>()
  useEffect(() => {
    if (params.campaignId) {
      getCampaignById(params.campaignId).then((response) => {
        if (response) {
          setCampaign(response);
        }
      });
    } else {
      navigate(`/dashboard`)
    }
  }, [navigate, params.campaignId])

  return (
    <div className='container-xxl' style={{
      paddingTop: '90px'
    }}>
      <KTCard>
        <CampaignLeads campaign={campaign || initialCampaign} />
      </KTCard>
      <CampaignActivity campaign={campaign || initialCampaign} />
    </div>
  )
}


const CampaignLeadsWrapper = () => {
  const { currentUser } = useAuth()
  const intl = useIntl()
  return (
    <>
      {currentUser && currentUser.customer?.customer_type === "crm" &&
        <>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CAMPAIGN' })}</PageTitle>
          <AdminCampaignLeadsPage />
        </>
      }
      {currentUser && currentUser.customer?.customer_type === "customer" &&
        <>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
          <CustomerCampaignLeadsPage />
        </>
      }
    </>
  )
}

export { CampaignLeadsWrapper }
