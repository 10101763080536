import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../../../_melbarr/helpers'
import {useListView} from '../core/ListViewProvider'
import { ScheduleCampaignModalForm } from './ScheduleCampaignModalForm'
import { getCampaignById } from '../../../campaign-management/list/core/_requests'

const ScheduleCampaignModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: campaign,
    error,
  } = useQuery(
    `${QUERIES.CAMPAIGN}-campaign-${itemIdForUpdate}`,
    () => {
      return getCampaignById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!isLoading && !error && campaign) {
    return <ScheduleCampaignModalForm isContactLoading={isLoading} campaign={campaign} />
  }
  return null
}

export {ScheduleCampaignModalFormWrapper}
