import { Dispatch, SetStateAction } from 'react'
import { UserQuery } from '../../../app/modules/apps/admin/user-management/list/core/_models'
import { PAGINATION_ITEM_PER_PAGE} from './consts'

export type ID = undefined | null | number

export type PaginationState = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  itemCount: number
  page: number
  pageCount: number
  take: number
  items_per_page: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: any
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  hasNextPage: false,
  hasPreviousPage: false,
  itemCount: 0,
  page: 1,
  pageCount: 1,
  take: PAGINATION_ITEM_PER_PAGE,
  items_per_page: PAGINATION_ITEM_PER_PAGE,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query?: UserQuery
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: { page: 1, items_per_page: PAGINATION_ITEM_PER_PAGE } }

export type ListViewContextProps = {
  selected: Array<any>
  onSelect: (selectedId: any) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  updateModalType?: any
  setUpdateModalType: Dispatch<SetStateAction<any>>
  itemIdForUpdate?: any
  setItemIdForUpdate: Dispatch<SetStateAction<any>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setUpdateModalType: () => { },
  isAllSelected: false,
  disabled: false,
}
