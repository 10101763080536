import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_melbarr/layout/core'
import { ListWrapper } from '../../modules/apps/admin/contact-management/list/List'

const ContactsWrapper = () => {
  const intl = useIntl()
  return (
      <div className='container-xxl' style={{
      paddingTop: '90px'
    }}>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CONTACTS' })}</PageTitle>
      <ListWrapper />
    </div>
  )
}

export { ContactsWrapper }
