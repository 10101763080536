/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Campaign } from '../../core/_models'

type Props = {
  campaign: Campaign
}

const CustomCell: FC<Props> = ({ campaign }) => (
  <>
    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-3 text-center'>
      <i className={
        `${campaign.email_verify ? 'text-success' : 'text-danger'} fa-solid fa-envelope me-5 fs-3`
      }></i>
      <i className={
        `${campaign.email_verify ? 'text-success' : 'text-danger'} fa-solid fa-phone-square me-5 fs-3`
      }></i>
      <i className={
        `${campaign.email_verify ? 'text-success' : 'text-danger'} fa-solid fa-mail-bulk fs-3`
      }></i>
    </a>
  </>
)

export { CustomCell }
