/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Contact } from '../../core/_models'

type Props = {
  contact: Contact
}

const JobInfoCell: FC<Props> = ({ contact }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
        {contact.job_title}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {contact?.job_level}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {contact?.job_area}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {contact?.job_function}
      </span>
    </div>
  </div>
)

export { JobInfoCell }
