import {ID, Response} from '../../../../../../../_melbarr/helpers'
import { User } from '../../../user-management/list/core/_models'
export type Customer = {
  id?: ID
  customer_name?: string
  email?: string
  contact_first_name?: string
  contact_last_name?: string
  contact_username?: string
  user?: User[]
  //campaign?: Campaign[]
  is_active?: boolean,
  campaignCount?: number,
}

export type CustomerQuery = {
  page: number,
  items_per_page: number,
  search?: string,
  filter_role?: number,
  filter_verified?: boolean,
}

export type CustomersQueryResponse = Response<Array<Customer>>


export const initialCustomer: Customer = {
  
  customer_name: '',
  email: '',
  contact_first_name: '',
  contact_last_name: '',
  contact_username: '',
  user: [],
  is_active: false,
}
