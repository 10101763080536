import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../../_melbarr/assets/ts/components'
import { initialQueryState, KTIcon } from '../../../../../../../../_melbarr/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'

const ListFilter = () => {
  const { state, updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [verified, setVerified] = useState<string | undefined>((state.filter ? state.filter?.verified : "verified"))
  const [emailValidationStatus, setEmailValidationStatus] = useState<string | undefined>((state.filter ? state.filter?.email_validation_status : ""))
  const [emailVerifyStatus, setEmailVerifyStatus] = useState<string | undefined>((state.filter ? state.filter?.email_verify_status : ""))
  const [phoneValidationStatus, setPhoneValidationStatus] = useState<string | undefined>((state.filter ? state.filter?.phone_validation_status : ""))

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [])

  const resetData = () => {
    if (initialQueryState.hasOwnProperty('filter')) {
      initialQueryState.filter = undefined;
      updateState({ ...initialQueryState })

    } else {
      updateState({ filter: undefined, ...initialQueryState })
    }
  }

  const filterData = () => {
    const filter = {
      verified: verified,
      email_validation_status: emailValidationStatus,
      email_verify_status: emailVerifyStatus,
      phone_validation_status: phoneValidationStatus,
    }
    if (initialQueryState.hasOwnProperty('filter')) {
      initialQueryState.filter = filter;
      updateState({ ...initialQueryState })

    } else {
      updateState({
        filter: filter,
        ...initialQueryState,
      })
    }
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-campaign-table-filter='form'>

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Verified:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-campaign-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setVerified(e.target.value)}
              value={verified}
            >
              <option value=''>All</option>
              <option value='verified'>Verified</option>
              <option value='pending'>Pending Verification</option>
            </select>
          </div>
          {/* end::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Email Validation:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-campaign-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setEmailValidationStatus(e.target.value)}
              value={verified}
            >
              <option value=''>All</option>
              <option value='valid'>Valid</option>
              <option value='invalid'>Invalid</option>
            </select>
          </div>
          
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Email Verification:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-campaign-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setEmailVerifyStatus(e.target.value)}
              value={verified}
            >
              <option value=''>All</option>
              <option value='subscribed'>Subscribed</option>
              <option value='unsubscribed'>Unsubscribed</option>
              <option value='bounced'>Bounced</option>
              <option value='soft_bounced'>Soft bounced</option>
              <option value='complained'>Complained</option>
            </select>
          </div>
          
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Phone Validation:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-campaign-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setPhoneValidationStatus(e.target.value)}
              value={verified}
            >
              <option value=''>All</option>
              <option value='valid'>Valid</option>
              <option value='invalid'>Invalid</option>
            </select>
          </div>
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-campaign-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-campaign-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { ListFilter }
