/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Customer } from '../customer-management/list/core/_models'
import { getTopCustomerList } from './core/_requests'

type Props = {
  className: string
}

const CustomerList: React.FC<Props> = ({ className }) => {

  const [topCustomerList, setTopCustomerList] = useState<Customer[]>()

  const abbreviate_number = function (num, fixed) {
    if (!num) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
  }

  useEffect(() => {
    getTopCustomerList().then((response) => {
      setTopCustomerList(response)
    })
  }, [])

  return (
    <div className={clsx('card', className)}>
      <div className='card-header align-items-center border-0 mt-3' style={{minHeight: "10px"}}>
        <h3 className='card-title align-items-start flex-column background-color-rh top-title'>
          <span className='fw-bolder fs-3 w-100 text-white'>Top Customers</span>
        </h3>
      </div>
      <div className='card-body pt-5'>
        {topCustomerList && topCustomerList.length &&
          topCustomerList.map(function (i) {
            return (
              <div className='d-flex mb-7' key={i.id}>
                <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                  <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                      {i.customer_name}
                    </a>
                    <span className='text-gray-400 fw-bold fs-7 my-1'>{i.email}</span>
                  </div>
                  <div className='text-end py-lg-0 py-2'>
                    <span className='text-gray-800 fw-boldest fs-3'>{abbreviate_number(i.campaignCount, 0)}</span>
                    <span className='text-gray-400 fs-7 fw-bold d-block'>Campaign</span>
                  </div>
                </div>
              </div>
            )
          })

        }
      </div>
    </div>
  )
}

export { CustomerList }
