import { FC } from 'react'
import { KTIcon } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { Role } from '../../core/_models'
import { ListFilter } from './ListFilter'
import { exportUsers } from '../../core/_requests'
import { useAuth } from '../../../../../../auth'
const FileDownload = require('js-file-download');
type Props = {
  roles: Role[] | undefined
}

const ListToolbar: FC<Props> = ({ roles }) => {
  const { setItemIdForUpdate } = useListView()
  const { currentUser } = useAuth()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const exportItem = async () => {
    const response = await exportUsers()
    FileDownload(response, 'UserList.csv');
  }
  const userCreateAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:new") > -1)) ? true : false;

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ListFilter roles={roles} />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={exportItem}>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      {userCreateAccess &&
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add User
        </button>
      }
      {/* end::Add user */}
    </div>
  )
}

export { ListToolbar }
