/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const ListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (page: number | undefined | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({ page, take: pagination.take || 10 })
  }
  
  const pageSec =  (Math.floor(pagination.page / 10) * 10) + (pagination.page % 10 <= 7 ? 0 : 5);
  const pages = Array.from({ length: pagination.pageCount }, (_, i) => i + 1).slice(pageSec, pageSec + 10)

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.page === 1,
              })}
            >
              <a onClick={() => updatePage(1)} style={{ cursor: 'pointer' }} className='page-link'>
                First
              </a>
            </li>
            <li className={clsx('page-item', {
              disabled: isLoading || !pagination.hasPreviousPage,
              previous: pagination.hasPreviousPage,
              next: pagination.hasNextPage,
            })}
            >
              <a
                className={clsx('page-link', {
                  'page-text': true,
                  'me-5': true,
                })}
                onClick={() => { if (pagination.hasPreviousPage) { updatePage(pagination.page - 1) } }}
                style={{ cursor: 'pointer' }}
              >Previous</a>
            </li>
            {pages.map((page) => (
              <li
                key={page}
                className={clsx('page-item', {
                  active: pagination.page === page,
                  disabled: isLoading,
                  previous: pagination.hasPreviousPage,
                  next: pagination.hasNextPage,
                })}
              >
                <a
                  className={clsx('page-link')}
                  onClick={() => updatePage(page)}
                  style={{ cursor: 'pointer' }}
                >{page}</a>
              </li>
            ))}

            <li className={clsx('page-item', {
              disabled: isLoading || !pagination.hasNextPage,
              previous: pagination.hasPreviousPage,
              next: pagination.hasNextPage,
            })}
            >
              <a
                className={clsx('page-link', {
                  'page-text': true,
                  'me-5': false,
                })}
                onClick={() => { if (pagination.hasNextPage) { updatePage(pagination.page + 1) } }}
                style={{ cursor: 'pointer' }}
              >Next</a>
            </li>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.page === pagination.pageCount! - 2,
              })}
            >
              <a
                onClick={() => updatePage(pagination.pageCount! - 2)}
                style={{ cursor: 'pointer' }}
                className='page-link'
              >
                Last
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { ListPagination }
