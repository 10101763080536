import { Response } from '../../../../../../../_melbarr/helpers'
import { Customer } from '../../../customer-management/list/core/_models'
import { User } from '../../../user-management/list/core/_models'
export type Campaign = {
  id?: string,
  customer?: Customer,
  customer_option?: SelectOption,
  customer_id?: number,
  campaign_id: string,
  band_name: string,
  allocation?: number,
  cpl?: string[],
  cpl_list?: SelectOption[],
  cpc?: string,
  department?: string[],
  department_list?: SelectOption[],
  department_id?: number[],
  job_level?: string[],
  job_level_list?: SelectOption[],
  job_level_id?: number[],
  employee_range?: string[],
  employee_range_list?: SelectOption[],
  employee_range_id?: number[],
  revenue?: string[],
  revenue_list?: SelectOption[],
  revenue_id?: number[],
  industry?: string[],
  industry_list?: SelectOption[],
  industry_id?: number[],
  country?: string[],
  country_list?: SelectOption[],
  country_id?: number[],
  abm_file?: string,
  suppression_file?: string,
  job_title_file?: string,
  abm_list?: string[],
  suppression_list?: string[],
  job_title_list?: string[],
  project_doc_file_path?: string,
  project_doc_file?: File,
  project_doc_file_name?: string,
  promotional_email_file?: File,
  promotional_email_file_name?: string,
  promotional_email_html?: string,
  promotional_email_subject?: string,
  promotional_email_from_name?: string,
  promotional_email_reply_to?: string,
  promotional_email_from_email?: string,
  schedule_promotional_email?: boolean,
  schedule_promotional_email_datetime?: Date,
  schedule_promotional_email_timezone?: string,
  promotional_email_recipients?: number,
  promotional_email_open_count?: number,
  promotional_email_click_count?: number,
  promotional_email_unsubscribe_count?: number,
  promotional_email_bounce_count?: number,
  promotional_email_complaint_count?: number,
  email_verify?: boolean,
  phone_verify?: boolean,
  schedule_batch?: boolean,
  is_campaign_started?: boolean,
  is_data_extracted?: boolean,
  is_campaign_scheduled?: boolean,
  is_campaign_schedule_completed?: boolean,
  is_ready_for_customer?: boolean,
  operations_assigned_by?: User,
  operations_assigned_to?: number[],
  is_operation_completed?: boolean,
  is_qc_completed?: boolean,
  qc_assigned_by?: User,
  qc_assigned_to?: number[],
  is_upload_completed?: boolean,
  uploader_assigned_by?: User,
  total_lead_filtered?: number,
  leads_email_recipients?: number,
  leads_phone_recipients?: number,
  leads_email_verified?: number,
  leads_phone_verified?: number,
  leads_email_completed?: number,
  is_active?: boolean,
  campaign_expiry_date?: Date,
  assign_to_list?: SelectOption[],
  admin_status?: string,
  admin_progress_percent?: number,
  output_file_path?: string,
}

export type CampaignQuery = {
  page: number,
  items_per_page: number,
  search?: string,
  filter_role?: number,
  filter_verified?: boolean,
}

export type SelectList = {
  label: string,
  name: string,
  value: any,
  __isNew__: boolean,
  options?: any,
  type?: string,
  parent?: string,
}

export type SelectOption = {
  label: string,
  value: any,
  __isNew__: boolean,
  id?: number,
  type?: string,
  parent?: string,
}


export type CampaignsQueryResponse = Response<Array<Campaign>>
export type CampaignLeadsQueryResponse = Response<Array<Campaign>>


export const initialCampaign: Campaign = {
  campaign_id: '',
  band_name: ''
}
