import { FC } from 'react'
import { Campaign } from '../../core/_models'
import Moment from 'moment';

type Props = {
  campaign?: Campaign
}

const StatusCell: FC<Props> = ({ campaign }) => {
  const badgeColor = ((campaign?.admin_status?.includes("Pending") || campaign?.admin_status?.includes("waiting")) ? "danger" :
    ((campaign?.admin_status?.includes("Ongoing") || campaign?.admin_status?.includes("progress") ) ? "success" :
      ((campaign?.admin_status?.includes("Expired")) ? "light" :
        ((campaign?.admin_status?.includes("Send to customer")) ? "primary" : 'warning'
        ))))
  return <>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex' }}>
        <div className={`badge badge-${badgeColor}`} > {campaign?.admin_status}</div>
      </div>
      <div>
        {campaign?.is_ready_for_customer && <div className='fw-bolder'>{(campaign?.campaign_expiry_date) ? Moment(campaign?.campaign_expiry_date).format('YYYY-MM-DD') : ""}</div>}
      </div>
    </div>
  </>
}

export { StatusCell }
