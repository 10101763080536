/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'
      style={{
        backgroundImage: `url('/media/logos/login_bg.jpg')`,
        backgroundSize: 'cover'
      }}>
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-1'
      >
      </div>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-2'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10' style={{
            background: "#0000008f",
            borderRadius: "16px",
            boxShadow: "0px 0px 80px rgb(2 134 109)",
            WebkitBackdropFilter: "blur(5px)",
            border: "2px solid rgb(2 134 109 / 65%)",
            height: "600px",
            display: "flex",
            verticalAlign: "middle",
            alignItems: "start",
            padding: "5rem !important",
          }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export { AuthLayout }
