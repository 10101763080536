/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Campaign } from '../list/core/_models'
import { EditModalForm } from '../list/edit-modal/EditModalForm'
import { UploadModalForm } from '../list/edit-modal/UploadModalForm'

type Props = {
  campaign: Campaign
}

const CampaignForm: React.FC<Props> = ({ campaign }) => {
  const [uploadCsv, setUploadCsv] = useState<boolean>(false);

  return (
    <div className={`card pb-20`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-1 mb-1 text-color-rh'> {uploadCsv ? "Add Campaign CSV" : (campaign.id ? "Edit Campaign" : "Add Campaign")} </span>
        </h3>
        {!campaign.id &&
          <div className="form-check form-switch form-check-custom form-check-solid me-10">
            <input
              type='checkbox'
              name='upload_csv'
              defaultChecked={uploadCsv}
              onChange={() => setUploadCsv(!uploadCsv)}
              className='form-check-input h-20px w-30px'
            />
            <label className="form-check-label">
              Upload Campigns CSV
            </label>
          </div>
        }
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {uploadCsv &&
          <UploadModalForm redirectType='navigate' isCampaignLoading={false} campaign={campaign} />
        }
        {!uploadCsv &&
          <EditModalForm redirectType='navigate' isCampaignLoading={false} campaign={campaign} />
        }
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { CampaignForm }
