import { KTIcon, MODALTYPE } from '../../../../../../../_melbarr/helpers'
import { useListView } from '../core/ListViewProvider'

const EditModalHeader = () => {
  const { setItemIdForUpdate, updateModalType } = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      {updateModalType === MODALTYPE.EDIT &&
        <h2 className='fw-bolder'>Add Campaign</h2>
      }
      {updateModalType === MODALTYPE.ASSIGNOPERATION &&
        <h2 className='fw-bolder'>Assign Campaign to Operations</h2>
      }
      {updateModalType === MODALTYPE.ASSIGNQA &&
        <h2 className='fw-bolder'>Assign Campaign to QA</h2>
      }
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{ cursor: 'pointer' }}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export { EditModalHeader }
