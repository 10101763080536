import { FC } from "react"
import { Campaign } from "../core/_models"
import { useQueryResponse } from "../core/QueryResponseProvider"
import { useListView } from "../core/ListViewProvider"

type Props = {
  campaign: Campaign
}

const EditMessage: FC<Props> = ({ campaign }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontSize: '14px',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: '35%',
    left: '35%',
  }

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>
    Campaign details saved successfully. 
    <div className='text-center pt-5'>
      <button
        type='reset'
        onClick={() => cancel(true)}
        className='btn btn-light me-3'
        data-kt-campaigns-modal-action='cancel'
      >
        Close
      </button>
    </div>
  </div >
}

export { EditMessage }
