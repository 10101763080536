/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Contact } from '../../core/_models'

type Props = {
  contact: Contact
}

const CompanyInfoCell: FC<Props> = ({ contact }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
        {contact?.company?.company_name}
      </span>
      {contact?.company?.email &&
        <span className='text-muted fw-semibold d-block fs-7'>
        <a href={`mailto:${contact?.company?.email}`} target='_blank' rel="noreferrer">{contact?.company?.email}</a>
        </span>
      }
      {contact?.company?.contact_no &&
        <span className='text-muted fw-semibold d-block fs-7'>
          <a href={`tel:${contact?.company?.contact_no}`} target='_blank' rel="noreferrer">{contact?.company?.contact_no}</a>
        </span>
      }
      <span className='text-muted fw-semibold d-block fs-7'>
        <a href={`${contact?.company?.company_link}`} target='_blank' rel="noreferrer">{contact?.company?.company_link}</a>
      </span>
      <span className='text-muted fw-semibold d-block fs-7'>
        <a href={`${contact?.company?.company_domain}`} target='_blank' rel="noreferrer">{contact?.company?.company_domain}</a>
      </span>
      <span className='text-muted fw-semibold d-block fs-7'>
        <a href={`${contact?.company?.company_website}`} target='_blank' rel="noreferrer">{contact?.company?.company_website} {contact?.company?.ip_address ? "(" + contact?.company?.ip_address + ")" : ""}</a>
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {contact?.company?.company_employee_range}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {contact?.company?.company_revenue_range}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {contact?.company?.sub_industry}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'>
        {contact?.company?.address}, {contact?.company?.street}, {contact?.company?.city}, {contact?.company?.state}, {contact?.company?.country?.country_name} - {contact?.company?.zip_code}
      </span>
    </div>
  </div>
)

export { CompanyInfoCell }
