/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getNewCampaignList } from './core/_requests'
import { Campaign } from '../campaign-management/list/core/_models'

type Props = {
  className: string
}

const NewCampaignTable: React.FC<Props> = ({ className }) => {
  const [newCampaignList, setNewCampaignList] = useState<Campaign[]>()

  useEffect(() => {
    getNewCampaignList().then((response) => {
      setNewCampaignList(response)
    })
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5' style={{minHeight: "10px"}}>
        <h3 className='card-title align-items-start flex-column background-color-rh top-title'>
          <span className='card-label fw-bold fs-3 mb-1 w-100 text-white'>New Campaigns</span>
        </h3>
      </div>
      <div className='card-body py-3 scroll-y h-400px'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-white background-color-rh'>
                <th className='ps-4'>Customer Name</th>
                <th className='w-125px'>Campaign Details</th>
              </tr>
            </thead>

            {newCampaignList && newCampaignList.length &&
              <tbody>
                {newCampaignList.map(function (i) {
                  return (<tr key={i.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {i.customer?.customer_name}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {i.customer?.email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {i.campaign_id}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>{i.band_name}</span>
                    </td>
                  </tr>
                  )
                })
                }
              </tbody>
            }
          </table>
        </div>
      </div>
    </div>
  )
}

export { NewCampaignTable }
