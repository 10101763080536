import { useRef, useState } from 'react';
import { KTIcon } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { exportContacts, uploadContacts } from '../../core/_requests'
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { useAuth } from '../../../../../../auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FileDownload = require('js-file-download');

const csvData = [
  ["First name", "Last name", "Email", "Contact No.", "Profile Link", "Country",
    "Company Name", "Company Domain", "Company Website", "Company Link",
    "Company Email", "Company Contact no.", "Company Sub industry", "Company Ip address",
    "Company employee range", "Company revenue range",
    "Company Address Line 1", "Company Address Line 2", "Company City", "Company State", "Company Country", "Company Zipcode",
    "Job Title", "Job Area", "Job Function", "Job Level"]
];

const ListToolbar = () => {
  const { setItemIdForUpdate, } = useListView()
  const [uploadCSVData, setUploadCSVData] = useState<File>();
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [isUploadReady, setIsUploadReady] = useState<boolean>(false)
  const [ìsUploading, setIsUploading] = useState<boolean>(false)
  const contactFileRef = useRef<HTMLInputElement>(null);
  const { currentUser } = useAuth()
  const openAddContactModal = () => {
    setItemIdForUpdate(null)
  }
  const createAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:new") > -1)) ? true : false;

  const exportItem = async () => {
    const response = await exportContacts()
    FileDownload(response, 'ContactList.csv');
  }

  let arrayChecker = (arr, target) => target.every(v => arr.includes(v));

  const handleChange = (event) => {
    setIsUploadReady(false);
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      encoding: "utf-8",
      complete: function (results) {
        const headers = results.meta.fields;
        if (results.data && results.data.length && arrayChecker(headers, csvData[0])) {
          setUploadCSVData(file);
          setHasErrors(false);
          setIsUploadReady(true);
        }
        else {
          setHasErrors(true);
        }
      },
      error: function (err, file, inputElem, reason) {
        console.log(err);
        console.log(reason);
        setHasErrors(true);
      },
    });
  };

  const uploadLeadsItem = () => {
    if (isUploadReady) {
      setIsUploading(true);
      if (uploadCSVData && uploadCSVData.size) {
        uploadContacts(uploadCSVData).then(() => {
          toast("Contacts uploaded successfully...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        });
      }

      toast("CSV Uploaded successfully. The contacts will be uploaded in sometime.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
      setIsUploading(false);
      if (contactFileRef.current) {
        contactFileRef.current.value = "";
      }
    }

  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={exportItem}>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>
      {/* end::Export */}
      {createAccess &&
        <div className='me-0'>
          <button
            className='btn btn-primary me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            Upload Leads
          </button>
          <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Upload Leads CSV</div>
              {hasErrors === true && (
                <div className='mt-lg-5 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Invalid file uploaded. Please download the template and use that for upload.
                  </div>
                </div>
              )}
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>
              <div className='mb-10'>
                <div>
                  <input
                    placeholder='Campaign output file path'
                    type='file'
                    name='output_file_path'
                    className='form-control'
                    autoComplete='off'
                    accept=".csv"
                    ref={contactFileRef}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <CSVLink data={csvData} className='me-5 btn btn-sm btn-primary' filename={"ContactUploadTemplate.csv"} >Download Template</CSVLink>
                {!ìsUploading && <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                  onClick={uploadLeadsItem} disabled={!isUploadReady}>
                  Upload
                </button>}
                {ìsUploading && <button className='btn btn-sm btn-default'>
                  Uploading...
                </button>}
              </div>
            </div>
          </div>
        </div>
      }
      {/* begin::Add user */}
      {createAccess &&
        <button type='button' className='btn btn-primary' onClick={openAddContactModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add Contact
        </button>
      }
      {/* end::Add user */}
      
      <ToastContainer />
    </div>
  )
}

export { ListToolbar }
