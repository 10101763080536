/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
  value: string
}

const CustomCell: FC<Props> = ({ value }) => (
  <div className='d-flex align-items-center'>
    <span>{value}</span>
  </div>
)

export { CustomCell }
