import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_melbarr/layout/core'
import {ListWrapper} from '../../modules/apps/admin/campaign-management/list/List'

const CampaignsWrapper = () => {
  const intl = useIntl()
  return (
      <div className='container-xxl' style={{
      paddingTop: '90px'
    }}>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CAMPAIGN'})}</PageTitle>
      <ListWrapper />
    </div>
  )
}

export {CampaignsWrapper}
