// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { Campaign } from '../../core/_models'
import { useNavigate } from 'react-router-dom';


type Props = {
  row: Row<Campaign>
}

const CustomRow: FC<Props> = ({ row }) => {
  const navigate = useNavigate();
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            onClick={() => ((cell.column.id !== 'actions' && cell.column.id !== 'selection')? navigate(`/campaign/${row.original.id}`) : undefined)}
            className={clsx({ 'text-center min-w-100px': (cell.column.id === 'actions' ||  cell.column.id === 'status') })}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export { CustomRow }
