import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { MODALTYPE, isNotEmpty } from '../../../../../../../_melbarr/helpers'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ListLoading } from '../components/loading/ListLoading'
import {
  updateCampaignAssignToOperations,
  updateCampaignAssignToQC,
} from '../../../campaign-management/list/core/_requests'
import Select from 'react-select';
import { getUserList } from '../../../../../auth/core/_requests'
import { Campaign, SelectList, SelectOption  } from '../../../campaign-management/list/core/_models'

type Props = {
  isCampaignLoading: boolean
  campaign: Campaign
  modalType: string
}

const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: '#f9f9f9',
    borderColor: '#f9f9f9',
    borderRadius: '0.475rem',
    padding: "0.2rem 0 0.2rem 1rem ",
    fontSize: "1.1rem",
    fontWeight: "500",
    lineHeight: "1.5",
    color: "#4b5675",
    border: '0 !important',
    boxShadow: '0 !important',
    "&:hover": {
      backgroundColor: '#f1f1f2',
      borderColor: '#f1f1f2',
      border: '0 !important',
      boxShadow: '0 !important',
    }
  })
};

const editCampaignSchema = Yup.object().shape({
  assign_to_list: Yup.array()
    .min(1, 'CPL is required')
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )

})

const AssignModalForm: FC<Props> = ({ campaign, isCampaignLoading, modalType }) => {
  const { setItemIdForUpdate } = useListView()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userList, setUserList] = useState<SelectList[]>([])

  const [campaignForEdit, setCampaignForEdit] = useState<Campaign>({
    ...campaign,
  })
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      window.location.reload();
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    const fetchUserList = getUserList()
    Promise.all([
      fetchUserList,
    ]).then((responses) => {
      const users: SelectList[] = [];
      responses[0].data?.forEach((u) => {
        users.push({
          __isNew__: false,
          label: u.first_name + ' ' + u.last_name,
          name: u.first_name + ' ' + u.last_name,
          value: u.id,
        })
        if (modalType === MODALTYPE.ASSIGNOPERATION && campaign.operations_assigned_to?.includes(u.id?.valueOf() || 0)) {
          const cp: SelectOption = {
            label: u.first_name + ' ' + u.last_name,
            value: u.id,
            __isNew__: false,
            id: u.id?.valueOf(),
          }
          campaignForEdit.assign_to_list?.push(cp);
        } else
          if (modalType === MODALTYPE.ASSIGNQA && campaign.qc_assigned_to?.includes(u.id?.valueOf() || 0)) {
            const cp: SelectOption = {
              label: u.first_name + ' ' + u.last_name,
              value: u.id,
              __isNew__: false,
              id: u.id?.valueOf(),
            }
            campaignForEdit.assign_to_list?.push(cp);
          }
      });

      setUserList(users);
      setCampaignForEdit(campaignForEdit);
      setIsLoading(false)
    });
  }, [])

  const formik = useFormik({
    initialValues: campaignForEdit,
    validationSchema: editCampaignSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const assignedUsers = values.assign_to_list?.map(i => i.value || 0) || [];
        if (values.id && isNotEmpty(values.id) && assignedUsers && assignedUsers.length) {
          if (modalType === MODALTYPE.ASSIGNOPERATION) {
            await updateCampaignAssignToOperations(values.id, assignedUsers)
          }
          else {
            await updateCampaignAssignToQC(values.id, assignedUsers)
          }
          cancel(true)
          setHasErrors(false);
        }
      } catch (ex) {
        setHasErrors(true);
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_campaign_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_campaign_scroll'
        >
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please close this form and try again.
              </div>
            </div>
          )}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Assign to</label>
            {/* end::Label */}

            {/* begin::Input */}
            <Select
              isMulti
              menuIsOpen={true}
              classNamePrefix={"react-select-relative"}
              {...formik.getFieldProps('assign_to_list')}
              className={clsx(
                'basic-multi-select  mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.assign_to_list && formik.errors.assign_to_list },
                {
                  'is-valid': formik.touched.assign_to_list && !formik.errors.assign_to_list,
                }
              )}
              name="assign_to_list"
              options={userList}
              styles={colourStyles}
              onChange={selectedOption => {
                let event = { target: { name: 'assign_to_list', value: selectedOption } }
                formik.handleChange(event)
              }}
              isDisabled={formik.isSubmitting || isCampaignLoading }
            />
            {formik.touched.assign_to_list && formik.errors.assign_to_list && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.assign_to_list}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-campaigns-modal-action='cancel'
            disabled={formik.isSubmitting || isCampaignLoading || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-campaigns-modal-action='submit'
            disabled={isCampaignLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Assign</span>
            {(formik.isSubmitting || isCampaignLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCampaignLoading || isLoading) && <ListLoading />}
    </>
  )
}

export { AssignModalForm }
