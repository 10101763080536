import { useEffect, useState } from 'react'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListHeader } from './components/header/ListHeader'
import { Table } from './table/Table'
import { EditModal } from './edit-modal/EditModal'
import { KTCard } from '../../../../../../_melbarr/helpers'
import { getCompanies, getCountryList, getSubIndustryList } from './core/_requests'
import { Company, SelectList } from './core/_models'
import { getEmployeeRangeList, getRevenueList } from '../../campaign-management/list/core/_requests'

const List = () => {
  const { itemIdForUpdate } = useListView()
  const [companies, setCompanies] = useState<Company[] | undefined>()
  const [country, setCountry] = useState<SelectList[] | undefined>()
  const [empRangeList, setEmpRangeList] = useState<SelectList[]>([])
  const [revenueList, setRevenueList] = useState<SelectList[]>([])
  const [subIndustryList, setSubIndustryList] = useState<SelectList[]>([])

  useEffect(() => {
    const fetchCompanyList = getCompanies("")
    const fetchCountryList = getCountryList()
    const fetchEmpRangeList = getEmployeeRangeList()
    const fetchRevenueList = getRevenueList()
    const fetchSubIndustry = getSubIndustryList()
    Promise.all([
      fetchCompanyList,
      fetchCountryList,
      fetchEmpRangeList,
      fetchRevenueList,
      fetchSubIndustry
    ]).then((responses) => {
      setCompanies(responses[0].data || [])
      setCountry(responses[1] || [])
      setEmpRangeList(responses[2] || [])
      setRevenueList(responses[3] || [])
      setSubIndustryList(responses[4] || [])
    })
  }, [])
  return (
    <>
      <KTCard>
        <ListHeader  />
        <Table />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditModal companies={companies} country={country} 
           employee_range={empRangeList} revenue={revenueList} subindustry={subIndustryList} />}
    </>
  )
}

const ListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <List />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ListWrapper }
