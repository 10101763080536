/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../../_melbarr/assets/ts/components'
import { KTIcon, QUERIES } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteUser, deactivateUser, activateUser } from '../../core/_requests'
import { User } from '../../core/_models'
import { useAuth } from '../../../../../../auth'

type Props = {
  user: User
}

const ActionsCell: FC<Props> = ({ user }) => {
  const { currentUser } = useAuth()
  const { setItemIdForUpdate } = useListView()
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(user.id)
  }

  const deleteItem = useMutation(() => deleteUser(user.id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      refetch();
    },
  })

  const activateItem = useMutation(() => activateUser(user.id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      refetch();
    },
  })

  const deactivateItem = useMutation(() => deactivateUser(user.id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      refetch();
    },
  })

  const userEditAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:edit") > -1)) ? true : false;
  const userDeleteAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:delete") > -1)) ? true : false;
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {userEditAccess &&
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Edit
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {userDeleteAccess && !user.is_active && user.verified &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='deactivate_row'
              onClick={async () => await activateItem.mutateAsync()}
            >
              Activate
            </a>
          </div>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {userDeleteAccess && user.is_active && user.verified &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='deactivate_row'
              onClick={async () => await deactivateItem.mutateAsync()}
            >
              Deactivate
            </a>
          </div>
        }
        {userDeleteAccess &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </a>
          </div>
        }
      </div>
    </>
  )
}

export { ActionsCell }
