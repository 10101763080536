/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../../_melbarr/assets/ts/components'
import { KTIcon, QUERIES, MODALTYPE } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { CampaignLeads } from '../../core/_models'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '../../../../../../auth'
import { validateLead, verifyLead } from '../../core/_requests'

type Props = {
  campaignleads: CampaignLeads
}

const ActionsCell: FC<Props> = ({ campaignleads }) => {
  const { setItemIdForUpdate, setUpdateModalType } = useListView()
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const editAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:edit") > -1)) ? true : false;
  const verifyAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:validate") > -1)) ? true : false;
  const validateAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "campaign:*" || str.indexOf("campaign:verify") > -1)) ? true : false;

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const verifyItem = useMutation(() => verifyLead(campaignleads.id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CUSTOMER_LIST}-${query}`])
      refetch();
    },
  })

  const validateItem = useMutation(() => validateLead(campaignleads.id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CUSTOMER_LIST}-${query}`])
      refetch();
    },
  })


  const openModal = (modalType) => {
    setUpdateModalType(modalType || MODALTYPE.EDIT);
    setItemIdForUpdate(campaignleads.id)
  }
  return (
    <>
      {!campaignleads.is_valid &&
        <>
          {editAccess &&
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id='tooltip-user-name'>Edit Lead info</Tooltip>}
            >
              <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={() => openModal(MODALTYPE.EDITLEAD)}>
                <KTIcon iconName='pencil' className='fs-3 text-grey' />
              </button>
            </OverlayTrigger>
          }
          {verifyAccess && !campaignleads.operation_verified &&
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id='tooltip-user-name'>Verify Lead</Tooltip>}
            >
              <button className='btn btn-icon btn-bg-warning btn-active-color-warning btn-sm me-1'
                onClick={async () => await verifyItem.mutateAsync()}>
                <KTIcon iconName='check-square' className='fs-3 text-white' />
              </button>
            </OverlayTrigger>
          }
          {validateAccess && !campaignleads.qc_verified &&
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id='tooltip-user-name'>Validate lead</Tooltip>}
            >
              <button className='btn btn-icon btn-bg-success btn-active-color-success btn-sm'
                onClick={async () => await validateItem.mutateAsync()}>
                <KTIcon iconName='double-check' className='fs-3 text-white' />
              </button>
            </OverlayTrigger>
          }
        </>
      }
    </>
  )
}

export { ActionsCell }
