import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty } from '../../../../../../../_melbarr/helpers'
import { initialUser, Role, User } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ListLoading } from '../components/loading/ListLoading'
import { createUser, updateUser, checkEmailUsernameUnique } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { getUserList } from '../../../../../auth/core/_requests'
import { EditMessage } from './EditMessage'
import { useAuth } from '../../../../../auth'

type Props = {
  isUserLoading: boolean
  user: User
  roles: Role[] | undefined
}

let currentEditUser: User;
let currentUserEmail: string;
let currentUserUsername: string;
let previousEmailError: boolean;
let previousUsernameError: boolean;

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .test('Unique Email', 'Email already in use',
      function (value, context) {
        if (currentUserEmail !== value) {
          return new Promise((resolve, reject) => {
            currentUserEmail = value;
            checkEmailUsernameUnique(`${value}`, "", (currentEditUser.id || 0)).then((res) => {
              previousEmailError = !res;
              resolve(!res)
            }).catch((error) => {
              previousEmailError = false;
              resolve(false);
            })
          })
        } else {
          return previousEmailError;
        }
      }
    ),
  first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  role_id: Yup.number()
    .min(1, 'Please select user role')
    .required('User Role is required'),
  username: Yup.string()
    .min(1, 'Please select user role')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required')
    .matches(
      /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
      "Invalid username"
    )
    .test('Unique Username', 'Username already in use',
      function (value, context) {
        if (currentUserUsername !== value) {
          return new Promise((resolve, reject) => {
            currentUserUsername = value;
            checkEmailUsernameUnique("", `${value}`, (currentEditUser.id || 0)).then((res) => {
              previousUsernameError = !res;
              resolve(!res)
            }).catch((error) => {
              previousUsernameError = false;
              resolve(false);
            })
          })
        } else {
          return previousUsernameError;
        }
      }
    ),
})

const EditModalForm: FC<Props> = ({ user, isUserLoading, roles }) => {
  const { setItemIdForUpdate } = useListView()
  const { currentUser } = useAuth()
  const { refetch } = useQueryResponse()
  const [users, setUsers] = useState<User[] | undefined>()
  const [editUser, setEditUser] = useState<User>(user)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [viewVerificationCodeModal, setViewVerificationCodeModal] = useState<boolean>(false)
  const userCreateAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:new") > -1)) ? true : false;
  const userEditAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:edit") > -1)) ? true : false;

  const [userForEdit] = useState<User>({
    ...user,
    photo: user.photo || initialUser.photo,
    user_role: user.user_role || initialUser.user_role,
    role_id: user.user_role?.id.toString() || initialUser.role_id,
    first_name: user.first_name || initialUser.first_name,
    last_name: user.last_name || initialUser.last_name,
    email: user.email || initialUser.email,
    username: user.username || initialUser.username,
    manager_id: user.manager?.id?.toString() || initialUser.manager_id,
  })

  currentEditUser = userForEdit;
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    setViewVerificationCodeModal(false);
    getUserList().then((response) => {
      setUsers(response.data)
    })

    if(!userCreateAccess && !user.id) {
      cancel(true)
    }

    
    if(!userEditAccess && user.id) {
      cancel(true)
    }
  }, [])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (isNotEmpty(values.id)) {
          await updateUser(values)
          cancel(true)
        } else {
          setEditUser(await createUser(values) || {})
          setViewVerificationCodeModal(true);
        }

        setHasErrors(false);
      } catch (ex) {
        setHasErrors(true);
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please close this form and try again.
              </div>
            </div>
          )}

          {/* end::Title */}
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>First Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='First name'
                  {...formik.getFieldProps('first_name')}
                  type='text'
                  name='first_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                    {
                      'is-valid': formik.touched.first_name && !formik.errors.first_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.first_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Last Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Last name'
                  {...formik.getFieldProps('last_name')}
                  type='text'
                  name='last_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                    {
                      'is-valid': formik.touched.last_name && !formik.errors.last_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.last_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Email</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {/* end::Input */}
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Username</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Username'
                  {...formik.getFieldProps('username')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.username && formik.errors.username },
                    {
                      'is-valid': formik.touched.username && !formik.errors.username,
                    }
                  )}
                  type='text'
                  name='username'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {/* end::Input */}
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.username}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Input group */}
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-5'>Role</label>
                {/* end::Label */}
                {/* begin::Roles */}
                {/* begin::Input row */}
                <select
                  id='kt_modal_update_role_option_0'
                  {...formik.getFieldProps('role_id')}
                  name='role_id'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.role_id && formik.errors.role_id },
                    {
                      'is-valid': formik.touched.role_id && !formik.errors.role_id,
                    }
                  )}
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  value={formik.values.role_id}
                  disabled={formik.isSubmitting || isUserLoading}
                >
                  <option value=''></option>
                  {roles?.map(role => (
                    <option key={role.id} value={role.id}>{role.role_name}</option>
                  ))}
                </select>
                {formik.touched.role_id && formik.errors.role_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.role_id}</span>
                  </div>
                )}
                {/* end::Roles */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-5'>Manager</label>
                {/* end::Label */}
                {/* begin::Roles */}
                {/* begin::Input row */}
                <select
                  id='kt_modal_update_role_option_0'
                  {...formik.getFieldProps('manager_id')}
                  name='manager_id'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.manager_id && formik.errors.manager_id },
                    {
                      'is-valid': formik.touched.manager_id && !formik.errors.manager_id,
                    }
                  )}
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  value={formik.values.manager_id}
                  disabled={formik.isSubmitting || isUserLoading}
                >
                  <option value=''></option>
                  {users?.map(user => (
                    <option key={user.id} value={user.id?.toString()}>{user.first_name} {user.last_name}</option>
                  ))}
                </select>
                {formik.touched.manager_id && formik.errors.manager_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.manager_id}</span>
                  </div>
                )}
                {/* end::Roles */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
      {(viewVerificationCodeModal && editUser) && <EditMessage user={editUser} />}
    </>
  )
}

export { EditModalForm }
