import { useListView } from '../../core/ListViewProvider'
import { ListToolbar } from './ListToolbar'
import { ListGrouping } from './ListGrouping'
import { ListSearchComponent } from './ListSearchComponent'
import { Campaign } from '../../../../campaign-management/list/core/_models'
import { FC } from 'react'

type Props = {
  campaign: Campaign
}

const ListHeader: FC<Props> = ({ campaign }) => {
  const { selected } = useListView()

  return (
    <div className='card-header border-0 pt-6'>
    
      <ListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ListGrouping campaign={campaign}  /> : <ListToolbar  campaign={campaign} />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { ListHeader }
