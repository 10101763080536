import { KTIcon } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { ListFilter } from './ListFilter'
import { exportCustomers } from '../../core/_requests'
import { useAuth } from '../../../../../../auth';
const FileDownload = require('js-file-download');

const ListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const { currentUser } = useAuth()
  const createAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "customer:*" || str.indexOf("customer:new") > -1)) ? true : false;

  const openAddCustomerModal = () => {
    setItemIdForUpdate(null)
  }

  const exportItem = async () => {
    const response = await exportCustomers()
    FileDownload(response, 'CustomerList.csv');
  }

  return (
    <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
      <ListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={exportItem}>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add customer */}
      {createAccess &&
        <button type='button' className='btn btn-primary' onClick={openAddCustomerModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add Customer
        </button>
      }
      {/* end::Add customer */}
    </div>
  )
}

export { ListToolbar }
