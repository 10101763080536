/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../../../_melbarr/helpers'
import { getDashboardStats } from './core/_requests'
import { Stats } from './core/_models'

type Props = {
  className: string
}

const DashboardStats: React.FC<Props> = ({ className }) => {
  const [dashboardStats, setDashboardStats] = useState<Stats>()

  const abbreviate_number = function (num, fixed) {
    if (!num) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
  }

  useEffect(() => {
    getDashboardStats().then((response) => {
      setDashboardStats(response)
    })
  }, [])
  return (
    <div className='row g-0 pb-6'>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(45deg, rgb(0 0 0) 0%,  rgb(0 0 0) 80%, rgb(35 35 36) 100%)',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='abstract-42' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>New Campaigns</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.campaign.itemCount, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(68deg, rgb(255 0 78), rgb(255 0 150))',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='user' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>Users</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.user, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(68deg, rgb(28 92 6), rgb(59 255 0))',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='badge' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>Customers</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.customer, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-3'>
        <div
          className={`card  rounded-0 shadow me-6 ${className}`}
        >
          <div className='card-body d-flex'>
            <div className='d-flex align-items-center w-100'>
              <div className='symbol symbol-60px me-3' style={{
                position: 'absolute',
                top: '-20px',
                background: 'linear-gradient(68deg, rgb(163 7 7), rgb(255 0 0))',
              }}>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName='user-tick' className='fs-xxl-1 text-white' />
                </div>
              </div>
              <div className='d-flex flex-column w-100 pt-10'>
                <div className='fs-2 text-gray-400 text-end'>Leads</div>
                <div className='fs-2 text-dark fw-bolder lh-1 text-end'>{abbreviate_number(dashboardStats?.contact, 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { DashboardStats }
