// @ts-nocheck
import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { CustomCell } from './CustomCell'
import { StatusCell } from './StatusCell'
import { ActionsCell } from './ActionsCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { User } from '../../core/_models'

const tableColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='ps-2 min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <InfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Manager' className='min-w-125px' />,
    id: 'manager',
    Cell: ({ ...props }) => <CustomCell value={(props.data[props.row.index].manager?.first_name ? props.data[props.row.index].manager?.first_name + " " : '') + (props.data[props.row.index].manager?.last_name ? props.data[props.row.index].manager?.last_name : '')} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    accessor: 'user_role.role_name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='text-center min-w-125px' />
    ),
    id: 'last_login',
    Cell: ({ ...props }) => <StatusCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ActionsCell user={props.data[props.row.index]} />,
  },
]

export { tableColumns }
