import { FC } from 'react'
import {useQuery} from 'react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, MODALTYPE, QUERIES} from '../../../../../../../_melbarr/helpers'
import {useListView} from '../core/ListViewProvider'
import { Company, SelectList } from '../../../contact-management/list/core/_models'
import { getCampaignLeadById } from '../core/_requests'

type Props = {
  companies: Company[] | undefined
  country: SelectList[] | undefined
  employee_range: SelectList[] | undefined
  revenue: SelectList[] | undefined
  subindustry: SelectList[] | undefined
}

const EditModalFormWrapper: FC<Props> = ({  companies, country, employee_range, revenue, subindustry }) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: campaignLead,
    error,
  } = useQuery(
    `${QUERIES.CONTACTS_LIST}-contact-${itemIdForUpdate}`,
    () => {
      return getCampaignLeadById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!isLoading && !error && campaignLead && MODALTYPE.EDIT) {
    return <EditModalForm isContactLoading={isLoading} campaignLead={campaignLead} country={country}  companies={companies}  employee_range={employee_range} revenue={revenue} subindustry={subindustry} />
  }

  return null
}

export {EditModalFormWrapper}
