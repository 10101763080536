/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../../../_melbarr/assets/ts/_utils'
import { useThemeMode } from '../../../../../_melbarr/partials/layout/theme-mode/ThemeModeProvider'
import { getDashboardLeadStats } from './core/_requests'
import { LeadStatDetails } from './core/_models'

type Props = {
  className: string
  chartHeight: string
  chartColor: string
}

const LeadStats: React.FC<Props> = ({ className, chartHeight, chartColor }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const [dashboardLeadStats, setDashboardLeadStats] = useState<LeadStatDetails>()

  const abbreviate_number = function (num, fixed) {
    if (!num) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
  }

  useEffect(() => {
    getDashboardLeadStats().then((response) => {
      setDashboardLeadStats(response)
    })
  }, [])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5' style={{minHeight: "10px"}}>
        <h3 className='card-title align-items-start flex-column background-color-rh top-title'>
          <span className='card-label fw-bold fs-3 mb-1 w-100 text-white'>Leads Overview</span>
        </h3>
      </div>
      <div className='card-body p-0 d-flex flex-column'>
        <div className='card-p pt-5 bg-body flex-grow-1'>
          <div className='row g-0'>
            <div className='col mr-8'>
              <div className='fs-7 text-muted fw-semibold'>Leads</div>
              <div className='d-flex align-items-center'>
                <div className='fs-4 fw-bold'>{abbreviate_number(dashboardLeadStats?.contact.total, 0)}</div>
              </div>
            </div>
            <div className='col'>
              <div className='fs-7 text-muted fw-semibold'>Company Leads</div>
              <div className='fs-4 fw-bold'>{abbreviate_number(dashboardLeadStats?.company, 0)}</div>
            </div>
          </div>
          <div className='row g-0 mt-8'>
            <div className='col mr-8'>
              <div className='fs-7 text-muted fw-semibold'>Email Verified</div>
              <div className='fs-4 fw-bold'>{abbreviate_number(dashboardLeadStats?.contact.email_verifed, 0)}</div>
            </div>
            <div className='col'>
              <div className='fs-7 text-muted fw-semibold'>Phone Verified</div>
              <div className='d-flex align-items-center'>
                <div className='fs-4 fw-bold'>{abbreviate_number(dashboardLeadStats?.contact.phone_verifed, 0)}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={chartRef}
          className='mixed-widget-3-chart card-rounded-bottom'
          data-kt-chart-color={chartColor}
          style={{ height: chartHeight }}
        ></div>
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string, chartColor: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')

  return {
    series: [
      {
        name: 'New Leads',
        data: [30, 25, 45, 30, 55, 55],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: false,
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${val}`
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export { LeadStats }
