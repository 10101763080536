import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ListLoading } from '../components/loading/ListLoading'
import { useAuth } from '../../../../../auth'
import { Campaign } from '../../../campaign-management/list/core/_models'
import { ITimezone, allTimezones, useTimezoneSelect } from 'react-timezone-select'
import DatePicker from 'react-datepicker'
import { uploadCampaignPromotionalEmail } from '../../../campaign-management/list/core/_requests'
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { KTIcon } from '../../../../../../../_melbarr/helpers'
import EditorToolbar, { modules, formats } from "../../../../../../../_melbarr/layout/core/QuillToolbar";

type Props = {
  isContactLoading: boolean
  campaign: Campaign
}

const editContactSchema = Yup.object().shape({
  promotional_email_from_email: Yup.string().nullable()
    .email('Wrong email format')
    .max(200, 'Maximum 50 symbols')
    .required('Email From is required'),
  promotional_email_reply_to: Yup.string().nullable()
    .email('Wrong email format')
    .max(200, 'Maximum 50 symbols')
    .required('Reply to Email is required'),
  promotional_email_from_name: Yup.string()
    .min(1, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email Name is required'),
  promotional_email_subject: Yup.string()
    .min(1, 'Minimum 3 symbols')
    .max(300, 'Maximum 300 symbols'),
})

const ScheduleCampaignModalForm: FC<Props> = ({ campaign, isContactLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [showTagInfos, setShowTagInfos] = useState<boolean>(false)
  const { currentUser } = useAuth()
  const [promotionalEmailHTML, setPromotionalEmailHTML] = useState<string | undefined>(campaign.promotional_email_html)
  const [promotionalEmailSchedule, setPromotionalEmailSchedule] = useState<boolean | undefined>(campaign.schedule_promotional_email)
  const [promotionalEmailScheduleDate, setPromotionalEmailScheduleDate] = useState(campaign.schedule_promotional_email_datetime || new Date())
  const [promotionalEmailTimezone, setPromotionalEmailTimezone] = useState<ITimezone>(campaign.schedule_promotional_email_timezone || 'Asia/Kolkata')

  const editAccess = currentUser && currentUser.customer?.customer_type === "crm" && currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:edit") > -1)) ? true : false;

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      window.location.reload();
    }
    setItemIdForUpdate(undefined)
  }

  const timezones = {
    ...allTimezones,
  }

  const labelStyle = 'original'
  const { options } = useTimezoneSelect({ labelStyle, timezones })


  if (campaign?.id && !editAccess) {
    cancel(true)
  }

  const formik = useFormik({
    initialValues: campaign,
    validationSchema: editContactSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const obj = {
          campaign_id: campaign.id,
          schedule_batch_email_subject: values.promotional_email_subject,
          schedule_batch_email_html: promotionalEmailHTML,
          schedule_batch_email_from_name: values.promotional_email_from_name,
          schedule_batch_email_from_email: values.promotional_email_from_email,
          schedule_batch_email_reply_to: values.promotional_email_reply_to,
          schedule_batch: promotionalEmailSchedule,
          schedule_batch_time: promotionalEmailScheduleDate,
          schedule_batch_timezone: promotionalEmailTimezone.toString(),
        }

        await uploadCampaignPromotionalEmail(obj);

        cancel(true)
        setHasErrors(false);
      } catch (ex) {
        setHasErrors(true);
      } finally {
        setSubmitting(true)
      }
    },
  })

  const showTagSection = () => {
    setShowTagInfos(!showTagInfos);
  }
  return (
    <>
      <form id='kt_modal_add_contact_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_contact_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_contact_header'
          data-kt-scroll-wrappers='#kt_modal_add_contact_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please close this form and try again.
              </div>
            </div>
          )}
          {/* end::Title */}
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Email From Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  {...formik.getFieldProps('promotional_email_from_name')}
                  placeholder='Email From Name'
                  type='email'
                  name='promotional_email_from_name'
                  maxLength={50}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.promotional_email_from_name && formik.errors.promotional_email_from_name },
                    {
                      'is-valid': formik.touched.promotional_email_from_name && !formik.errors.promotional_email_from_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {formik.touched.promotional_email_from_name && formik.errors.promotional_email_from_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.promotional_email_from_name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>From Email</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  {...formik.getFieldProps('promotional_email_from_email')}
                  type='email'
                  placeholder='Email From Name'
                  name='promotional_email_from_email'
                  maxLength={50}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.promotional_email_from_email && formik.errors.promotional_email_from_email },
                    {
                      'is-valid': formik.touched.promotional_email_from_email && !formik.errors.promotional_email_from_email,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {formik.touched.promotional_email_from_email && formik.errors.promotional_email_from_email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.promotional_email_from_email}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </div>
          </div>
          <div className='row fv-row'>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Reply To</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  maxLength={200}
                  {...formik.getFieldProps('promotional_email_reply_to')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.promotional_email_reply_to && formik.errors.promotional_email_reply_to },
                    {
                      'is-valid': formik.touched.promotional_email_reply_to && !formik.errors.promotional_email_reply_to,
                    }
                  )}
                  placeholder='Reply To Email'
                  type='email'
                  name='promotional_email_reply_to'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {/* end::Input */}
                {formik.touched.promotional_email_reply_to && formik.errors.promotional_email_reply_to && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.promotional_email_reply_to}</span>
                  </div>
                )}
              </div>
              {/* end::Input group */}
            </div>
            <div className='col-6'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Schedule Email</label>
                {/* end::Label */}

                {/* begin::Input */}
                <select
                  {...formik.getFieldProps('schedule_promotional_email')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.schedule_promotional_email && formik.errors.schedule_promotional_email },
                    {
                      'is-valid': formik.touched.schedule_promotional_email && !formik.errors.schedule_promotional_email,
                    }
                  )}
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-campaign-table-filter='two-step'
                  data-hide-search='true'
                  name='schedule_promotional_email'
                  disabled={formik.isSubmitting || isContactLoading}
                  onChange={(e) => setPromotionalEmailSchedule(e.target.value === "Yes" ? true : false)}
                  value={promotionalEmailSchedule ? "Yes" : "No"}
                >
                  <option value='No'>No</option>
                  <option value='Yes'>Yes</option>
                </select>
                {/* end::Input */}
                {formik.touched.schedule_promotional_email && formik.errors.schedule_promotional_email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.schedule_promotional_email}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Input group */}
          {promotionalEmailSchedule &&
            <div className='row fv-row'>
              <div className='col-6'>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-5'>Schedule Email Time</label>
                  <DatePicker
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.schedule_promotional_email_timezone && formik.errors.schedule_promotional_email_timezone },
                      {
                        'is-valid': formik.touched.schedule_promotional_email_timezone && !formik.errors.schedule_promotional_email_timezone,
                      }
                    )}
                    {...formik.getFieldProps('schedule_promotional_email_datetime')}
                    selected={promotionalEmailScheduleDate}
                    onChange={(date) => setPromotionalEmailScheduleDate(date)}
                    showTimeSelect
                    name="schedule_promotional_email_datetime"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    disabled={formik.isSubmitting || isContactLoading}
                  />
                  {formik.touched.schedule_promotional_email_datetime && formik.errors.schedule_promotional_email_datetime && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.schedule_promotional_email_datetime}</span>
                    </div>
                  )}
                  {/* end::Roles */}
                </div>
                {/* end::Input group */}
              </div>
              <div className='col-6'>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-5'>Schedule Email Timezone</label>
                  {/* end::Label */}
                  <select
                    {...formik.getFieldProps('schedule_promotional_email_timezone')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.schedule_promotional_email_timezone && formik.errors.schedule_promotional_email_timezone },
                      {
                        'is-valid': formik.touched.schedule_promotional_email_timezone && !formik.errors.schedule_promotional_email_timezone,
                      }
                    )}
                    name='schedule_promotional_email_timezone'
                    disabled={formik.isSubmitting || isContactLoading}
                    onChange={e => setPromotionalEmailTimezone(e.currentTarget.value)}>
                    {options.map(option => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  {formik.touched.schedule_promotional_email_timezone && formik.errors.schedule_promotional_email_timezone && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.schedule_promotional_email_timezone}</span>
                    </div>
                  )}
                  {/* end::Roles */}
                </div>
                {/* end::Input group */}
              </div>
            </div>
          }
          <div className='row fv-row'>
            <div className='col-12'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-5'>Email Subject</label>
                <input
                  maxLength={255}
                  placeholder='Subject'
                  type='text'
                  {...formik.getFieldProps('promotional_email_subject')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.promotional_email_subject && formik.errors.promotional_email_subject },
                    {
                      'is-valid': formik.touched.promotional_email_subject && !formik.errors.promotional_email_subject,
                    }
                  )}
                  name='promotional_email_subject'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isContactLoading}
                />
                {formik.touched.promotional_email_subject && formik.errors.promotional_email_subject && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.promotional_email_subject}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-5'>Schedule Email Content
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    onClick={showTagSection}
                  >
                    <KTIcon className='fs-1' iconName='information' />
                  </div>
                </label>
                <div style={{ display: 'flex', height: '200px' }}>
                  <div style={{ width: '100%', height: '100%' }}>
                    <EditorToolbar />
                    <ReactQuill theme="snow"
                      placeholder='Campaign email content'
                      style={{ height: '100%' }}
                      value={promotionalEmailHTML}
                      modules={modules}
                      formats={formats}
                      onChange={setPromotionalEmailHTML}
                      className={clsx(
                        'mb-3 mb-lg-0',
                        { 'is-invalid': formik.touched.promotional_email_html && formik.errors.promotional_email_html },
                        {
                          'is-valid': formik.touched.promotional_email_html && !formik.errors.promotional_email_html,
                        }
                      )} />
                    {formik.touched.promotional_email_html && formik.errors.promotional_email_html && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.promotional_email_html}</span>
                      </div>
                    )}
                  </div>
                  {showTagInfos &&
                    <div style={{ width: '100%', overflow: 'auto', padding: '0 20px' }}>
                      <table>
                        <tr>
                          <td style={{ fontWeight: '600' }}>
                            Tags
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Name:<br></br>
                            <code>[Name,fallback=]</code> or<br></br><code>[Name]</code>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Email:<br></br>
                            <code>[Email]</code>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Two digit day of the month (eg. 01 to 31):<br></br>
                            <code>[currentdaynumber]</code>
                          </td>
                        </tr>
                        <tr> <td>
                          A full textual representation of the day (eg. Friday):<br></br>
                          <code>[currentday]</code>
                        </td>
                        </tr>
                        <tr>
                          <td>
                            Two digit representation of the month (eg. 01 to 12):<br></br>
                            <code>[currentmonthnumber]</code>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Full month name (eg. May):<br></br>
                            <code>[currentmonth]</code>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Four digit representation of the year (eg. 2014):<br></br>
                            <code>[currentyear]</code>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Webversion link:<br></br>
                            <code>&lt;webversion&gt;View web version&lt;/webversion&gt;</code> or<br></br><code>[webversion]</code>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Unsubscribe link:<br></br>
                            <code>&lt;unsubscribe&gt;Unsubscribe here&lt;/unsubscribe&gt;</code> or<br></br><code>[unsubscribe]</code>
                          </td>
                        </tr>
                      </table>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-contacts-modal-action='cancel'
              disabled={formik.isSubmitting || isContactLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-contacts-modal-action='submit'
              disabled={isContactLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Schedule Campaign</span>
              {(formik.isSubmitting || isContactLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </form>
      {(formik.isSubmitting || isContactLoading) && <ListLoading />}
    </>
  )
}

export { ScheduleCampaignModalForm }
