/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../../_melbarr/assets/ts/components'
import { KTIcon, QUERIES, MODALTYPE } from '../../../../../../../../_melbarr/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteCampaign } from '../../core/_requests'
import { Campaign } from '../../core/_models'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { exportCampaignLeads } from '../../../../../admin/lead-management/list/core/_requests'
import { downloadCampaignOutput } from '../../../../../admin/campaign-management/list/core/_requests'
const FileDownload = require('js-file-download');
type Props = {
  campaign: Campaign
}

const ActionsCell: FC<Props> = ({ campaign }) => {
  const { setItemIdForUpdate, setUpdateModalType } = useListView()
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const openModal = (modalType) => {
    setUpdateModalType(modalType || MODALTYPE.EDIT);
    setItemIdForUpdate(campaign.id)
  }

  const deleteItem = useMutation(() => deleteCampaign(campaign.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.CAMPAIGN_LIST}-${query}`])
      refetch();
    },
  })

  const exportItem = async () => {
    const response = await exportCampaignLeads(campaign.id || "")
    FileDownload(response, 'CampaignLeads.csv');
  }

  const downloadOutputFileItem = async () => {
    if (campaign && campaign.id) {
      if (campaign.output_file_path) {
        const fileName = JSON.parse(campaign.output_file_path)["originalname"]
        const response = await downloadCampaignOutput(campaign.id)
        FileDownload(response, fileName);
      }
      else {
        exportItem();
      }
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>

        {/* begin::Menu item */}
        {!campaign.is_campaign_started &&

          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip-user-name'>Edit</Tooltip>}
          >
            <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={(e) => {
              e.stopPropagation(); openModal(MODALTYPE.EDIT)
            }} >
              <KTIcon iconName='pencil' className='fs-3' />
            </button>
          </OverlayTrigger>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {campaign.is_ready_for_customer &&
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip-user-name'>Download</Tooltip>}
          >
            <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={async (e) => {
                e.stopPropagation(); await downloadOutputFileItem()
              }} >
              <KTIcon iconName='file-down' className='fs-3' />
            </button>
          </OverlayTrigger>
        }
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {!campaign.is_campaign_started && <OverlayTrigger
          placement='top'
          overlay={<Tooltip id='tooltip-user-name'>Delete</Tooltip>}
        >
          <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={async (e) => {
              e.stopPropagation(); await deleteItem.mutateAsync()
            }}>
            <KTIcon iconName='trash' className='fs-3' />
          </button>
        </OverlayTrigger>}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { ActionsCell }
