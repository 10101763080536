/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ListWrapper } from './list/List'

type Props = {
  className: string
}

const CampaignList: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Campaign Statistics</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
       <ListWrapper />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { CampaignList }
