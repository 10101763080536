/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {AsideMenu} from './AsideMenu'
import { AsideUserMenu } from '../../../partials'

const AsideDefault: FC = () => {
  const {classes} = useLayout()
  return (
    <div
      id='kt_aside'
      className={clsx('aside pb-5 pt-5 pt-lg-0 background-color-rh ', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'80px', '300px': '100px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div className='aside-logo  py-8' id='kt_aside_logo'>
        <Link to='/dashboard' className='d-flex align-items-center aside-text'>
          ROUNDHOUSE
        </Link>
      </div>
      <div className='aside-menu flex-column-fluid' id='kt_aside_menu'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      <div className='aside-footer flex-column-auto px-9 pt-5 pb-5' id='kt_aside_footer'>
        <AsideUserMenu />
      </div>
    </div>
  )
}

export {AsideDefault}
