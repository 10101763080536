/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { CampaignLeads } from '../../core/_models'

type Props = {
  campaignleads: CampaignLeads
}

const CompanyInfoCell: FC<Props> = ({ campaignleads }) => {
  return (
    <>
      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
        {campaignleads?.contact?.company?.company_name}
      </span>
      {campaignleads?.contact?.company?.email &&
        <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.email}
        </span>
      }
      {campaignleads?.contact?.company?.contact_no &&
        <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.contact_no}
         </span>
      }
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.company_link}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.company_domain}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.company_website} {campaignleads?.contact?.company?.ip_address ? "(" + campaignleads?.contact?.company?.ip_address + ")" : ""}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.company_employee_range}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.company_revenue_range}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'> {campaignleads?.contact?.company?.sub_industry}
      </span>
      <span className='text-muted fw-semibold d-block fs-7'>
        {campaignleads?.contact?.company?.address}, {campaignleads?.contact?.company?.street}, {campaignleads?.contact?.company?.city}, {campaignleads?.contact?.company?.state}, {campaignleads?.contact?.company?.country?.country_name} - {campaignleads?.contact?.company?.zip_code}
      </span>

    </>
  )
}

export { CompanyInfoCell }
