import { FC } from 'react'
import { CampaignLeads } from '../../core/_models';

type Props = {
  campaignleads: CampaignLeads
}

const StatusCell: FC<Props> = ({ campaignleads }) => {

  return <>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex' }}>
        {!campaignleads.is_valid && !campaignleads?.operation_verified && <div className="badge badge-danger" >Pending Validation</div>}
        {!campaignleads.is_valid && campaignleads?.operation_verified && !campaignleads.qc_verified && <div className="badge badge-success" >Validation completed.</div>}
        {campaignleads?.operation_verified && campaignleads.qc_verified && (campaignleads.is_valid === undefined || campaignleads.is_valid === null) && <div className="badge badge-warning" >Pending to be added in campaign</div>}
        {campaignleads.is_valid && <div className="badge badge-success" >Lead added in campaign</div>}
        {campaignleads?.operation_verified && campaignleads.qc_verified && campaignleads.is_valid === false && <div className="badge badge-danger" >Lead excluded from campaign</div>}
      </div>
    </div>
  </>
}

export { StatusCell }
