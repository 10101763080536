/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate } from "react-router-dom";
import { FC } from 'react'
import {
  deleteCampaign,
} from './list/core/_requests'
import { Campaign } from './list/core/_models'
import { useMutation } from "react-query";
import { KTIcon } from "../../../../../_melbarr/helpers";
import { exportCampaignLeads } from "../../admin/lead-management/list/core/_requests";
const FileDownload = require('js-file-download');
type Props = {
  campaign: Campaign
}

const CampaignLeads: FC<Props> = ({ campaign }) => {
  const navigate = useNavigate();
  const deleteItem = useMutation(() => deleteCampaign(campaign?.id || ""), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      navigate(`/dashboard`)
    },
  })

  const exportItem = async () => {
    const response = await exportCampaignLeads(campaign.id || "")
    FileDownload(response, 'CampaignLeads.csv');
  }

  const completionPercent = (!campaign?.is_campaign_started ? 0 :
    (campaign?.is_campaign_started && !campaign?.is_operation_completed ? 10 :
      (campaign?.is_operation_completed && !campaign.is_qc_completed ? 50 :
        (campaign?.is_qc_completed && !campaign?.is_upload_completed ? 80 :
          (campaign?.is_upload_completed && !campaign?.is_ready_for_customer ? 95 : 100)))))
  const campaignStatus = (!campaign?.is_campaign_started ? "new" :
    (campaign?.is_campaign_started && !campaign.is_operation_completed ? "validation" :
      (campaign?.is_operation_completed && !campaign.is_qc_completed ? "verification" :
        (campaign?.is_qc_completed && !campaign?.is_upload_completed ? "upload" :
          (campaign?.is_ready_for_customer ? "download" :
            (campaign?.is_ready_for_customer && campaign?.campaign_expiry_date && campaign?.campaign_expiry_date > new Date() ? "expired" : ""))))))

  return (
    <>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2' >
                    Campaign Id: {campaign?.campaign_id}
                  </div>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'  >
                    Allocation: {campaign?.allocation}
                  </div>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'  >
                    Band name: {campaign?.band_name}
                  </div>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <i className="far fa-envelope text-danger fs-3"></i>&nbsp;&nbsp;
                    Verify Email: {campaign?.email_verify ? <KTIcon iconName='check  text-success' className='fs-1' /> : <KTIcon iconName='cross text-danger' className='fs-1' />}
                  </div>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <i className="fas fa-phone text-success fs-3"></i>&nbsp;&nbsp;
                    Verify Phone: {campaign?.phone_verify ? <KTIcon iconName='check  text-success' className='fs-1' /> : <KTIcon iconName='cross text-danger' className='fs-1' />}
                  </div>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <i className="fas fa-mail-bulk text-primary fs-3"></i>&nbsp;&nbsp;
                    Schedule Batch: {campaign?.schedule_batch ? <KTIcon iconName='check  text-success' className='fs-1' /> : <KTIcon iconName='cross text-danger' className='fs-1' />}
                  </div>
                </div>
              </div>
              <div className='d-flex my-4'>
                {!campaign?.is_campaign_started &&
                  <button className='btn btn-sm btn-primary me-2' id='kt_delete_campaign_button'
                    onClick={async () => await deleteItem.mutateAsync()}>
                    <span className='indicator-label'>Delete</span>
                  </button>
                } {campaign?.is_ready_for_customer && campaign?.campaign_expiry_date && campaign?.campaign_expiry_date > new Date() &&
                  <button className='btn btn-sm btn-primary me-2' id='kt_delete_campaign_button'
                    onClick={exportItem}>
                    <span className='indicator-label'>Download</span>
                  </button>
                }
              </div>
            </div>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>{campaign?.total_lead_filtered}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Total Leads Filtered</div>
                  </div>
                  {campaign?.email_verify &&
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                        <div className='fs-2 fw-bolder'>{campaign?.leads_email_verified}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Emails Verified</div>
                    </div>
                  }
                  {campaign?.phone_verify &&
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>{campaign?.leads_phone_verified}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Phone Verified</div>
                    </div>
                  }
                </div>
              </div>

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>
                    {campaignStatus === 'new' && 'New Campaign. Waiting for approval'}
                    {campaignStatus === 'validation' && 'Validation in progress'}
                    {campaignStatus === 'verification' && 'Verification in progress'}
                    {campaignStatus === 'upload' && 'Generating the output'}
                    {campaignStatus === 'download' && 'Ready for download'}
                    {campaignStatus === 'expired' && 'Expired'}
                  </span>
                  <span className='fw-bolder fs-6'>
                    {completionPercent + "%"}
                  </span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className={`progress-bar bg-${completionPercent > 50 ? 'success' : 'warning'} `}
                    role='progressbar'
                    style={{ width: `${completionPercent}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CampaignLeads }
