import {FC, useEffect} from 'react'
import {EditModalHeader} from './EditModalHeader'
import {EditModalFormWrapper} from './EditModalFormWrapper'
import { SelectList } from '../../../campaign-management/list/core/_models'
import { Company } from '../../../contact-management/list/core/_models'

type Props = {
  companies: Company[] | undefined
  country: SelectList[] | undefined
  employee_range: SelectList[] | undefined
  revenue: SelectList[] | undefined
  subindustry: SelectList[] | undefined
}

const EditModal: FC<Props> = ({ companies, country, employee_range, revenue, subindustry }) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        data-backdrop="static" data-keyboard="false" 
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <EditModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <EditModalFormWrapper companies={companies}  country={country} employee_range={employee_range} revenue={revenue} subindustry={subindustry}/>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {EditModal}
