/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../../../_melbarr/helpers'
import { User } from '../../core/_models'

type Props = {
  user: User
}

const InfoCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.photo ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`/media/avatars/${user.photo}`)} alt={user.first_name + " " + user.last_name} className='w-100' />
          </div>
        ) : ""}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <span className='text-gray-800 text-hover-primary mb-1'>
        {user.first_name}  {user.last_name}
      </span>
      <span>{user.email}</span>
    </div>
  </div>
)

export { InfoCell }
