import { LeadStatDetails, Stats } from './_models'
import { ApiClient } from '../../../../../../_melbarr/helpers/APIHelpers'
import { Campaign } from '../../campaign-management/list/core/_models'
import { Customer } from '../../customer-management/list/core/_models'

const DASHBOARD_URL = `/dashboard`

const getDashboardStats = (): Promise<Stats> => {
  return ApiClient()._get(`${DASHBOARD_URL}/stats`)
    .then((d) => d.data)
}

const getDashboardLeadStats = (): Promise<LeadStatDetails> => {
  return ApiClient()._get(`${DASHBOARD_URL}/lead/stats`)
    .then((d) => d.data)
}

const getNewCampaignList = (): Promise<Campaign[]> => {
  return ApiClient()._get(`${DASHBOARD_URL}/campaign/pending`)
    .then((d) => d.data)
}

const getOngoingCampaignList = (): Promise<Campaign[]> => {
  return ApiClient()._get(`${DASHBOARD_URL}/campaign/ongoing`)
    .then((d) => d.data)
}

const getTopCustomerList = (): Promise<Customer[]> => {
  return ApiClient()._get(`${DASHBOARD_URL}/customer`)
    .then((d) => d.data)
}


export {
  getDashboardStats,
  getDashboardLeadStats,
  getNewCampaignList,
  getOngoingCampaignList,
  getTopCustomerList,
}
