import { FC } from "react"
import { Customer } from "../core/_models"
import { useQueryResponse } from "../core/QueryResponseProvider"
import { useListView } from "../core/ListViewProvider"
import _ from 'lodash'

type Props = {
  customer: Customer
}

const EditMessage: FC<Props> = ({ customer }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontSize: '14px',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: '35%',
    left: 'auto',
  }

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>
    Customer is added successfully. Account needs activation. Account Activation details are shared to the email.
    In case of not received please share the verification code below with Customer and ask to use it on&nbsp;<a href="/auth/activate-accounts">Account activation </a>

    <div className='text-center pt-5'>
      <div className='btn btn-primary me-3 pt-5'
        data-bs-toggle='tooltip'
        title='Click to Copy'
        onClick={() => { navigator.clipboard.writeText(_.first(customer.user)?.verification_code || "") }}
      >{_.first(customer.user)?.verification_code}</div>
    </div>

    <div className='text-center pt-5'>
      <button
        type='reset'
        onClick={() => cancel(true)}
        className='btn btn-light me-3'
        data-kt-customers-modal-action='cancel'
      >
        Close
      </button>
    </div>
  </div >
}

export { EditMessage }
