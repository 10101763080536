/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Contact } from '../../core/_models'

type Props = {
  contact: Contact
}

const InfoCell: FC<Props> = ({ contact }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className='text-gray-800 text-hover-primary mb-1'>
        {contact.first_name}  {contact.last_name}
      </span>
      <span>
        <a href={`mailto:${contact.email_id}`} target='_blank' rel="noreferrer">{contact.email_id}</a>
      </span>
      <span>{contact.contact_no}</span>
      <a href={contact.profile_link} target='_blank' rel="noreferrer">{contact.profile_link}</a>
    </div>
  </div>
)

export { InfoCell }
