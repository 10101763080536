/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const { currentUser } = useAuth()

  const campaignMenuAccess = currentUser?.user_role?.access && currentUser?.user_role?.access.some(str =>  str && (str === "campaign:*" || str.indexOf("campaign:view") > -1)) ? true : false;
  const customerMenuAccess = currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "customer:*" || str.indexOf("customer:view") > -1)) ? true : false;
  const userMenuAccess = currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "user:*" || str.indexOf("user:view") > -1)) ? true : false;
  const contactMenuAccess = currentUser?.user_role?.access && currentUser?.user_role?.access.some(str => str && (str === "contact:*" || str.indexOf("contact:view") > -1)) ? true : false;

  return (
    <>
      {currentUser?.customer?.customer_type === "customer" &&
        <>
          <AsideMenuItem
            to='/dashboard'
            icon='black-right'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-tv'
          />
          <AsideMenuItem to='/campaign/new' icon='black-right' title='Add Campaign' fontIcon='bi-journal-plus' />
        </>}
      {currentUser?.customer?.customer_type !== "customer" &&
        <>
          <AsideMenuItem
            to='/dashboard'
            title='Home'
            fontIcon='bi-house fs-2'
            bsTitle={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            className='py-2'
          />
          {customerMenuAccess &&
            <AsideMenuItem
              to='/customer'
              title='Customers'
              fontIcon='bi-person-vcard-fill fs-2'
              bsTitle={intl.formatMessage({ id: 'MENU.CUSTOMER' })}
            />}
          {userMenuAccess &&
            <AsideMenuItem
              to='/user'
              title='Users'
              fontIcon='bi-people fs-2'
              bsTitle={intl.formatMessage({ id: 'MENU.USERS' })}
            />}
          {contactMenuAccess &&
            <AsideMenuItem
              to='/contact'
              title='Contacts'
              fontIcon='bi-people fs-2'
              bsTitle={intl.formatMessage({ id: 'MENU.CONTACTS' })}
            />}
          {campaignMenuAccess &&
            <AsideMenuItem
              to='/campaign'
              title='Campaign'
              fontIcon='bi-megaphone fs-2'
              bsTitle={intl.formatMessage({ id: 'MENU.CAMPAIGN' })}
            />}
        </>
      }
    </>
  )
}
