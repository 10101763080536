import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListHeader } from './components/header/ListHeader'
import { Table } from './table/Table'
import { EditModal } from './edit-modal/EditModal'
import { KTCard, MODALTYPE } from '../../../../../../_melbarr/helpers'
import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useState } from 'react'
import {
  getCampaignById, getCountryList, getEmployeeRangeList, getRevenueList
} from '../../campaign-management/list/core/_requests'
import { Campaign, SelectList, initialCampaign } from '../../campaign-management/list/core/_models'
import { CampaignInfo } from './components/header/CampaignInfo'
import { Company } from '../../contact-management/list/core/_models'
import { getCompanies, getSubIndustryList } from '../../contact-management/list/core/_requests'
import { ScheduleCampaignModal } from './edit-modal/ScheduleCampaignModal'
import { AssignCampaignModal } from './edit-modal/AssignCampaignModal'
type Props = {
  campaign: Campaign
}

const List: FC<Props> = ({ campaign }) => {

  const { itemIdForUpdate, updateModalType } = useListView()
  const [companies, setCompanies] = useState<Company[] | undefined>()
  const [country, setCountry] = useState<SelectList[] | undefined>()
  const [empRangeList, setEmpRangeList] = useState<SelectList[]>([])
  const [revenueList, setRevenueList] = useState<SelectList[]>([])
  const [subIndustryList, setSubIndustryList] = useState<SelectList[]>([])

  useEffect(() => {
    const fetchCompanyList = getCompanies("")
    const fetchCountryList = getCountryList()
    const fetchEmpRangeList = getEmployeeRangeList()
    const fetchRevenueList = getRevenueList()
    const fetchSubIndustry = getSubIndustryList()
    Promise.all([
      fetchCompanyList,
      fetchCountryList,
      fetchEmpRangeList,
      fetchRevenueList,
      fetchSubIndustry
    ]).then((responses) => {
      setCompanies(responses[0].data || [])
      setCountry(responses[1] || [])
      setEmpRangeList(responses[2] || [])
      setRevenueList(responses[3] || [])
      setSubIndustryList(responses[4] || [])
    })
  }, [])
  return (
    <>
      <KTCard>
        <CampaignInfo campaign={campaign} />
        <ListHeader campaign={campaign} />
        <Table />
      </KTCard>
      {itemIdForUpdate !== undefined && updateModalType !== MODALTYPE.SCHEDULECAMPAIGN && updateModalType !== MODALTYPE.ASSIGNOPERATION && updateModalType !== MODALTYPE.ASSIGNQA
      && <EditModal companies={companies} country={country}
        employee_range={empRangeList} revenue={revenueList} subindustry={subIndustryList} />}
     {itemIdForUpdate !== undefined && updateModalType === MODALTYPE.SCHEDULECAMPAIGN  && <ScheduleCampaignModal/>}
     {itemIdForUpdate !== undefined && (updateModalType === MODALTYPE.ASSIGNOPERATION || updateModalType === MODALTYPE.ASSIGNQA)  && <AssignCampaignModal/>}
    </>
  )
}

const ListWrapper = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [campaign, setCampaign] = useState<Campaign>()
  useEffect(() => {
    if (params.campaignId) {
      getCampaignById(params.campaignId).then((response) => {
        if (response) {
          setCampaign(response);
        }
      });
    } else {
      navigate(`/campaign`)
    }
  }, [navigate, params.campaignId])

  return (
    <QueryRequestProvider >
      <QueryResponseProvider>
        <ListViewProvider>
          <List campaign={campaign || initialCampaign} />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export { ListWrapper }
