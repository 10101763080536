import { useEffect, useState } from 'react'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListHeader } from './components/header/ListHeader'
import { Table } from './table/Table'
import { EditModal } from './edit-modal/EditModal'
import { KTCard } from '../../../../../../_melbarr/helpers'
import { getRoles } from './core/_requests'
import { Role } from './core/_models'

const List = () => {
  const { itemIdForUpdate } = useListView()
  const [roles, setRoles] = useState<Role[] | undefined>()

  useEffect(() => {
    getRoles().then((response) => {
      setRoles(response.data)
    })
  }, [])
  return (
    <>
      <KTCard>
        <ListHeader roles={roles}  />
        <Table />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditModal roles={roles} />}
    </>
  )
}

const ListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <List />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ListWrapper }
