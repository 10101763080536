import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_melbarr/layout/core'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Campaign, initialCampaign } from '../../modules/apps/customer/campaigns/list/core/_models'
import { getCampaignById } from '../../modules/apps/customer/campaigns/list/core/_requests'
import { CampaignForm } from '../../modules/apps/customer/campaigns/create-edit-form/Form'
const CampaignCreateEditWrapper = () => {
  const intl = useIntl()
  const params = useParams();
  const [campaign, setCampaign] = useState<Campaign>()
  useEffect(() => {
    if (params.campaignId) {
      getCampaignById(params.campaignId).then((response) => {
        if (response) {
          setCampaign(response);
        }
      });
    }
  }, [])

  return (
    <div style={{
      background: "url('/media/logos/roundhouse.jpg')",
      backgroundRepeat: 'no-repeat',
      backgroundSize: "100% 300px",
      paddingTop: "215px",
    }}>
      <div className='container-xxl' >
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CAMPAIGN' })}</PageTitle>
        <CampaignForm campaign={campaign || initialCampaign} />
      </div>
    </div>
  )
}

export { CampaignCreateEditWrapper }
