// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { Campaign } from '../../core/_models'
import { useNavigate } from 'react-router-dom';


type Props = {
  row: Row<Campaign>
}

const CustomRow: FC<Props> = ({ row }) => {
  const navigate = useNavigate();
  return (
    <tr {...row.getRowProps()} onClick={() => navigate(`/campaign/${row.original.id}`)}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export { CustomRow }
