export interface AuthModel {
  access_token: string
  refresh_token?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromMelbarrthemes?: {
    newsAboutMelbarrthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMore?: boolean
    thingsYouMissedSindeYouLastLogged?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserModel {
  id: number
  username: string
  password: string | undefined
  email: string
  first_name: string
  last_name: string
  fullname?: string
  customer?: CustomerModel
  phone?: string
  user_role?: RoleModel
  photo?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://melbarr.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel
}

export interface RoleModel {
  id: number
  role_name: string
  description: string | undefined
  customer_type: string
  access:  Array<RoleAccessEnum>
}

export interface CustomerModel {
  id: number
  customer_name: string
  description: string | undefined
  customer_type: string
  email: string
  image: string
}

export const RoleAccess = {
  SuperAdminDashboard: 'dashboard:superadmin:view',

  DashboardAll: 'dashboard:all',
  DashboardOperations: 'dashboard:operation:all',
  DashboardOperationsOwn: 'dashboard:operation:own',
  DashboardQA: 'dashboard:qa:all',
  DashboardQAOwn: 'dashboard:qa:own',
  DashboardUploader: 'dashboard:uploader:all',


  UserFullAccess: 'user:*',
  UserViewAll: 'user:view',
  UserViewOperations: 'user:view:operation',
  UserViewQA: 'user:view:qa',
  UserViewUploader: 'user:view:uploader',
  UserNew: 'user:new',
  UserEdit: 'user:edit',
  UserDelete: 'user:delete',

  ContactFullAccess: 'contact:*',
  ContactViewAll: 'contact:view',
  ContactNew: 'contact:new',
  ContactEdit: 'contact:edit',
  ContactDelete: 'contact:delete',

  CustomerFullAccess: 'customer:*',
  CustomerViewAll: 'customer:view',
  CustomerNew: 'customer:new',
  CustomerEdit: 'customer:edit',
  CustomerActivate: 'customer:activate',
  CustomerDeactivate: 'customer:deactivate',
  CustomerDelete: 'customer:delete',

  CampaignFullAccess: 'campaign:*',
  CampaignViewAll: 'campaign:view:all',
  CampaignViewNew: 'campaign:view:new',
  CampaignOperationsFullAccess: 'campaign:view:all:operation',
  CampaignOperationsOnGoing: 'campaign:view:ongoing:operation',
  CampaignQAFullAccess: 'campaign:view:all:qa',
  CampaignQAOnGoing: 'campaign:view:ongoing:qa',
  CampaignUploaderFullAccess: 'campaign:view:all:uploader',
  CampaignOwnAccess: 'campaign:view:assigned',
  CampaignCreate: 'campaign:new',
  CampaignEdit: 'campaign:edit',
  CampaignActivate: 'campaign:activate',
  CampaignDeactivate: 'campaign:deactivate',
  CampaignStart: 'campaign:move:operation',
  CampaignMoveToQA: 'campaign:move:qa',
  CampaignMoveToUpload: 'campaign:move:upload',
  CampaignAssignToOperation: 'campaign:assign:operation',
  CampaignAssignToQA: 'campaign:assign:qa',
  CampaignValidate: 'campaign:validate',
  CampaignVerify: 'campaign:verify',
  CampaignUpload: 'campaign:uploader',
  CampaignDownload: 'campaign:download',
  CustomerCheckStatus: 'campaign:status',
} as const

type RoleAccessEnum = typeof RoleAccess[keyof typeof RoleAccess]