import {ID, Response} from '../../../../../../../_melbarr/helpers'
export type User = {
  id?: ID
  first_name?: string
  last_name?: string
  photo?: string
  email?: string
  user_role?: Role
  role_id?: string
  last_login?: string
  username?: string
  manager_id?: string
  manager?: User
  is_active?: boolean
  verified?: boolean
  verification_code?: string
}

export type UserQuery = {
  page: number,
  items_per_page: number,
  search?: string,
  filter_role?: number,
  filter_verified?: boolean,
}

export type Role = {
  id: number,
  role_name: string,
  access: string[]
}

export type UsersQueryResponse = Response<Array<User>>
export type RolesQueryResponse = Response<Array<Role>>


export const initialUser: User = {
  photo: 'default.png',
  user_role: {
    id: 4,
    role_name: 'Admin',
    access: []
  },
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  role_id: '4',
  manager_id: '',
  manager: {},
  is_active: false,
  verified: false,
}
